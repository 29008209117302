import React, { Component } from 'react'
import moment from "moment";
import {
  Container,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Label,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import '../debrief/Debrief.css';
import '../debrief/Slider.css';
import constant from '../../shared/constant';
import RangeSlider from 'react-bootstrap-range-slider';
import _ from 'lodash';
import axios from 'axios';
import ReflectiveQuestion from "./reflective_question/ReflectiveQuestion";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import './Debrief.css';
import './Slider.css';
import ModalImage from "react-modal-image";

export class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obData: {},
      domains: [],
      components: [],
      rankedRatings: [],
      printPreview: false,
      firstNum: [ 0, 0, 0, 0, 0 ],
      secondNum: [ 4, 7, 5, 3, 8],
      totalRated: 0,
      starCount: [0, 0, 0, 0, 0, 0, 0],
      starVal: [10, 10, 10, 10, 10, 10, 10],
      starColor: ['#493CC2', '#6D63CE', '#928ADA', '#A49DE0', '#B6B1E7', '#C8C4ED', '#DBD8F3'],
      options: {
        plotOptions: {
          radialBar: {
            track: {
              background: '#FFFFFF20',
            },
            hollow: {
              margin: 5,
              size: "40%"
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: false,
                color: "#888",
                fontSize: "8px"
              },
              value: {
                color: "#FFF",
                fontSize: "15px",
                offsetY: 6,
                show: true
              }
            }
          }
        },
        colors: [
          '#FFF'
        ],
        stroke: {
          lineCap: "round",
        },
        labels: ["Percentage"]
      },
      series: [49]
    }
  }

  componentDidMount() {
    document.body.classList.add("debrief-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    if (observationId !== null) {
      this.getUserList();
      this.getDomains();
    }
  }

  getUserList() {
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    axios
      .post('/api/users', { userId: this.props.auth.user.id })
      .then((res) => {
        const users = res.data.sort((a, b) => {
          return new Date(b.dateLastVisit) - new Date(a.dateLastVisit);
        })

        axios
        .get(`/api/observations/${observationId}`)
        .then((res) => {
          let tempTeachers = [];
          res.data.teachers.forEach(teacher => {
            let tempTeacher = {email: teacher.email, date: teacher.invitedDate};
            tempTeachers.push(tempTeacher);
          })
          this.setState({invitedTeacher: tempTeachers});
          this.categorizeIndicators(res.data);
        }).catch((err) => {
          if (err.response) {
            console.log(err.response.data.message);
          } else if (err.request) {
            console.log('Please check your netwrok connection.')
          } else {
            console.log(err.message || 'Error occured while getting observation data.')
          }
          // this.props.history.goBack();
        });

        this.setState({ 
          totalUsers: users
        });
        
      }).catch((err) => {
        console.log(err);
      });
  }

  categorizeIndicators(obData) {
    if (obData.indicators.length !== 0) {
      let filteredIndicatorsAndActions = [];
      obData.indicators.forEach((indicator) => {
        if ((indicator.checked === true && indicator.type !== constant['OBSERVATION_TYPE']['CUSTOM'])
          || (indicator.type === constant['OBSERVATION_TYPE']['CUSTOM'] && indicator.components.length > 0)) {
          indicator.components.forEach((component) => {
            filteredIndicatorsAndActions.push({
              id: indicator._id,
              title: indicator.title,
              notes: indicator.notes,
              type: indicator.type,
              componentId: typeof(component) === "string" ? component : component._id,
              domainId: component.domain,

            });
          });
        }
      });
      obData.actions.forEach((action) => {
        if (action.components.length > 0) {
          action.components.forEach((component) => {
            filteredIndicatorsAndActions.push({
              id: action._id,
              title: action.teacher,
              note: action.student,
              type: action.type,
              componentId: typeof(component) === "string" ? component:  component._id,
              domainId: component.domain,
            });
          });
        }
      });
      this.setState({ obData: obData, filteredIndicators: filteredIndicatorsAndActions }, () => {
        this.getComponents();
      });
    } else {
      // toast.info("Please select at least an indicator to debrief");
      this.props.history.push('/observe');
    }
  }

  getComponents = () => {
    axios
      .get('/api/components')
      .then((res) => {
        if (res.data && res.data.length === 27) {
          let obData = this.state.obData;
          if (obData.ratings && obData.ratings.length !== 27) {
            obData.ratings = res.data.map((component) => {
              return {
                rating: -1,
                component: component._id
              }
            });
            this.setState({ components: res.data, obData: obData });
          } else {
            this.setState({ components: res.data });
          }
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response.data.message)
        } else if (err.request) {
          console.log('Please check your netwrok connection.')
        } else {
          console.log(err.message || 'Error occured while retrieving components.')
        }
      });
  }

  renderIndicatorsByDomains() {
    return this.state.domains.map((domain) => {
      return (
        <div>
          <Row style={{ display: "flex", alignItems: 'center', marginBottom: '15px', marginLeft: '0', width: '100%' }}>
            <img style={{withd: "30px", height: "30px"}} className="logo-image mr-2" src={require("assets/img/logo-without-circle.png")} alt="" />
            <div className={`domain-title text-white font-crm-big ${domain.index === 1 ? " color-domain-1" : ""} 
                  ${domain.index === 2 ? " color-domain-2" : ""} 
                  ${domain.index === 3 ? " color-domain-3" : ""} 
                  ${domain.index === 4 ? " color-domain-4" : ""} 
                  ${domain.index === 5 ? " color-domain-5" : ""}`} style={{width: "calc(100% - 60px)"}}>
              <span style={{ float: 'left', fontSize: '12px' }}>Domain {domain.index}: {domain.name}</span>
            </div>
          </Row>
          {
            this.renderIndicatorsByComponents(domain)
          }
        </div>
      )
    });
  }

  getDomains = () => {
    axios
      .get('/api/domains')
      .then((res) => {
        if (res.data) {
          const domains = res.data.sort((a, b) => { return a.index - b.index });
          this.setState({ domains: domains });
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response.data.message)
        } else if (err.request) {
          console.log('Please check your netwrok connection.')
        } else {
          console.log(err.message || 'Error occured while retrieving domains.')
        }
      });
  }

  renderIndicatorsByComponents(domain) {
    const componentsByDomain = this.state.components.filter((component) => {
      return component.domain._id === domain._id;
    });
    return componentsByDomain.map((component) => {
      const ratingDesc = constant['RATINGS'].find((item) => { return item.index === component.index });
      return (
        <Table responsive>
          <thead>
            <tr>
              <th className="text-left" style={{ width: '30px' }}>
                <div className={`order-mark 
                      ${domain.index === 1 ? " color-domain-1" : ""} 
                      ${domain.index === 2 ? " color-domain-2" : ""} 
                      ${domain.index === 3 ? " color-domain-3" : ""} 
                      ${domain.index === 4 ? " color-domain-4" : ""} 
                      ${domain.index === 5 ? " color-domain-5" : ""}`}>
                  <span className="text-white">{component.index}</span>
                </div>
              </th>
              <th className="text-left" colSpan="2"  style={{border: "0 !important"}}>
                <h6 style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: "center", backgroundColor: "#eee"}}>
                  <span style={{width: "50%", fontSize: '12px'}} >{component.name}<i className="now-ui-icons ui-1_simple-add" style={{ verticalAlign: 'top', marginLeft: "10px" }}></i></span>
                  <Col style={{width: "50%", marginRight: '50px'}}>
                    {(this.state.obData.ratings !== undefined || this.state.obData.ratings !== null) &&

                      this.renderRatings(component, domain)
                    }
                    <div className='print-rating-desc'>
                      <span className='print-text' style={{width: "22%"}}>{ratingDesc.texts[0]}</span>
                      <span className='print-text' style={{width: "18%"}}>{ratingDesc.texts[1]}</span>
                      <span className='print-text' style={{width: "17%"}}>{ratingDesc.texts[2]}</span>
                      <span className='print-text' style={{width: "22%"}}>{ratingDesc.texts[3]}</span>
                      <span className='print-text' style={{width: "5%", marginRight: '0px'}}>{ratingDesc.texts[4]}</span>
                    </div>
                    <div className='rating-ticks-print'>
                      <span className='print-rating'>3</span>
                      <span className='print-rating'>2.5</span>
                      <span className='print-rating'>2</span>
                      <span className='print-rating'>1.5</span>
                      <span className='print-rating'>1</span>
                      <span className='print-rating'>0</span>
                      <span className='print-rating'>N/A</span>
                    </div>
                  </Col>
                </h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              this.renderIndicators(domain, component)
            }
          </tbody>
        </Table>
      )
    });
  }

  loadStandIndicatorsByComponent(component) {
    if(this.disableCheckForCompletedObservation()) return;
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    const indicators = this.state.obData.indicators.filter((indicator) => {
    if(typeof indicator.components[0] != "undefined")
    {
      return indicator.type === constant['OBSERVATION_TYPE']['STANDARD'] && (indicator.components[0]._id ? indicator.components[0]._id === component._id : indicator.components[0] === component._id);
    }
    });
    this.setState({ componentWillUpdated: component, indicatorModal: true, standardIndicators: indicators });
  }

  getRatingColor = (domain) => {
    switch (domain.index) {
      case 1:
        return 'primary';
      case 2:
        return 'secondary';
      case 3:
        return 'success';
      case 4:
        return 'danger';
      case 5:
        return 'warning';
      default:
        return ''
    }
  }

  renderRatings = (component, domain) => {
    let sRating = _.find(this.state.obData.ratings, { component: component._id });
    const rating = (sRating === undefined || sRating === null || sRating.rating === -1) ? 6 : sRating.rating;
    return (
      <>
        <RangeSlider
          value={rating}
          onChange={(e) => { this.onSlideRatings(e.target.value, component) }}
          min={0} max={6} step={1}
          variant={`${this.getRatingColor(domain)}`}
          tooltipLabel={(value) => `${this.setRatings(value)}`}
          tooltip="on"
          className="hide-printing"
          disabled={this.disableCheckForCompletedObservation()}
        />
      </>
    );
  }

  onSlideRatings = (rating, component) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    if (this.state.rating !== rating) {
      this.setState({ componentWillUpdated: component, rating: rating }, () => {
        this.saveRating();
      });
    }
  }

  saveRating = () => {
    const index = _.findIndex(this.state.obData.ratings, { 'component': this.state.componentWillUpdated._id });
    let obData = this.state.obData;
    const rating = {
      rating: Number(this.state.rating),
      component: this.state.componentWillUpdated._id
    }
    obData.ratings.splice(index, 1, rating);
    this.updateObData(obData);
  }

  updateObData(obData) {
    axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        this.setState({ obData: res.data, rating: -1 }, () => {
          const indicators = this.state.obData.indicators.filter((indicator) => {
            if(typeof indicator.components[0] != "undefined")
            {
              return indicator.type === constant['OBSERVATION_TYPE']['STANDARD'] && indicator.components[0]._id === this.state.componentWillUpdated._id;
            }
          });
          this.setState({ standardIndicators: indicators });
        });
        this.categorizeIndicators(res.data);
      }).catch((err) => {
        this.setState({ rating: -1 });
        if (err.response) {
          console.log(err.response);
          console.log(err.response.data.message)
        } else if (err.request) {
          console.log('Please check your netwrok connection.')
        } else {
          console.log(err.message || 'Error occured while updating observation data.')
        }
      });
  }

  setRatings = (value) => {
    switch (value) {
      case 6:
        return 'N/A';
      case 5:
        return 0;
      case 4:
        return 1;
      case 3:
        return 1.5;
      case 2:
        return 2;
      case 1:
        return 2.5;
      case 0:
        return 3;
      default:
        return '';
    }
  }

  disableCheckForCompletedObservation = () => {
    if (this.disableUserAccess())
      return true;
    if (this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED']) return true;
  }

  disableUserAccess = () => {
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ||
      this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      return true;
    } else if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      if (this.state.obData && this.state.obData.observer && (this.props.auth.user.id !== this.state.obData.observer._id)) {
        return true;
      }
    }
    return false;
  }

  renderIndicators = (domain, component) => {
    const indicators = _.filter(this.state.filteredIndicators, { 'componentId': component._id});
    if (indicators.length > 0) {
      return indicators.map((indicator, index) => {
        return (
          <>
            <tr key={indicator._id}>
              <td style={{border: "0 !important"}}></td>
              <td className="text-left" style={{border: "0 !important"}}>
                {
                  this.renderIndicator(indicator)
                }
              </td>
            </tr>
            {(indicators.length === 1) &&
              <>
                <tr>
                  <td colSpan="3" style={{border: "0 !important"}}></td>
                </tr>
                <tr>
                  <td colSpan="3" style={{border: "0 !important"}}></td>
                </tr>
                <tr>
                  <td colSpan="3" style={{border: "0 !important"}}></td>
                </tr>
                <tr>
                  <td colSpan="3" style={{border: "0 !important"}}></td>
                </tr>
              </>
            }
            {(indicators.length === 2 && (index === indicators.length - 1)) &&
              <>
                <tr>
                  <td colSpan="3" style={{border: "0 !important"}}></td>
                </tr>
                <tr>
                  <td colSpan="3" style={{border: "0 !important"}}></td>
                </tr>
              </>
            }
          </>
        );
      });
    } else {
      return (
        <>
          <tr>
            <td colSpan="3" style={{border: "0 !important"}}></td>
          </tr>
          <tr>
            <td colSpan="3" style={{border: "0 !important"}}></td>
          </tr>
          <tr>
            <td colSpan="3" style={{border: "0 !important"}}></td>
          </tr>
          <tr>
            <td colSpan="3" style={{border: "0 !important"}}></td>
          </tr>
          <tr>
            <td colSpan="3" style={{border: "0 !important"}}></td>
          </tr>
          <tr>
            <td colSpan="3" style={{border: "0 !important"}}></td>
          </tr>
        </>
      );
    }
  }

  renderIndicator = (indicator) => {
    return (
      <>
        <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}}>
          <div style={{width: "45%"}}>
            <label style={{ marginLeft: '5px', display: 'block' }} className="font-crm">
              {indicator.type === 7 ? 'Teacher: ' : ''}
              <span className={indicator.type === 7 ? "font-italic" : ""} style={{fontSize: '12px'}}>{indicator.title}</span>
            </label>
            {
              this.renderNotes(indicator)
            }
          </div>
        </div>
      </>
    );
  }

  renderNotes = (inputIndicator) => {
    const vIndicators = this.state.obData.indicators.filter((indicator) => {
      return indicator.checked === true
    });
      return (
        <>
          {inputIndicator.notes && inputIndicator.notes.length > 0 ? 
            inputIndicator.notes.map(note =>(
              <div style={{marginLeft: '5px'}}>
              <Label style={{ display: 'block', marginTop: '5px', marginBottom: '5px' }} className="font-crm" id="edit-note">
                <>
                  {inputIndicator.type === 7 ? 'Student: ' : ''}<span className="font-italic">{note.text}</span>
                </>
              </Label>
              {note.image &&
                <div style={{maxWidth : '50px'}}>
                  <ModalImage
                    small={note.image}
                    large={note.image}
                    alt="image"
                  />
                </div>
              }
              </div>
            ))
          : 
          <Label style={{ display: 'block', marginTop: '5px', marginLeft: '5px' }} className="font-crm" id="edit-note">
            <>
              {inputIndicator.type === 7 ? 'Student: ' : ''}<span className="font-italic">{inputIndicator.note}</span>
            </>
          </Label>}
        </>
      );
  }

  render() {
    return (
      <div id="debrief-report" style={{ backgroundColor: 'white' }} className="ml-auto mr-auto">
        {/* Add the <style> tag here */}
        <style type="text/css" media="print">
          {`
            @page {
              size: landscape;
            }
          `}
        </style>
        <Row className="print-row default-back-color text-white">
          <span style={{ marginRight: '60px' }}>Teacher: {this.state.obData.teacher}</span>
          <span style={{ marginRight: '60px' }}>Grade: {this.state.obData.grade}</span>
          <span style={{ marginRight: '60px' }}>Topic of Lesson: {this.state.obData.topic}</span>
          <span style={{ marginRight: '60px' }}>Created: {moment(this.state.obData.date).format('MM/DD/YYYY h:mm a')}</span>
          <span style={{ marginRight: '30px' }}>Last Modified: {moment(this.state.obData.dateModified).format('MM/DD/YYYY h:mm a')}</span>
        </Row>
        <div style={{ marginLeft: 10, marginRight: 10, marginTop: "0px" }}>
        {
          this.renderIndicatorsByDomains()
        }
        </div>
        <ReflectiveQuestion
          obData={this.state.obData}
        />
      </div>
    )
  }
}

ComponentToPrint.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(ComponentToPrint);

// export default ComponentToPrint