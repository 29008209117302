import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  Modal,
  ModalFooter, 
  Label
} from "reactstrap";

// core components
import DropdownScrollNavbar from "../../shared/DropdownScrollNavbar";
import constant from "../../../shared/constant";

class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      commissions: [],
      searchFocus: false,
      keyword: '',
      totalUsers: [],
      modal: false,
      selectedUser: null
    }
  }

  componentDidMount() {
    document.body.classList.add("admin-users-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    this.getUserList();
  }

  componentWillUnmount() {
    document.body.classList.remove("admin-users-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getUserList() {
    axios
      .post('/api/users', { userId: this.props.auth.user.id })
      .then((res) => {
        const users = res.data.sort((a, b) => {
          return new Date(b.dateLastVisit) - new Date(a.dateLastVisit);
        })
        this.setState({ 
          users: users, 
          keyword: '',
          totalUsers: users
        });
      }).catch((err) => {
        console.log(err);
      });
  }

  getSchoolName(district, school) {
    if (district && district.schools && district.schools.length > 0) {
      const schoolObj = district.schools.find(s => s._id === school);
      if (!schoolObj) return 'Unaffiliated';
      return schoolObj.name;
    }
    return 'Unaffiliated';
  }

  deleteUser = () => {
    axios
      .delete(`/api/users/${this.state.selectedUser._id}`)
      .then((res) => {
        let users = [...this.state.users];
        let userIndex = users.indexOf(users.filter(u => u._id === this.state.selectedUser._id)[0]);
        users.splice(userIndex, 1);
        this.setState({
          users: users,
          modal: false
        })
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error occured while getting observation data.");
        }
      });
  }

  showModal = (user) => {
    this.setState({
      modal: true,
      selectedUser: user
    })
  }

  renderUserListTableData() {
    const moment = require('moment');
    return this.state.users.map((user) => {
      return (
        <tr key="#">
          <td className="text-left">{user.firstName ? user.firstName + ' ' + user.lastName : 'Unregistered'}</td>
          <td className="text-left">{user.email}</td>
          <td className="text-left">{moment(user.date).format('MM/DD/YYYY')}</td>
          <td className="text-left">{moment(user.dateLastVisit).format('MM/DD/YYYY HH:MM A')}</td>
          <td className="text-center"><Button className="color-white default-back-color border-0 p-2" style={{fontSize: '15px'}} onClick={() => this.showModal(user)}><i class="fa fa-trash"></i>  Delete</Button></td>
        </tr>
      );
    });
  }

  getCSVHeaders() {
    return [
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Role", key: "role" },
      { label: "Date Joined", key: "dateJoined" },
      { label: "Date last", key: "dateLastVisited" },
      { label: "Date Joined", key: "dateJoined" },
      { label: "District Name", key: "districtName" },
      { label: "School Name", key: "schoolName" },
      { label: "Status", key: "status" }
    ]
  }

  getCSVData() {
    const moment = require('moment');
    let data = this.state.users.map((user) => {
      return {
        name: user.firstName ? user.firstName + ' ' + user.lastName : 'Unregistered',
        email: user.email,
        role: user.role,
        dateJoined: moment(user.date).format('MM/DD/YYYY'),
        dateLastVisited: moment(user.dateLastVisit).format('MM/DD/YYYY HH:MM A'),
        districtName: user.district ? user.district.name : 'Unaffiliated',
        schoolName: user.school ? this.getSchoolName(user.district, user.school) : "Unaffiliated",
        status: user.status === constant['STATUS']['ACTIVE'] ? 'Active'
          : user.status === constant['STATUS']['EMAILED'] ? 'Emailed' : 'Deleted'
      }
    });
    return data;
  }

  onChangeKeyword = (e) => {
    let tempUsers = [];
    if(e.target.value.length > 0) {
      this.state.totalUsers.forEach(user => {
        let name = user.firstName + ' ' + user.lastName;
        if(name.toLowerCase().includes(e.target.value.toLowerCase())) {
          tempUsers.push(user);
          return
        }
        if(user.email && user.email.toLowerCase().includes(e.target.value.toLowerCase())) {
          tempUsers.push(user);
          return
        }
        if(user.district && user.district.name.toLowerCase().includes(e.target.value.toLowerCase())) {
          tempUsers.push(user);
          return
        }
        if(user.district && user.school && this.getSchoolName(user.district, user.school).toLowerCase().includes(e.target.value.toLowerCase())) {
          tempUsers.push(user);
          return
        }
      })
    } else {
      tempUsers = [...this.state.totalUsers];
    }
    this.setState({
      keyword: e.target.value,
      users: tempUsers
    })
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <div className="mr-auto ml-auto col-md-8" style={{ textAlign: 'center' }}>
                <h3 className="title">User Management</h3>
              </div>
              <div className="mr-auto ml-auto col-lg-10" style={{ textAlign: 'right' }}>
                {this.state.users.length > 0 &&
                  <CSVLink
                    filename="rubric-user-monitor.csv"
                    headers={this.getCSVHeaders()} data={ this.getCSVData()}
                    className="default-back-color font-crm mt-4 btn btn-secondary btn-md">
                    Download CSV
                  </CSVLink>
                }
                {this.state.users.length > 0 &&
                  <Row>
                    <Col lg='6'>
                      <InputGroup
                        className={this.state.searchFocus ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_zoom-bold"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={this.onChangeKeyword}
                          value={this.state.keyword}
                          placeholder=""
                          id="keyword"
                          type="text"
                          autoComplete="on"
                          onFocus={() => this.setState({ searchFocus: true })}
                          onBlur={() => this.setState({ searchFocus: false })}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                }
                <Card className="card-plain table-card">
                  <CardBody>
                    {this.state.users.length > 0 ?
                      <Table bordered responsive>
                        <thead>
                          <tr>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Date Joined</th>
                            <th className="text-left">Date Last Visited</th>
                            <th className="text-left" width='120px'>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.renderUserListTableData()
                          }
                        </tbody>
                      </Table>
                      :
                      <h4 style={{ textAlign: 'center' }}>No User Found</h4>
                    }
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <Modal
            modalClassName="modal-question"
            isOpen={this.state.modal}
            toggle={() => this.setState({ modal: false })}
          >
            <div className="modal-title">
              <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning</h5>
            </div>
            <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  Are you sure you want to permanently delete this User?  This can not be undone.
                </Label>
              </Col>
            </div>
          </div>
            <ModalFooter className="justify-content-center" style={{ gap: '10px' }}>
              <Button
                className="red-alert-color font-crm action-save ml-3"
                onClick={() => this.setState({modal: false})}
              >
                Cancel
              </Button>
              <Button
                className="default-back-color font-crm action-save mr-3"
                onClick={this.deleteUser}
              >
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Users);