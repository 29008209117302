import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
  ModalFooter,
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";

class DistrictModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'School',
      email: '',
      name: '',
      index: -1, 
      status: '', 
      archivedStatus: false, 
      confirmAdminDelete: false,
      backWarningModal:false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.name !== '' || nextProps.email !== '' || nextProps.type !== '' || nextProps.index !== -1) {
      this.setState({
        name: nextProps.name,
        email: nextProps.email,
        index: nextProps.index,
        type: nextProps.type
      });
    }
  }

  componentDidMount() {

  }

  onClickUpdate = () => {
    if (this.state.type === 'School' && this.state.name === '') {
      toast.info('Email can not be empty');
      return;
    }
    this.props.updateDistrictSchoolData({
      type: this.state.type,
      index: this.state.index,
      name: this.state.name,
      email: this.state.email,
      status: this.state.status
    });
    this.setState({status: ''})
  }

  onChangeName = (e) => {
    let tempName = {...this.state.email}
    tempName.name = e.target.value
    this.setState({ email: tempName })
  }

  deleteAdmins = (email) => {
    this.setState({ confirmAdminDelete: true })  }

  archiveBtn = (email) => {
    let temp = {...this.state.email}
    temp.archived = !email.archived
    this.setState({email: temp})
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Modal
          modalClassName="modal-district1"
          isOpen={this.props.districtModal}
          toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color">
             {`Edit ${this.props.userType} Information`}
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              {this.state.status !== 'delete' && 
                <Col style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
                  <Col lg={1} style={{padding: "0px"}} >
                    <Button
                      className="btn-icon btn-neutral btm btn-twitter"
                    >
                      <i className="now-ui-icons ui-2_settings-90"></i>
                    </Button>
                  </Col>
                  <Col lg={6}>
                    <Input
                      onChange={(e) => this.onChangeName(e)}
                      value={this.state.email.name}
                      placeholder="District Name Here"
                      id="name"
                      type="text"
                      autoComplete="off"
                      required
                      onFocus={() => this.setState({ nameFocus: true })}
                      onBlur={() => this.setState({ nameFocus: false })}
                    />
                  </Col>
                  <Col lg={2} style={{display: 'flex', justifyContent: 'end', padding: "0px"}}>
                    <Button className="color-white border-0 p-2" style={{backgroundColor: "#cca923"}} onClick={() => this.archiveBtn(this.state.email)} >
                      <i className="fa fa-archive"></i> {this.state.email.archived === true ? "Archived" : "Archive" }
                    </Button>
                  </Col>
                  <Col lg={2} style={{display: 'flex', justifyContent: 'end', padding: "0px"}}>
                    <Button className="color-white border-0 p-2" style={{backgroundColor: "#b80838"}} onClick={() => this.deleteAdmins(this.state.email)} >
                      <i className="fa fa-trash"></i>  Delete
                    </Button>
                  </Col>
                </Col>
              }
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center', gap: '30px' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { 
                // this.props.closeDistrictModal(); 
                this.setState({status: '', 
                backWarningModal: true}); }}
            >
              Back
            </Button>
            <Button
              className="default-back-color font-crm"
              onClick={() => this.onClickUpdate()}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.confirmAdminDelete}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning</h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  {`Are you sure you want to permanently delete this ${this.props.userType}?  This can not be undone.`}
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { this.setState({ confirmAdminDelete: false })}}
            >
              Cancel
            </Button>
            <Button
              className="default-back-color font-crm mr-2"
              onClick={() => { this.setState({ 
                confirmAdminDelete: false,
                status: 'delete'
                })
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.backWarningModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning</h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  {`Your changes have NOT been saved. Would you like to save them or cancel them? `}
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { 
                this.props.cancelEdit(); 
                this.setState({ 
                backWarningModal: false,
                }) 
              }}
            >
              Cancel
            </Button>
            <Button
              className="default-back-color font-crm mr-2"
              onClick={() => { this.setState({ 
                backWarningModal: false,
                })
                this.onClickUpdate();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default DistrictModal;