import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
  ModalFooter,
  Button,
} from "reactstrap";

class ManagerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  displaySchools(schools, modalShools) {
    let currentSchools = [];
    let remainSchools = []
    let tempSchools = schools.filter((item) => item.archived === false || item.archived===undefined)
    if (modalShools) {
      for (let i = 0; i < modalShools.length; i++) {
        for (let j = 0; j < tempSchools.length; j++ ) {
          if (modalShools[i] === tempSchools[j]._id) currentSchools.push(tempSchools[j])
        }
      }

      for (let j = 0; j < tempSchools.length; j++) {
        let count = 0
        for (let i = 0; i < modalShools.length; i++) {
          if (tempSchools[j]._id === modalShools[i]) count++
        }
        if (count === 0) remainSchools.push(tempSchools[j])
      }
    }

    return <div style={{marginBottom: "30px"}}>  
      <p style={{fontSize: "20px", fontWeight: 400}}>
        <span style={{fontSize: "24px", fontWeight: 600}}>{this.props.manager.name}</span>{` manages `} <span style={{fontSize: "24px", fontWeight: 600}}>{modalShools && modalShools.length ? modalShools.length : 0}</span> schools:
      </p>
      <div style={{display: 'flex', marginTop: "30px"}}>
        <div style={{width: '50%', display: "flex", flexDirection: "column", gap: "10px"}}>
          {
            
            currentSchools.length > 0 ? currentSchools.sort((a, b) => a.name > b.name ? 1 : -1).map(school => {
              return (
                <div 
                  style={{display: 'flex', alignItems: 'center', gap: '20px', cursor: 'pointer', fontSize: '18px'}} key={school._id}
                  onClick={() => this.props.deleteCurrentSchool(school)}
                >
                    <i className="now-ui-icons ui-1_simple-delete" style={{backgroundColor: "#648292", padding: "5px", color: 'white'}}></i>
                    <div>{school.name}</div>
                </div>
              )
            }) : <p style={{fontSize: '18px', fontWeight: 400}}>There is no school that can manage</p>
          }
        </div>
        <div style={{width: '50%', display: "flex", flexDirection: "column", gap: "10px"}}>
          {
            remainSchools.sort((a, b) => a.name > b.name ? 1 : -1).map(school => {
              return (
                <div 
                  style={{display: 'flex', alignItems: 'center', gap: '20px', cursor: 'pointer', fontSize: '18px'}} key={school._id}
                  onClick={() => this.props.addOtherSchool(school)}
                >
                    <i className="now-ui-icons ui-1_simple-add" style={{backgroundColor: "#648292", padding: "5px", color: 'white'}}></i>
                    <div>{school.name}</div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Modal
          modalClassName="modal-district1"
          isOpen={this.props.managerModal}
          toggle={() => { this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color">Assign Schools</h5>
          </div>
          <div className="modal-body just">
            {
              this.props.district.schools && this.displaySchools(this.props.district.schools, this.props.modalSchools)
            }
          </div>
          <ModalFooter style={{ justifyContent: 'center', gap: '30px' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { this.props.closeDistrictModal(); }}
            >
              Back
            </Button>
            <Button
              className="default-back-color font-crm"
              onClick={() => this.props.saveDistrictData()}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        
      </>
    )
  }
}

export default ManagerModal;