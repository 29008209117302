import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ReactDOM from 'react-dom';
import moment from "moment";
import _ from "lodash";
import Switch from "react-switch";
// reactstrap components
import {
  Row,
  Button,
  Modal,
  ModalFooter,
  FormGroup,
  Input,
  Table,
  Card,
  CardBody,
  Container,
  Col,
  Label
} from "reactstrap";
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import constant from "../../shared/constant";

class Actions extends Component {
  constructor(props) {
    super(props);

    this.onDrop = (files) => {
      if (files.length === 0) {
        return;
      }
      const file = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
      this.setState(prevState => ({
        note: {
          ...prevState.note,
          image: file
        }
      }));
      this.setState({ files })
    };

    this.state = {
      actionModal: false,
      action: {
        student: '',
        teacher: '',
        component: null,
        teacherFirst: false,
        type: constant['OBSERVATION_TYPE']['ACTION']
      },
      customizedIndicator: {},
      checked: false,
      noteModal: false,
      indicator: {},
      file: null,
      inputState: true, 
      indicators: []
    };
    this.handleChange = this.handleChange.bind(this);
    localStorage.setItem('teacherFirst', true);
  }

  componentWillReceiveProps(props) {
    this.setState({ indicators: props.indicators });
  }

  handleChange(checked) {
    if(checked) {
      ReactDOM.findDOMNode(this.refs.teacherInput).focus();
    } else {
      ReactDOM.findDOMNode(this.refs.studentInput).focus();
    }
    this.setState({ checked });
    const action = Object.assign({}, this.state.action);
    action.teacherFirst = !action.teacherFirst;
    localStorage.setItem('teacherFirst', action.teacherFirst);
    this.setState({ action: action });
  }
  onClickElement = (action) => {
    if (this.disableUserAccess()) return;
    if (this.props.obData.status !== constant['OBSERVATION_STATUS']['COMPLETED']) {
      this.setState({ action: action }, () => {
        this.setState({ actionModal: true }, () => {
          setTimeout(() => {
            ReactDOM.findDOMNode(this.refs.teacherInput).focus();
          }, 1)
        });
      });
    } else return;
  }

  openActionModal = () => {
    if (this.disableUserAccess()) return;
    this.setState({ actionModal: true }, () => {
      setTimeout(() => {
        if(this.state.checked) {
          ReactDOM.findDOMNode(this.refs.studentInput).focus();
        } else {
          ReactDOM.findDOMNode(this.refs.teacherInput).focus();
        }
        this.setState({
          action: { teacherFirst: (localStorage.getItem('teacherFirst') === 'true')}
        });
      }, 1)
    });
  }

  submitActionData = () => {
    if (this.state.action.student === '' && this.state.action.teacher === '') {
      return;
    }
    let actions = this.props.obData.actions ? this.props.obData.actions : [];
    let action = actions.find((action) => { return action._id === this.state.action._id });
    if (action) {
      action.student = this.state.action.student;
      action.teacher = this.state.action.teacher;
      action.teacherFirst = this.state.action.teacherFirst;
    } else {
      actions.unshift({ ...this.state.action, type: constant['OBSERVATION_TYPE']['ACTION'] });
    }
    this.props.saveObservationData(constant['DATA_SAVE_TYPE']['ACTIONS'], actions);
    this.setState({ action: {}, actionModal: false });
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
    const action = Object.assign({}, this.state.action);
    action[e.target.id] = e.target.value;
    this.setState({ action: action });
  };

  deleteNote = (cIndicator) => {
    let tempIndicators = [...this.props.obData.indicators]
    let index = tempIndicators.findIndex((item) => item._id === cIndicator._id)
    tempIndicators.splice(index, 1)
    this.props.saveObservationData(constant['DATA_SAVE_TYPE']['INDICATORS'], tempIndicators);
  }

  renderActions = () => {
    const actions = this.props.obData.actions.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return actions.map((action) => {
      return (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#dedede', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  #dedede' }}
          date={moment(action.date).format('H:mm:A')}
          iconStyle={{ background: '#648292', color: '#fff' }}
          icon={<i className="now-ui-icons ui-1_calendar-60"></i>}
          onTimelineElementClick={() => this.onClickElement(action)}
        > 
        
        {!action.teacherFirst ?
          <>
            {action.student &&
              <p>
                <span className="color-default">Student: </span><span className="color-black font-italic">{action.student}</span>
              </p>
            }
            {action.teacher &&
              <p>
                <span className="color-default">Teacher: </span><span className="color-black font-italic">{action.teacher}</span>
              </p>
            }
          </>
          :
          <>
            {action.teacher &&
              <p>
                <span className="color-default">Teacher: </span><span className="color-black font-italic">{action.teacher}</span>
              </p>
            }
            {action.student &&
              <p>
                <span className="color-default">Student: </span><span className="color-black font-italic">{action.student}</span>
              </p>
            }
          </>
          }
        </VerticalTimelineElement>
      )
    })
  }

  renderCustomIndicators = () => {
    if (this.props.obData.indicators === undefined) return (<></>);
    let cIndicators = this.props.obData.indicators.filter((indicator) => { return indicator.type === constant['OBSERVATION_TYPE']['CUSTOM'] });
    cIndicators.unshift({}); // Add empty indicator for new custom indicator
    const indicators = cIndicators.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    return indicators.map((cIndicator, index) => {
      const { title, checked, _id } = cIndicator;
      return (
        <>
          {index === 0 ?
            <tr>
              <td colSpan="2">
                <FormGroup>
                  <Input
                    disabled={this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                    style={{cursor: this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed"}}
                    onChange={this.onChangeCustomizedIndicator}
                    value={this.state.customizedIndicator.title}
                    placeholder=""
                    autoComplete="on"
                    type="textarea"
                    rows="4"
                    id="customIndicator"
                    onKeyPress={this.onKeyPress}
                  ></Input>
                </FormGroup>
                <Button
                 disabled={this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                  style={{cursor: this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed"}}
                  className="default-back-color font-crm mt-4"
                  onClick={this.saveCustomizedIndicator}
                  size="md">
                  Save
                </Button>
              </td>
            </tr>
            :
            <tr key={_id}>
              <td className="text-left">
                <FormGroup check style={{ marginLeft: '5px' }}>
                  <Label check>
                    <Input 
                      disabled={this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                      defaultChecked={checked} 
                      type="checkbox" 
                      onChange={(e) => this.onChangeCheckStatus(e, _id)} />
                    <span className="form-check-sign"></span>
                  </Label>
                </FormGroup>
              </td>
              <td className="text-left" onClick={() => {this.setState({customizedIndicator: cIndicator})}}>
                <h5 className="font-crm" style={{wordBreak: 'break-all'}}>
                  {title}
                </h5>
                {
                  this.renderNotes(cIndicator)
                }  
              </td>
              <td className="text-right" style={{ verticalAlign: 'initial' }}>
                <Button
                  id="add-note"
                  disabled={this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                  style={{ backgroundColor: '#5bada3', cursor: this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed" }}
                  className="btn-icon btn-square btn"
                  onClick={()=> { this.onClickNoteIndicator(cIndicator); this.setState({inputState : false})}}
                  size="md">
                  <i className="now-ui-icons design_image"></i>
                </Button>
              </td>
              <td className="text-right" style={{ verticalAlign: 'top', paddingTop: '18px' }}>
                <div
                  onClick={() => this.deleteNote(cIndicator)}

                >
                  <i class="fa fa-trash" style={{color: "#648292", fontSize: '25px'}}></i>
                </div>
              </td>
            </tr>
          }
        </>
      );
    });
  }

  renderNotes = (indicator, modal = false) => {
    if (indicator.notes && indicator.notes.length > 0) {
      return indicator.notes.map((note, nIndex) => {
        return (
          <>
            <div className="note" style={{width: !modal ? 'inherit': '100%', overflow: modal ? 'hidden' : 'inherit'}}>
              <div className="d-flex align-items-center justify-content-between">
                <Label style={{ display: 'block', marginTop: '5px' }} id="edit-note" onClick={() => this.onClickNoteIndicator(indicator, nIndex, note)}>
                  {note.text &&
                    <>
                  -  {note.text}
                    </>
                  }
                </Label>
                {modal && note.text &&
                  <Button
                    onClick={()=> { this.onClickNoteRemove(indicator, nIndex); this.setState({ noteModal: false, note: {}, nIndex: -1 })}}
                    className="btn-icon btn-neutral btn note-image-remove">
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </Button>
                }
              </div>
              {note.image &&
                <div className="note-image d-flex align-items-center justify-content-between">
                  <img
                    src={note.image}
                    alt="image"
                    width={50}
                    onClick={() => { this.onClickNoteIndicator(indicator, nIndex, note); }} />
                  {modal &&
                    <Button
                      onClick={()=> { this.onClickNoteImageRemove(indicator, nIndex); this.setState({ noteModal: false, note: {}, nIndex: -1 })}}
                      className="btn-icon btn-neutral btn note-image-remove">
                      <i className="now-ui-icons ui-1_simple-remove"></i>
                    </Button>
                  }
                </div>
              }
              <span className="tooltiptext">Click To Edit</span>
            </div>
          </>
        );
      });
    }
  }

  onClickNoteImageRemove = (indicator, noteIndex) => {
    if (this.disableUserAccess()) return;
    let indicators = this.props.obData.indicators;
    let ind = indicators.find((ind) => { return ind._id === indicator._id });
    const indIndex = indicators.findIndex((ind) => { return ind._id === indicator._id });
    let note = ind.notes[noteIndex];
    note.image = null;
    ind.notes.splice(noteIndex, 1, note);
    this.props.removeNote(indicators, indIndex, noteIndex);
  }
  
  onClickNoteIndicator(indicator, nIndex, note) {
    if (this.disableUserAccess()) return;
    this.setState({ indicator: indicator, note: note, nIndex: nIndex !== undefined ? nIndex : -1 }, () => {
      this.setState({ noteModal: true }, () => {
        setTimeout(() => {
          if(this.state.inputState == false) return;
          ReactDOM.findDOMNode(this.refs.noteInput).focus();
        }, 1)
      });
    });
  }

  onChangeNote = (e) => {
    const text = e.target.value;
    this.setState(prevState => ({
      note: {
        ...prevState.note,
        text: text
      }
    }));
  }

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      if (e.target.id === 'customIndicator') {
        this.saveCustomizedIndicator();
      } else if (e.target.id === 'note') {
        this.addNote();
      }
    }
  }

  getBackgroundColor = () => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === this.props.type) {
      return '#5bada3';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === this.props.type) {
      return '#158574';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === this.props.type) {
      return '#cd1f3d';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === this.props.type) {
      return '#136ba5';
    } else {
      return '#d6af20';
    }
  }

  onChangeCustomizedIndicator = (e) => {
    let indicator = this.state.customizedIndicator;
    indicator.title = e.target.value;
    indicator.notes = null;
    indicator.type = constant['OBSERVATION_TYPE']['CUSTOM'];
    indicator.checked = true;
    indicator.component = null;
    this.setState({ customizedIndicator: indicator });
  }

  saveCustomizedIndicator = () => {
    if (this.disableUserAccess() || this.state.customizedIndicator.title === '' || _.isEmpty(this.state.customizedIndicator)) return;
    let indicators = this.props.obData.indicators;
    if (this.state.customizedIndicator._id) { // Edit
      let indicator = indicators.find((indicator) => { return indicator._id === this.state.customizedIndicator._id });
      indicator.title = this.state.customizedIndicator.title;
    } else {
      indicators.push({ ...this.state.customizedIndicator, type: constant['OBSERVATION_TYPE']['CUSTOM'] });
    }
    this.setState({ customizedIndicator: {title: '', id: null, notes: null} });
    this.props.saveObservationData(constant['DATA_SAVE_TYPE']['INDICATORS'], indicators);
    
    this.setState({
      action: { teacherFirst: localStorage.getItem('teacherFirst')}
    });
  }

  addNote = () => {
    if (this.state.inputState === true && _.isEmpty(this.state.note)) {
      toast.error('Please add note.');
      return;
    };
    if (this.state.inputState === false && (_.isEmpty(this.state.note) || (!this.state.note || this.state.note === undefined))) {
      return;
    };

    let indicators = this.props.obData.indicators;
    let indicator = indicators.find((indicator) => { return indicator._id === this.state.indicator._id });
    indicator.checked = true;
    if (this.state.nIndex === -1) {
      if(indicator.notes == null) indicator.notes = [];
      indicator.notes.push(this.state.note);
    } else {
      indicator.notes.splice(this.state.nIndex, 1, this.state.note);
    }
    this.setState({ indicator: indicator, nIndex: -1, note: {}, indicators: indicators }, () => {
    this.props.saveObservationData(constant['DATA_SAVE_TYPE']['INDICATORS'], indicators);
    });
  }

  disableUserAccess = () => {
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ||
      this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      return true;
    } else if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      if (this.state.obData && this.state.obData.observer && (this.props.auth.user.id !== this.state.obData.observer._id)) {
        return true;
      }
    }
    return false;
  }

  onTeacherInputKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      setTimeout(() => {
        ReactDOM.findDOMNode(this.refs.studentInput).focus();
      }, 1);
    }
  }

  onStudentInputKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      this.submitActionData();
    }
  }

  render() {
    return (
      <>
        <div className="mr-auto ml-auto col-md-12">
          <h4 className="mt-5">Enter Actions for Teachers and Students</h4>
          <Row className="justify-content-center">

            <Button 
              disabled={this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
              style={{cursor: this.props.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] && "not-allowed"}}
              className="btn-round color-pink font-crm btn-icon" onClick={this.openActionModal}>
              <i className="now-ui-icons ui-1_simple-add"></i>
            </Button>
          </Row>
          <Container className="actions">
            <Row>
              <Col md="8">
                {(this.props.obData.actions && this.props.obData.actions.length > 0) &&
                  <VerticalTimeline layout={'1-column-left'}>
                    {this.renderActions()}
                  </VerticalTimeline>
                }
              </Col>
              <Col md="4">
                <Card className="mt-4">
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left">
                            <div style={{ textAlign: "center", backgroundColor: "#648292", width: '30px', height: '25px' }}>
                              <span style={{ color: 'white' }}></span>
                            </div>
                          </th>
                          <th className="text-left">Other Observations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.renderCustomIndicators()
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          modalClassName="modal-action"
          isOpen={this.state.actionModal}
          toggle={() => this.setState({ actionModal: false, action: {} })}
        >
          <div className="modal-title">
            <h5 className="default-back-color">Enter new actions for teacher and students</h5>
          </div>
          <div className="modal-body just">
            <div className="row">
              <Switch
                checked={this.state.checked}
                onChange={this.handleChange}
                uncheckedIcon={
                  <div></div>
                }
                checkedIcon={
                  <div></div>
                }
                className="col-1 action-switch"
              />
              <div className="col-11">
                {this.state.checked ?
                  <>
                    <FormGroup>
                      <Input
                        id="student"
                        onChange={this.onChange}
                        value={this.state.action.student}
                        placeholder="Student's action here..."
                        autoComplete="on"
                        type="textarea"
                        rows="1"
                        ref='studentInput'
                        onKeyPress={this.onStudentInputKeyPress}
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="teacher"
                        onChange={this.onChange}
                        value={this.state.action.teacher}
                        placeholder="Teacher's action here..."
                        autoComplete="on"
                        type="textarea"
                        rows="1"
                        ref='teacherInput'
                        onKeyPress={this.onTeacherInputKeyPress}
                      ></Input>
                    </FormGroup>
                  </>
                  :
                  <>
                    <FormGroup>
                      <Input
                        id="teacher"
                        onChange={this.onChange}
                        value={this.state.action.teacher}
                        placeholder="Teacher's action here..."
                        autoComplete="on"
                        type="textarea"
                        rows="1"
                        ref='teacherInput'
                        onKeyPress={this.onTeacherInputKeyPress}
                      ></Input>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="student"
                        onChange={this.onChange}
                        value={this.state.action.student}
                        placeholder="Student's action here..."
                        autoComplete="on"
                        type="textarea"
                        rows="1"
                        ref='studentInput'
                        onKeyPress={this.onStudentInputKeyPress}
                      ></Input>
                    </FormGroup>
                  </>
                }
              </div>
            </div>
          </div>
          <ModalFooter className="justify-content-center">
            <Button
              className="default-back-color font-crm action-save"
              onClick={() => { this.submitActionData(); }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-action"
          isOpen={this.state.noteModal}
          toggle={() => this.setState({ noteModal: false, note: '', nIndex: -1 , inputState : true})}
        >
          <div className="modal-title default-back-color">
            <h5>Enter Visual Indicator</h5>
          </div>
          <div className="modal-body just">
            <div className="notes">
              {
                this.renderNotes(this.state.indicator, true)
              }
            </div>
            {
              this.state.inputState &&
              <FormGroup>
                <Input
                  id="note"
                  placeholder="You can write your note here..."
                  onChange={this.onChangeNote}
                  type="textarea"
                  value={this.state.note && this.state.note.text}
                  rows="1"
                  ref="noteInput"
                  onKeyPress={this.onKeyPress}
                ></Input>
              </FormGroup>
            }
            <Dropzone
              onDrop={this.onDrop}
              accept=".jpeg,.jpg,.ai,.png,.webp"
              minSize={0}
              maxSize={constant['MAX_UPLOAD_IMAGE_SIZE']}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="container">
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {this.state.note && this.state.note.image ?
                      <img src={(this.state.note && this.state.note.image && typeof this.state.note.image === 'string' ? this.state.note.image : this.state.note.image.preview)} alt="image" />
                      :
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', padding: '16px 16px 16px 32px', color: 'black' }}>
                        <img src={require("assets/img/pic.png")} style={{ width: 40 }} />
                        <div style={{ padding: '0 16px 0 32px' }}>
                          <p className={'thumbnail-intro'}>Drag or Upload Graphic Here</p>
                          <span>.jpeg,.jpg,.ai,.png,.webp</span>
                        </div>
                      </div>
                    }
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <ModalFooter className="justify-content-center">
            <Button
                className="default-back-color font-crm action-save mr-3"
                onClick={() => { this.setState({ noteModal: false, note: {}, nIndex: -1, inputState: true }) }}
              >
                Cancel
            </Button>
            <Button
              className="default-back-color font-crm action-save ml-3"
              onClick={() => { this.addNote(); this.setState({ noteModal: false, note: {}, nIndex: -1, inputState: true }) }}
            >
              Save {'&'} Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

Actions.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Actions)
