import React, { Component } from 'react'
import moment from "moment";
import "../consolidate/Consolidate.css"
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  Col,
  Label,
  Row
} from "reactstrap";

import constant from "../../shared/constant";
import ModalImage from "react-modal-image";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';

export class ComponentToPrint extends Component {
	constructor(props) {
		super(props);
		this.state = {
			obData: {},
			components: [],
			domains: [],
			filteredComponents: [],
      componentsModal: false,
      dataWillSaved: {},
		}
	}

	componentDidMount() {
    document.body.classList.add("consolidate-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    if (observationId !== null) {
      this.loadCurrentObserveData(observationId);
      this.getComponents();
      this.getDomains();
    } else {
      console.log("Please select a classroom to observe or start new")
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("consolidate-page");
    document.body.classList.remove("sidebar-collapse");
  }

	loadCurrentObserveData = (observationId) => {
    axios
      .get(`/api/observations/${observationId}`)
      .then((res) => {
        if (res.data.indicators.length !== 0) {
          this.setState({ obData: res.data });
        } else {
          // toast.info("Please select at least an indicator to consolidate");
          this.props.history.push('/observe');
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log('Please check your netwrok connection.');
        } else {
          console.log(err.message || 'Error occured while getting observation data.');
        }
        // this.props.history.goBack();
      });
  }

	getComponents = () => {
    axios
      .get('/api/components')
      .then((res) => {
        if (res.data) {
          this.setState({ components: res.data });
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log('Please check your netwrok connection.');
        } else {
          console.log(err.message || 'Error occured while retrieving components.');
        }
      });
  }

	getDomains = () => {
    axios
      .get('/api/domains')
      .then((res) => {
        if (res.data) {
          const domains = res.data.sort((a, b) => { return a.index - b.index });
          this.setState({ domains: domains });
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log('Please check your netwrok connection.');
        } else {
          console.log(err.message || 'Error occured while retrieving domains.');
        }
      });
  }

	displayComponents = (data) => {
    if (data.components == null || data.components.length === 0) {
      return;
    }
    const components = data.components.sort((comA, comB) => {
      return (comA.index < comB.index) ? -1 : (comA.index > comB.index) ? 1 : 0;
    })
    return components.map((component) => {
      let tmpComponent = this.state.components.filter(component1 => component1._id === component);
      return (<div className={`ml-2 circle-badge ${this.getComponentColor(component)}`} onClick={(e) => { this.removeComponent(data, component); }}>{tmpComponent.length > 0 && tmpComponent[0].index}</div>);
    });
  };

	getComponentColor = (component) => {
    let tmpComponent = this.state.components.filter(component1 => component1._id === component);
    // const domain = this.state.domains.find((domain) => domain._id === component.domain);
    if (tmpComponent.length > 0) {
      if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === tmpComponent[0].domain.index) {
        return 'color-domain-1';
      } else if (constant['OBSERVATION_TYPE']['REGULATION'] === tmpComponent[0].domain.index) {
        return 'color-domain-2';
      } else if (constant['OBSERVATION_TYPE']['SAFETY'] === tmpComponent[0].domain.index) {
        return 'color-domain-4';
      } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === tmpComponent[0].domain.index) {
        return 'color-domain-5';
      } else {
        return 'color-domain-3';
      }
    } else {
      return 'color-grey-default';
    }
  }

	renderNotes = (indicator) => {
    if (indicator.notes && indicator.notes.length > 0) {
      return indicator.notes.map((note, nIndex) => {
        return (
          <>
            <Label style={{ display: 'block', marginTop: '5px' }} id="edit-note">
              {note.text &&
                <>
              -  {note.text}
                </>
              }
            </Label>
            {note.image &&
              <div style={{maxWidth : '50px'}}>
                <ModalImage
                  small={note.image}
                  large={note.image}
                  alt="image"
                />
              </div>
            }
          </>
        );
      });
    }
  }

	onClickDomain = (domain, data) => {
    if (this.disableUserAccess()) return;
    if (data.type === constant['OBSERVATION_TYPE']['ACTION'] || data.type === constant['OBSERVATION_TYPE']['CUSTOM']) {
      const filteredComponents = this.state.components.filter((component) => { return component.domain._id === domain._id });
      this.setState({ filteredComponents: filteredComponents, componentsModal: true, dataWillSaved: data });
    }
  }

	disableUserAccess = () => {
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ||
      this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      return true;
    } else if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      if (this.state.obData && this.state.obData.observer && (this.props.auth.user.id !== this.state.obData.observer._id)) {
        return true;
      }
    }
    return false;
  }

	renderDomains = (data, defaultIndicator) => { 
    return this.state.domains.map((domain) => {
      let selected = '';
      data.components.forEach((component) => {
        let tmpComponent = this.state.components.filter(component1 => component1._id === component);
        if (tmpComponent.length > 0 && tmpComponent[0].domain._id === domain._id)
          selected = 'selected';
      });
      const { index } = domain;
      return (
        <>  
          <Button
            disabled={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
            onClick={(e) => { e.preventDefault(); this.onClickDomain(domain, data) }}
            className={`domain-mark domain-mark-${index} domain-mark-${index}-${selected}`} 
            style={{ 
              pointerEvents: 
              this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? 'none' : (defaultIndicator || this.state.printPreview) ? 'none' : '' 
            }} 
          >
            {index}
          </Button>
        </>
      );
    });
  }

	renderVisualIndicators = () => {
    const vIndicators = this.state.obData.indicators.filter((indicator) => {
      return indicator.type !== constant['OBSERVATION_TYPE']['CUSTOM'] &&
        indicator.type !== constant['OBSERVATION_TYPE']['STANDARD'] &&
        indicator.checked === true
    });
    return vIndicators.map((indicator, index) => {
      const { title, _id } = indicator;
      return (
        <tr key={_id}>
					<td className="text-left" style={{ verticalAlign: 'initial', padding: "3px 7px" }}>
						<span style={{ marginLeft: '8px' }} className="font-crm">
							{index + 1}
						</span>
					</td>
					<td className="text-left" style={{padding: "3px 7px"}}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<label style={{ marginLeft: '5px', maxWidth: '76%', marginBottom: '0px'}} className="font-new">{title}</label>
							{
								indicator.components.length > 0 && this.displayComponents(indicator)
							}
						</div>
						{
							this.renderNotes(indicator)
						}
					</td>
					<td className="text-right actions domain" style={{ verticalAlign: 'initial', padding: "3px 7px" }}>
						{
							this.renderDomains(indicator, true)
						}
						<Button
							className="domain-mark color-darker-grey n-a-btn" style={{ pointerEvents: 'none' }}>
							N/A
						</Button>
					</td>
				</tr>
      );
    });
  }

	displayComponents = (data) => {
    if (data.components == null || data.components.length === 0) {
      return;
    }
    const components = data.components.sort((comA, comB) => {
      return (comA.index < comB.index) ? -1 : (comA.index > comB.index) ? 1 : 0;
    })
    return components.map((component) => {
      let tmpComponent = this.state.components.filter(component1 => component1._id === component);
      return (<div className={`ml-2 circle-badge ${this.getComponentColor(component)}`} onClick={(e) => { this.removeComponent(data, component); }}>{tmpComponent.length > 0 && tmpComponent[0].index}</div>);
    });
  };

	onClickTBD = (data) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    data.components = [];
    const obData = this.state.obData;
    if (data.type === constant['OBSERVATION_TYPE']['ACTION']) {
      const dataArray = this.state.obData.actions.map((action) => {
        return action._id === data._id ? data : action
      });
      obData.actions = dataArray;
    } else if (data.type === constant['OBSERVATION_TYPE']['CUSTOM']) {
      const dataArray = this.state.obData.indicators.map((indicator) => {
        return indicator._id === data._id ? data : indicator
      });
      obData.indicators = dataArray;
    }
    axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        this.setState((prevState) => {
          return ({
            ...prevState,
            indicators: res.data.indicators
          })
        })
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
          console.log(err.response.data.message)
        } else if (err.request) {
          console.log('Please check your netwrok connection.')
        } else {
          console.log(err.message || 'Error occured while updating observation data.')
        }
      });
  }

	renderActions = () => {
    const actions = this.state.obData.actions.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    return actions.map((action, index) => {
      const { teacher, student, _id, teacherFirst } = action;
      return (
        <>
          <tr key={_id}>
            <td className="text-left" style={{ verticalAlign: 'initial', padding: "3px 7px" }}>
              <span style={{ marginLeft: '8px' }} className="font-crm">
                {index + 1}
              </span>
            </td>
            <td className="text-left" style={{padding: '3px 7px'}}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  { 
                    teacher && !teacherFirst 
                    ? <div>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-new">Student: <span className="font-italic">{student}</span></label>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-new">Teacher: <span className="font-italic">{teacher}</span></label>
                    </div>
                    : <div>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-new">Teacher: <span className="font-italic">{teacher}</span></label>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-new">Student: <span className="font-italic">{student}</span></label>
                    </div>
                  }
                  {

                  }
                </div>
                <div style={{minWidth: '20%'}}> 
                  {action.components.length > 0 &&
                    this.displayComponents(action)
                  }
                </div>
              </div>
            </td>
            <td className="text-right actions domain" style={{ verticalAlign: 'initial', padding: "3px 7px" }}>
              {
                this.renderDomains(action)
              }
              <Button
                disabled={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                onClick={(e) => { e.preventDefault(); this.onClickTBD(action) }}
                style={{ pointerEvents: 
                  this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? 'none' : this.state.printPreview ? 'none' : '', backgroundColor: action.components.length > 0 ? '#888' : '#648292' }}
                className="domain-mark">
                N/A
              </Button>
            </td>
          </tr>
        </>
      );
    });
  }

	renderCustomizedIndicators = () => {

    const cIndicators = this.state.obData.indicators.filter((indicator) => { return indicator.type === constant['OBSERVATION_TYPE']['CUSTOM'] && indicator.checked });
    return cIndicators.map((indicator, index) => {
      const { title, _id } = indicator;
      return (
        <>
          <tr key={_id}>
            <td className="text-left" style={{ verticalAlign: 'initial', padding: '3px 7px' }}>
              <span style={{ marginLeft: '8px' }} className="font-crm">
                {index + 1}
              </span>
            </td>
            <td className="text-left" style={{padding: '3px 7px'}}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginLeft: '5px', maxWidth: '76%' }} className="font-new">{title}</label>
                {indicator.components.length > 0 &&
                  this.displayComponents(indicator)
                }
              </div>
              {
                this.renderNotes(indicator)
              }
            </td>
            <td className="text-right actions domain" style={{ verticalAlign: 'initial', padding: '3px 7px' }}>
              {
                this.renderDomains(indicator)
              }
              <Button
                disabled={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                onClick={(e) => { e.preventDefault(); this.onClickTBD(indicator) }}
                className="domain-mark" style={{ pointerEvents: this.state.printPreview ? 'none' : '', backgroundColor: indicator.components.length > 0 ? '#888' : '#648292' }}>
                N/A
              </Button>
            </td>
          </tr>
        </>
      );
    });
  }

	render() {
		return (
			<div className='wrapper'>
				<style type="text/css" media="print">
          {`
            @page {
              size: landscape;
            }
          `}
        </style>
				<div className='home'>
					<div className='panel'>
						<div>
							<Card>
								<CardBody>
								<Row className="default-back-color text-white print-row" style={{padding: '20px'}}>
                  <span style={{ marginRight: '30px' }}>Teacher: {this.state.obData.teacher}</span>
                  <span style={{ marginRight: '30px' }}>Grade: {this.state.obData.grade}</span>
                  <span style={{ marginRight: '30px' }}>Lesson: {this.state.obData.topic}</span>
                  <span style={{ marginRight: '30px' }}>Created: {moment(this.state.obData.date).format('MM/DD/YYYY h:mm a')}</span>
                  <span>Last Modified: {moment(this.state.obData.dateModified).format('MM/DD/YYYY h:mm a')}</span>
								</Row>
									<Table responsive className="consolidate-table">
										<thead>
											<tr>
												<th className="text-left" style={{ width: '20px' }}>
													<div className="order-mark">
													</div>
												</th>
												<th className="text-left" colSpan="2">
													<h6 className="grey-back-color padding-sm align-items">
														<span style={{fontSize: '12px'}}>Room Scan Evidence</span>
														<span style={{fontSize: '12px'}}>Domains/Components</span>
													</h6>
												</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.obData.indicators && this.renderVisualIndicators()
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>
							<Card>
								<CardBody>
									<Table responsive>
										<thead>
											<tr>
												<th className="text-left" style={{ width: '30px' }}>
													<div className="order-mark">
													</div>
												</th>
												<th className="text-left" colSpan="2">
                          <h6 className="grey-back-color padding-sm align-items">
                            <span style={{fontSize: '12px'}}>Other Observations</span>
                            <span style={{fontSize: '12px'}}>Domains/Components</span>
                          </h6>
                        </th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.obData.indicators && this.renderCustomizedIndicators()
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>
							<Card>
								<CardBody>
									<Table responsive>
										<thead>
											<tr>
												<th className="text-left" style={{ width: '30px' }}>
													<div className="order-mark">
													</div>
												</th>
												<th className="text-left" colSpan="2">
                          <h6 className="grey-back-color padding-sm align-items">
                            <span style={{fontSize: '12px'}}>Teacher/Student Actions</span>
                            <span style={{fontSize: '12px'}}>Domains/Components</span>
                            </h6>
                          </th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.obData.actions && this.renderActions()
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

ComponentToPrint.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(ComponentToPrint);