import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { registerEmployee } from "../../actions/authActions";


// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle
} from "reactstrap";

// core components
import { GApageView } from "../../shared/gaUtils";
import FooterWhite from "../shared/FooterWhite";
import './auth.css';
import _ from 'lodash';
import constant from "../../shared/constant";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      errors: {},
      firstNameFocus: false,
      lastNameFocus: false,
      emailFocus: false,
      passwordFocus: false,
      district: '',
      school: '',
      role: constant['ROLE']['OBSERVER'],
      editEmail: true,
      observation: null,
      passwordShown: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    if (nextProps.auth.isAuthenticated) {
      if (this.state.observation) {
        this.props.history.push(`/debrief/${this.state.observation}`);
      } else {
        if (localStorage.getItem('isSchoolAdmin') === 'true') {
          this.props.history.push("/schooladmin");
        } else {
          this.props.history.push("/"); // push user to home page when they login
        }
      }
    }
  }

  componentDidMount() {
    const queryString = require('query-string');
    const parsed = queryString.parse(this.props.location.search);
    if (!_.isEmpty(parsed)) {
      if (parsed.district && parsed.role !== constant['ROLE']['TEACHER']) {
        this.setState({
          district: parsed.district,
          school: parsed.school ? parsed.school : '',
          email: parsed.email ? parsed.email : '',
          role: parsed.role,
          editEmail: parsed.email ? true : false
        });
      } else {
        this.setState({
          role: parsed.role,
          email: parsed.email ? parsed.email : '',
          editEmail: parsed.email ? true : false,
          observation: parsed.observation
        });
      }
    } else {
      this.setState({ editEmail: false });
      // If logged in and user navigates to Register page, should redirect them to home
      if (this.props.auth.isAuthenticated) {
        if (localStorage.getItem('isSchoolAdmin') === 'true') {
          this.props.history.push("/schooladmin");
        } else {
          this.props.history.push("/"); // push user to home page when they login
        }
        return;
      }
    }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    GApageView("Register");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      // district: this.state.district !== '' ? this.state.district : null,
      // school: this.state.school,
      // role: this.state.role,
      // observer: this.state.observer
    };
    this.props.registerUser(newUser);
  };

  togglePassword = () => {
    this.setState({
        passwordShown: !this.state.passwordShown
    })
  };

  render() {
    const { errors, passwordShown } = this.state;
    return (
      <>
        <div className="page-header header-filter"
          style={{
            backgroundImage: "url(" + require("assets/img/back-landing.jpg") + ")",
          }}>
          <svg className="divider-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern1" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back1.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern1)" points="0,0 100,0 0,100" />
          </svg>
          <svg className="divider-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern2" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back2.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern2)" points="50,100 100,0 100,100" />
          </svg>
          <div className="content">
            <Container className="register-container">
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <Row className="brand justify-content-center">
                    <img src={require("assets/img/logo.png")} alt="" />
                  </Row>
                  <Card className="card-login mt-4">
                    <Form noValidate action="" className="form" method="" onSubmit={this.onSubmit}>
                      <CardBody>
                        <CardTitle className="text-center" tag="h3">
                          Sign Up
                      </CardTitle>
                        <Row className="fields-signup">
                          <Col md="6">
                            <Row>
                              <InputGroup
                                className={this.state.firstNameFocus ? "input-group-focus" : ""}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons users_circle-08"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  onChange={this.onChange}
                                  value={this.state.firstName}
                                  error={errors.firstName}
                                  placeholder="First Name"
                                  id="firstName"
                                  type="text"
                                  required
                                  onFocus={() => this.setState({ firstNameFocus: true })}
                                  onBlur={() => this.setState({ firstNameFocus: false })}
                                  autoComplete="off"
                                ></Input>
                              </InputGroup>
                              <span className="color-default text-left">{errors.firstName}</span>
                            </Row>
                          </Col>
                          <Col md="6">
                            <Row>
                              <InputGroup
                                className={this.state.lastNameFocus ? "input-group-focus" : ""}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons users_circle-08"></i>
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  onChange={this.onChange}
                                  value={this.state.lastName}
                                  error={errors.lastName}
                                  placeholder="Last Name"
                                  id="lastName"
                                  type="text"
                                  required
                                  onFocus={() => this.setState({ lastNameFocus: true })}
                                  onBlur={() => this.setState({ lastNameFocus: false })}
                                  autoComplete="off"
                                ></Input>
                              </InputGroup>
                              <span className="color-default text-left">{errors.lastName}</span>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="fields-signup">
                          <Col md="6">
                            <Row>
                              <InputGroup
                                className={this.state.emailFocus ? "input-group-focus" : ""}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="now-ui-icons ui-1_email-85"></i>
                                  </InputGroupText>
                                </InputGroupAddon>

                                <Input
                                  onChange={this.onChange}
                                  value={this.state.email}
                                  error={errors.email}
                                  placeholder="Your Email"
                                  readOnly={this.state.editEmail}
                                  required
                                  id="email"
                                  type="email"
                                  onFocus={() => this.setState({ emailFocus: true })}
                                  onBlur={() => this.setState({ emailFocus: false })}
                                  autoComplete="off"
                                ></Input>
                              </InputGroup>
                              <span className="text-left" style={{color: "red"}}>{errors.email}</span>
                            </Row>
                          </Col>
                          <Col md="6">
                            <Row>
                              <InputGroup
                                className={
                                  (this.state.passwordFocus ? " input-group-focus" : "")
                                }
                              >
                                <InputGroupAddon addonType="prepend" onClick={this.togglePassword}>
                                  <InputGroupText>
                                    <i className="far fa-eye"></i>
                                  </InputGroupText>
                                </InputGroupAddon>

                                <Input
                                  onChange={this.onChange}
                                  value={this.state.password}
                                  error={errors.password}
                                  placeholder="Password"
                                  id="password"
                                  type={passwordShown ? 'password' : 'text'}
                                  required
                                  autoComplete="off"
                                  onFocus={() => this.setState({ passwordFocus: true })}
                                  onBlur={() => this.setState({ passwordFocus: false })}
                                ></Input>
                              </InputGroup>
                              <span className="color-default text-left">{errors.password}</span>
                              <span className="color-default text-left">{errors.passwordsNotMatch}</span>

                            </Row>
                          </Col>
                        </Row>
                        <span className="color-default text-left">{errors.error}</span>
                        <div className="justify-content-center mt-5">
                          <a
                            className="link-signup color-default"
                            rel="noopener noreferrer"
                            href="https://docs.google.com/document/d/1pdW1z1BKojGxZnElYMr7mGWqraR9XeapQ02zEpFjKPY/edit?usp=sharing"
                            target="_blank">
                            You agree to our TOS and Privacy Policy
                        </a>
                        </div>
                        <Row>
                          <Col md="8" className="ml-auto mr-auto">
                            <Button
                              block
                              className="btn-round default-back-color font-crm mt-4"
                              type="submit"
                              size="md"
                            >
                              Enter Now
                          </Button>
                            <div className="text-center">
                              <Link className="link-signup color-default" to="/login">Sign In</Link>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
            <FooterWhite />
          </div>
        </div>
      </>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { registerUser, registerEmployee }
)(withRouter(Register));