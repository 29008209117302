import React, { Component } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle, Label
} from "reactstrap";

// core components
import { GApageView } from "../../shared/gaUtils";
import FooterWhite from "../shared/FooterWhite";
import './auth.css';

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      passwordFocus: false,
      password: '',
      email: ''
    };
  }

  async componentDidMount() {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const queryString = require('query-string');
    const parsed = queryString.parse(this.props.location.search);
    await axios.get(`api/users/reset/${parsed.token}`)
      .then((res) => {
        toast.info(res.data.message);
        if (res.status === 200) {
          this.setState({ email: res.data.email });
        }
      }).catch((err) => {
        console.log(err);
      });
    GApageView('Reset Password');
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    axios
      .post('api/users/updatePasswordViaEmail', {
        email: this.state.email,
        password: this.state.password
      }).then((res) => {
        console.log(res.data);
        toast.info(res.data.message);
        if (res.status === 200) {
          this.props.history.push('/login');
        }
      }).catch((err) => {
        console.log(err);
        toast.error(typeof err.response !== "undefined" ? err.response.data.message : err.message);
      });
  };

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-header header-filter"
          style={{
            backgroundImage: "url(" + require("assets/img/back-landing.jpg") + ")",
          }}>
          <svg className="divider-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern1" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back1.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern1)" points="0,0 100,0 0,100" />
          </svg>
          <svg className="divider-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern2" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back2.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern2)" points="50,100 100,0 100,100" />
          </svg>
          <div className="content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8" lg="5">
                  <Row className="brand justify-content-center">
                    <img src={require("assets/img/logo.png")} alt="" />
                  </Row>
                  <Card className="card-login mt-4">
                    <Form noValidate action="" className="form" method="" onSubmit={this.onSubmit}>
                      <CardBody>
                        <CardTitle className="text-center" tag="h3">
                          Reset Password
                        </CardTitle>
                        <Label className="label-default">Please enter a new password for your account:</Label>
                        <InputGroup
                          className={this.state.passwordFocus ? "input-group-focus mt-4" : "mt-4"}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_lock-circle-open"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={this.onChange}
                            value={this.state.password}
                            placeholder="Password"
                            id="password"
                            type="password"
                            onFocus={() => this.setState({ passwordFocus: true })}
                            onBlur={() => this.setState({ passwordFocus: false })}
                          />
                        </InputGroup>
                      </CardBody>
                      <CardFooter className="text-center">
                        <div className="justify-content-center mt-2">
                          <a
                            className="link-signup color-default"
                            rel="noopener noreferrer"
                            href="https://docs.google.com/document/d/1pdW1z1BKojGxZnElYMr7mGWqraR9XeapQ02zEpFjKPY/edit?usp=sharing"
                            target="_blank">
                            You agree to our TOS and Privacy Policy
                          </a>
                        </div>
                        <Button
                          block
                          className="btn-round default-back-color font-crm"
                          type="submit"
                          size="md"
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
            <FooterWhite />
          </div>
        </div>
      </>
    );
  }
}

export default ResetPassword;