import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  Col
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import '../admin/Admin.css';

class Consolidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      domains: [],
      indicators: [],
      filteredComponents: [],
      componentsModal: false,
      type: -1,
      dataWillSaved: {}
    }
  }

  componentDidMount() {
    document.body.classList.add("admin-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    this.getIndicators();
    this.getComponents();
    this.getDomains();
  }

  componentWillUnmount() {
    document.body.classList.remove("admin-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getIndicators = () => {
    axios
      .get('/api/questions')
      .then((res) => {
        if (res.data) {
          this.setState({ indicators: res.data });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving indicators.');
        }
      });
  }

  getComponents = () => {
    axios
      .get('/api/components')
      .then((res) => {
        if (res.data) {
          this.setState({ components: res.data });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving components.');
        }
      });
  }

  getDomains = () => {
    axios
      .get('/api/domains')
      .then((res) => {
        if (res.data) {
          const domains = res.data.sort((a, b) => { return a.index - b.index });
          this.setState({ domains: domains });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving domains.');
        }
      });
  }

  onClickDomain = (domain, data) => {
    const filteredComponents = this.state.components.filter((component) => { return component.domain._id === domain._id });
    this.setState({ filteredComponents: filteredComponents, componentsModal: true, dataWillSaved: data });
  }

  onClickComponent = (component) => {
    const dataWillSaved = this.state.dataWillSaved;
    const componentFound = dataWillSaved.components.find((comp) => comp.index === component.index);
    if (componentFound) {
      toast.error('This component was already assigned to this indicator');
      this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
      return;
    }
    dataWillSaved.components.push(component._id);
    axios
      .put(`/api/questions/${dataWillSaved._id}`, { indicator: dataWillSaved })
      .then((res) => {
        this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
        this.getIndicators();
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while updating observation data.');
        }
      });
  }

  renderDomains = (data) => {
    return this.state.domains.map((domain) => {
      const { index } = domain;
      return (
        <>
          <Button
            onClick={(e) => { e.preventDefault(); this.onClickDomain(domain, data) }}
            className={`domain-mark ${domain.index === 1 ? " color-domain-1" : ""} 
                      ${domain.index === 2 ? " color-domain-2" : ""} 
                      ${domain.index === 3 ? " color-domain-3" : ""} 
                      ${domain.index === 4 ? " color-domain-4" : ""} 
                      ${domain.index === 5 ? " color-domain-5" : ""}`}>
            {index}
          </Button>
        </>
      );
    });
  }

  displayComponent = (indicator) => {
    if (indicator.components == null || indicator.components.length === 0) {
      return '';
    } else {
      let strComponent = '';
      indicator.components.forEach((component, index) => {
        if (indicator.components.length - 1 !== index)
          strComponent += component.index + ', ';
        else
          strComponent += component.index;
      });
      return strComponent;
    }
  }

  removeComponent = (indicator) => {
    indicator.components = [];
    axios
      .put(`/api/questions/${indicator._id}`, { indicator: indicator })
      .then((res) => {
        this.getIndicators();
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while updating observation data.');
        }
      });
  };

  renderIndicators = () => {
    return this.state.indicators.map((indicator, index) => {
      const { title, _id } = indicator;
      return (
        <>
          <tr key={_id}>
            <td className="text-left">
              <span style={{ marginLeft: '8px' }} className="font-crm">
                {index + 1}
              </span>
            </td>
            <td className="text-left">
              <span style={{ marginLeft: '5px' }}>{title}</span>
              {indicator.components.length > 0 &&
                <span className="ml-2 badge badge-component badge-info" onClick={() => { this.removeComponent(indicator) }}>{this.displayComponent(indicator)}</span>
              }
            </td>
            <td className="text-right actions">
              {
                this.renderDomains(indicator)
              }
            </td>
          </tr>
        </>
      );
    });
  }

  renderComponents = () => {
    return this.state.filteredComponents.map((component) => {
      return (
        <>
          <a
            onClick={(e) => { e.preventDefault(); this.onClickComponent(component) }}
            href="#pablo"
            style={{ display: "block", color: '#158574' }}
            rel="noopener noreferrer"
            target="_blank">
            {component.index}.{component.name}
          </a>
        </>
      )
    })
  }

  colorsPerDomains = () => {
    return '#black';
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container>
                <h5 className="text-left padding-lg back-default-color text-white">Tie the Indicators to Components and Domains</h5>
                <Card className='mt-3'>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left" style={{ width: '30px' }}>
                            <div className="order-mark">
                              <span className="text-white">N</span>
                            </div>
                          </th>
                          <th className="text-left" colSpan="2"><h6 className="grey-back-color padding-sm align-items"><span>Visual Indicators</span><span>Domains/Components</span></h6></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.indicators && this.renderIndicators()
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          modalClassName="modal-components"
          isOpen={this.state.componentsModal}
          toggle={() => this.setState({ componentsModal: false })}
        >
          <div className="modal-title">
            {this.state.filteredComponents[0] &&
              <h5 className={`${this.state.filteredComponents[0].domain.index === 1 ? " color-domain-1" : ""} 
                ${this.state.filteredComponents[0].domain.index === 2 ? " color-domain-2" : ""} 
                ${this.state.filteredComponents[0].domain.index === 3 ? " color-domain-3" : ""} 
                ${this.state.filteredComponents[0].domain.index === 4 ? " color-domain-4" : ""} 
                ${this.state.filteredComponents[0].domain.index === 5 ? " color-domain-5" : ""}`}>
                {this.state.filteredComponents[0].domain.name}
              </h5>
            }
          </div>
          <div className="modal-body just">
            <Col>
              {
                this.renderComponents()
              }
            </Col>
          </div>
        </Modal>
      </>
    );
  }
}

Consolidate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Consolidate);