import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Input,
  Container,
  Col,
  Row,
  CardTitle,
  FormGroup
} from "reactstrap";
import Select from "react-select";


import { GApageView, GAexception } from "../../shared/gaUtils";
import axios from "axios";
import FooterWhite from "../shared/FooterWhite";

const categories = [
  { value: 'Problem', label: 'Problem' },
  { value: 'Improvement', label: 'Improvement' },
  { value: 'Other', label: 'Other' }
]

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.auth.user ? this.props.auth.user.email : '',
      name: 'BEYOND CONSEQUENCES INSTITUTE',
      phone: this.props.auth.user ? this.props.auth.user.phone : '',
      message: "",
      errors: {},
      category: { value: 'Problem', label: 'Problem' },
    };
  }

  componentDidMount() {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Contact-US");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSelection = (selectedCategory) => {
    this.setState({ category: selectedCategory });
    console.log(`Option selected:`, this.state.category);
  };

  onSubmit = e => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, 
    // we don't need to pass in this.props.history as a parameter
  };

  validateForm = () => {
    if (_.isEmpty(this.state.name)) {
      toast.error("Please put your name and fill in information required.");
      return false;
    }
    if (_.isEmpty(this.state.email)) {
      toast.error("Please put your email and fill in information required.");
      return false;
    }
    if (_.isEmpty(this.state.message)) {
      toast.error("Please write down your message.");
      return false;
    }
    if (_.isEmpty(this.state.phone)) {
      toast.error("Please put your phone number.");
      return false;
    }
    return true;
  }

  submitMessage = () => {
    console.log(this.state.name);
    if (!this.validateForm())
      return;
    axios.post('/api/users/contact-us', {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
      category: this.state.category.value
    }).then((res) => {
      toast.info("Successfully sent a message!");
      this.props.history.push("/");
    }).catch((err) => {
      if (err.response) {
        console.log(err.response);
        toast.error(err.response.data.message);
      } else if (err.request) {
        toast.error('Please check your netwrok connection.');
      } else {
        toast.error(err.message || 'Error occured while getting observation data.');
      }
      console.log(err);
      GAexception(`Sending Message Failed: ${err}`);
    });
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="page-header header-filter"
          style={{
            backgroundImage: "url(" + require("assets/img/back-landing.jpg") + ")",
          }}>
          <svg className="divider-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern1" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back1.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern1)" points="0,0 100,0 0,100" />
          </svg>
          <svg className="divider-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern2" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back2.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern2)" points="50,100 100,0 100,100" />
          </svg>
          <div className="content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8" lg="5">
                  <Row className="brand justify-content-center">
                    <img src={require("assets/img/logo.png")} alt="" />
                  </Row>
                  <Card className="card-login mt-4">
                    <CardBody>
                      <CardTitle className="text-center" tag="h3">
                        Send us a message
                      </CardTitle>
                      <Row className="mt-3">
                        <Col>
                          <FormGroup>
                            <Input
                              id="name"
                              value={this.state.name}
                              placeholder="Your Name Here..."
                              autoComplete="off"
                              type="text"
                              readonly
                            ></Input>
                            <span className="red-text">
                              {errors.name}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <FormGroup>
                            <Input
                              id="email"
                              onChange={this.onChange}
                              value={this.state.email}
                              placeholder="Email Here..."
                              error={errors.email}
                              autoComplete="off"
                              onBlur={this.validateInput}
                              type="email"
                            ></Input>
                            <span className="red-text">
                              {errors.email}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <FormGroup>
                            <Input
                              id="phone"
                              onChange={this.onChange}
                              value={this.state.phone}
                              placeholder="Number Here..."
                              error={errors.phone}
                              autoComplete="off"
                              onBlur={this.validateInput}
                              type="phone"
                            ></Input>
                            <span className="red-text">
                              {errors.phone}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <FormGroup>
                            <Select
                              className="react-select react-select-info"
                              classNamePrefix="react-select"
                              value={this.state.category}
                              onChange={this.handleSelection}
                              options={categories}
                            ></Select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col>
                          <FormGroup>
                            <Input
                              id="message"
                              onChange={this.onChange}
                              value={this.state.message}
                              placeholder="Your Message Here..."
                              error={errors.message}
                              autoComplete="off"
                              onBlur={this.validateInput}
                              type="textarea"
                              rows="6"
                            ></Input>
                            <span className="red-text">
                              {errors.message}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Col>
                        <Button
                          className="btn-round btn-login"
                          type="submit"
                          size="md"
                          onClick={this.submitMessage}
                        >
                          Submit
                        </Button>
                        <Link className="link-signup" to="/" style={{ marginLeft: '20px' }}>Back</Link>
                      </Col>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
            <FooterWhite />
          </div>
        </div>
      </>
    );
  }
}

ContactUs.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps
)(ContactUs);