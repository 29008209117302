import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// reactstrap components
import {
  Container,
  Row,
  NavItem,
  Nav,
  NavLink,
  Col,
  Modal,
  ModalFooter, 
  Button,
  Label
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import { GApageView } from "../../shared/gaUtils";
import RoomDetail from "./RoomDetail";
import '../classroom/Classroom.css';
import constant from "../../shared/constant";

class Classroom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      obData: null, 
      warningModal: false, 
      remainingCount: 0
    }
    if(this.props.location && this.props.location.state && this.props.location.state.obData)
    {  
      this.state.edit = true;
      this.state.obData = this.props.location.state.obData;
    }
    if(this.props.location && this.props.location.state && this.props.location.state.new)
    {  
      this.state.edit = false;
      this.state.obData = null;
    }
  }

  componentDidMount() {
    document.body.classList.add("classroom-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Classroom");
    if (this.props.auth.user.district) {
      this.getTotalObservationsCount();
    } else {
      if (this.props.auth.user.role === constant['ROLE']['OBSERVER']) {
        this.getObservationList();
      }
    }
  }

  getTotalObservationsCount() {
    axios
      .post('/api/observations/districtObservationCount',
        {
          districtId: this.props.auth.user.district._id
        })
      .then((res) => {
        if (res.status === 200) {
          if (res.data >= this.props.auth.user.district.totalObservations) {
            this.props.history.push('/');
          }
        }
      });
  }

  getObservationList = () => {
    axios
      .post('/api/observations', { userId: this.props.auth.user.id })
      .then((res) => {
        if (res.data && res.data.length >= 1 && this.state.edit === false && localStorage.getItem('isSchoolUser') !== "true") {
          this.props.history.push('/');
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving observation data.');
        }
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("classroom-page");
    document.body.classList.remove("sidebar-collapse");
  }

  saveRoomDetail = (roomDetailData, schoolList) => {
    const observationData = {
      ...roomDetailData,
      observer: this.props.auth.user.id,
    };
    this.saveObservationDataIntoDB(observationData, schoolList);
  }

  handleSaveObservations = (obData) => {
    axios
    .post('/api/observations/create', { obData })
    .then((res) => {
      localStorage.setItem(constant['OBSERVATION_ID'], res.data._id);
      this.props.history.push('/observe');
      if(localStorage.getItem('isSchoolUser') === 'true') {
        axios
          .get('/api/districts')
          .then((res) => {
            let tempDistricts = [...res.data];
            if(tempDistricts.length > 0) {
              tempDistricts.forEach(district => {
                if(district.schools.length > 0) {
                  let flag = 0;
                  district.schools.forEach(school => {
                    if(school.users.length > 0) {
                      school.users.forEach(user => {
                        if(user.email.toLowerCase() === this.props.auth.user.email) {
                          user.count ? user.count++ : user.count = 1;
                          flag = 1;
                        }
                      })
                    }
                  })
                  if(flag === 1) {
                    axios
                      .put(`/api/districts/${district._id}`, { district: district })
                      .then((res) => {
                        if (res.status === 200) {
                          console.log('success');
                        }
                      })
                  }
                }
              })
            }
          })
      }
    }).catch((err) => {
      console.log(err);
      if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.request) {
        toast.error('Please check your netwrok connection.');
      } else {
        toast.error(err.message || 'Error occured while saving observation data.');
      }
    });
  }

  saveObservationDataIntoDB = (obData, schoolList) => {
    if(this.state.edit) {
      axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        localStorage.setItem(constant['OBSERVATION_ID'], res.data._id);
        this.props.history.push('/observe');
      }).catch((err) => {
        console.log(err);
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while saving observation data.');
        }
      });
    } else {
      if (this.props.auth.user.role !== "SuperAdmin") {
        if (obData.district === undefined && obData.school === undefined) {
          this.handleSaveObservations(obData)
        } else {
          let currentSchool = schoolList.filter((item) => item._id === obData.school)[0]
          let currentTotalCounts = 0;
          currentSchool.users.forEach((user) => currentTotalCounts += user.count)
          if (currentSchool.count - currentTotalCounts <= 3) {
            this.setState({ warningModal: true, remainingCount: currentSchool.count - currentTotalCounts, obData: obData })
          } else {
            this.handleSaveObservations(obData)
          }
        }
        
      } else {
        this.handleSaveObservations(obData)
      }
    }
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container>
                <Row>
                  <div className="mr-auto ml-auto col-lg-12">
                    <h3 className="title">Current Classroom</h3>
                    <div className="classroom">
                      <RoomDetail obData={this.state.obData} saveRoomDetail={this.saveRoomDetail}></RoomDetail>
                    </div>
                    <Nav className="row mt-5 features">
                      <div className="col-lg-1"></div>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon01-book.png") + ")",
                          }} />
                          <h4 className="info-title">LEARN</h4>
                          <p>Read Classroom180 and memorize the domains, and components. Under-stand the process of the Classroom180 Rubric. To purchase Classroom180, click here.</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools" tag={Link} to="/observe">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon02-Observe.png") + ")",
                          }} />
                          <h4 className="info-title">OBSERVE</h4>
                          <p>Go into the classroom and carefully observe the classroom and gather the evidence from an objective and unbiased perspective.</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2" >
                        <NavLink className="icon-tools" tag={Link} to="/consolidate">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon03-Consolidate.png") + ")",
                          }} />
                          <h4 className="info-title">CONSOLIDATE</h4>
                          <p>After the observation, consolidate the evidence by assigning your components and domains. Prepare for the debriefing step to come next.</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools" tag={Link} to="/debrief">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon04-Debrief.png") + ")",
                          }} />
                          <h4 className="info-title">DEBRIEF</h4>
                          <p>Meet with the teacher for a collaborative post-observation debriefing. Gather more evidence with the teacher and determine levels of performance</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools" tag={Link} to="/portfolio">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon05-Portfolio.png") + ")",
                          }} />
                          <h4 className="info-title">PORTFOLIO</h4>
                          <p>Find your finalized Classroom180 Rubrics along with the Teacher Reflection and Growth Plans in the Portfolio.</p>
                        </NavLink>
                      </NavItem>
                      <div className="col-lg-1"></div>
                    </Nav>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          modalClassName="modal-confirm"
          isOpen={this.state.warningModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  {
                    this.state.remainingCount === 0 
                    ? "You are out of observations. Please contact your administrator."
                    : `You only have ${this.state.remainingCount} observations remaining period`
                  }
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            {
              <div style={{gap: '20px', display: 'flex'}}>
                <Button
                  className="red-alert-color font-crm mr-2"
                  onClick={() => { this.setState({ 
                    warningModal: false,
                    }) 
                  }}
                >
                  Cancel
                </Button>
                {
                  this.state.remainingCount > 0 && <Button
                    className="default-back-color font-crm mr-2"
                    onClick={() => { this.setState({ 
                      warningModal: false,
                      })
                      this.handleSaveObservations(this.state.obData)
                    }}
                  >
                    Got it!
                  </Button>
                }
                
            </div>
            }
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

Classroom.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Classroom);