import React, { Component, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";

// reactstrap components
import {
  Container,
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import { GApageView } from "../../shared/gaUtils";
import constant from '../../shared/constant';
import '../portfolio/Portfolio.css';
import StarRatings from 'react-star-ratings';
import _, { first } from 'lodash';
import html2pdf from "html2pdf.js";
import Chart from 'react-apexcharts';
import ReactToPrint from 'react-to-print';

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obData: {},
      domains: [],
      components: [],
      rankedRatings: [],
      firstNum: [ 0, 0, 0, 0, 0 ],
      secondNum: [ 4, 7, 5, 3, 8],
      totalRated: 0,
      starCount: [0, 0, 0, 0, 0, 0, 0],
      starVal: [10, 10, 10, 10, 10, 10, 10],
      starColor: ['#493CC2', '#6D63CE', '#928ADA', '#A49DE0', '#B6B1E7', '#C8C4ED', '#DBD8F3'],
      options: {
        plotOptions: {
          radialBar: {
            track: {
              background: '#FFFFFF20',
            },
            hollow: {
              margin: 5,
              size: "40%"
            },
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                show: false,
                color: "#888",
                fontSize: "8px"
              },
              value: {
                color: "#FFF",
                fontSize: "15px",
                offsetY: 6,
                show: true
              }
            }
          }
        },
        colors: [
          '#FFF'
        ],
        stroke: {
          lineCap: "round",
        },
        labels: ["Percentage"]
      },
      series: [49]
    };
  }

  componentDidMount() {
    document.body.classList.add("portfolio-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Portfolio");
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    if (observationId !== null) {
      this.loadCurrentObserveData(observationId);
    } else {
      toast.info("Please select a classroom to observe or start new");
      // this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("portfolio-page");
    document.body.classList.remove("sidebar-collapse");
  }

  loadCurrentObserveData = (observationId) => {
    const requests = [];
    requests.push(axios.get(`/api/observations/${observationId}`));
    requests.push(axios.get('/api/domains'));
    requests.push(axios.get('/api/components'));
    axios.all(requests).then(axios.spread((...responses) => {
      const obData = responses[0].data;
      const domains = responses[1].data;
      const components = responses[2].data;
      let componentsWithRatings = obData.ratings.map((rating) => {
        return {
          component: rating.component,
          rating: this.getRatingFromIndicators(rating)
        }
      });
      const rankedRatings = componentsWithRatings.sort((a, b) => { return b.rating - a.rating });
      this.setState({ obData: obData, domains: domains, components: components, rankedRatings: rankedRatings });
      this.state.rankedRatings.map((rating,cIndex) => {
        const component = _.find(this.state.components, { _id: rating.component });
        if(rating.rating !== -1 ){
          if ( constant['OBSERVATION_TYPE']['RELATIONSHIP'] === component.domain.index) {
            const tempNum = this.state.firstNum.slice() 
            tempNum[0] = this.state.firstNum[0] + 1
            this.setState({firstNum: tempNum})
          } else if ( constant['OBSERVATION_TYPE']['REGULATION'] === component.domain.index) {
            const tempNum = this.state.firstNum.slice() 
            tempNum[1] = this.state.firstNum[1] + 1
            this.setState({firstNum: tempNum})
          } else if ( constant['OBSERVATION_TYPE']['SAFETY'] === component.domain.index) {
            const tempNum = this.state.firstNum.slice() 
            tempNum[3] = this.state.firstNum[3] + 1
            this.setState({firstNum: tempNum})
          } else if ( constant['OBSERVATION_TYPE']['DISCIPLINE'] === component.domain.index) {
            const tempNum = this.state.firstNum.slice()
            tempNum[4] = this.state.firstNum[4] + 1
            this.setState({firstNum: tempNum})
          } else {
            const tempNum = this.state.firstNum.slice()
            tempNum[2] = this.state.firstNum[2] + 1
            this.setState({firstNum: tempNum})
          }
          const tempTotal = this.state.totalRated + 1
          this.setState({totalRated : tempTotal})
          const tempCount = this.state.starCount.slice()
          tempCount[6-rating.rating * 2] = this.state.starCount[6-rating.rating * 2] + 1
          this.setState({starCount: tempCount})
        }
      });
      
      const tempRated = this.state.starVal.slice();
      tempRated.map((temp, index) => {
        tempRated[index] = Math.trunc(this.state.starCount[index] / this.state.totalRated * 100);
      });
      this.setState({starVal : tempRated});
    })).catch((err) => {
      console.log(err);
      if (err.response) {
        toast.error(err.response.data.message);
      } else if (err.request) {
        toast.error('Please check your netwrok connection.');
      } else {
        toast.error(err.message || 'Error occured while getting observation data.');
      }
      // this.props.history.goBack();
    });
  }

  printPdf = () => {
    const element = document.getElementById('portfolio-report');
    let clonedElement = element.cloneNode(true);
    // let hideList = clonedElement.getElementsByClassName('hide-printing');
    // for (let i = hideList.length - 1; i >= 0; i--) {
    //   let childNode = hideList[i];
    //   childNode.parentNode.removeChild(childNode);
    // }
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: 'portfolio.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true
      },
      jsPDF: { orientation: 'landscape', unit: 'pt', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  setRatings = (value) => {
    switch (value) {
      case 6:
        return -1;
      case 5:
        return 0;
      case 4:
        return 1;
      case 3:
        return 1.5;
      case 2:
        return 2;
      case 1:
        return 2.5;
      case 0:
        return 3;
      case -1:
        return -1;
      default:
        return '';
    }
  }

  getRatingFromIndicators(rating) {
    if (rating)
      return this.setRatings(rating.rating);
    return this.setRatings(6);
  }

  getComponentColor = (component) => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === component.domain.index) {
      return 'color-domain-1';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === component.domain.index) {
      return 'color-domain-2';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === component.domain.index) {
      return 'color-domain-4';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === component.domain.index) {
      return 'color-domain-5';
    } else {
      return 'color-domain-3';
    }
  }

  getBackColorByRating = (rating) => {
    if (rating >= 2.5) {
      return 'color-rating-1';
    } else if (rating === 2) {
      return 'color-rating-2';
    } else if (rating === 1.5) {
      return 'color-rating-3';
    } else if (rating === 1) {
      return 'color-rating-4';
    } else if (rating === 0) {
      return 'color-rating-5';
    }
    return 'color-back-white';
  }
  getComponentText = (index) => {
    if(index === 0)
      return "Relationships and Family Culture";
    if(index === 1)
      return "Regulation";
    if(index === 2)
      return "Language of Trauma";
    if(index === 3)
      return "Safety";
    if(index === 4)
      return "Discipline and Empowerment";   
  }

  getBackColor = (index) => {
    return 'color-domain-' + (index + 1);
  }

  getPercent = (index) => {
    const value = Math.trunc(this.state.firstNum[index] / this.state.secondNum[index] * 100);
    const arr = [value];
    return(
        <Chart
          options={this.state.options}
          series={arr}
          type="radialBar"
          width="120"
        />
    );
  }

  getResult = (index) => {
    return this.state.firstNum[index] + "/" + this.state.secondNum[index];
  }

  getStarBackColor = (index) => {
    return 'star-color-' + (index + 1);
  }

  renderStarRadial = () =>{
    var options = {
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        pie: {
          expandOnClick: false,
          innerSize: '50%',
          allowPointSelect: true,
          cursor: 'pointer',
          size: 100,
          showInLegend: true,
          dataLabels: {
              enabled: false
          }
        }
      }
    }],
    legend:{
      show: false,
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        if( (3 - seriesIndex / 2) == 1 || (3 - seriesIndex / 2) == 0)
        {
          return (
            '<div style="padding: 8px;">' +
            "<span>" + (3 - seriesIndex / 2) +
            " Star" +
            "</span>" +
            "</div>"
          );
        }
        else{
        return (
          '<div style="padding: 8px;">' +
          "<span>" + (3 - seriesIndex / 2) +
          " Stars" +
          "</span>" +
          "</div>"
        );}
      }
    },
    fill:{
      colors: this.state.starColor
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return (Math.trunc(val) + "%");
      }
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: this.state.starColor,
      width: 8,
      dashArray: 0,   
    }
    };
    return(
      <div id="chart">
        <Chart options={options} series={this.state.starVal} type="donut" width="250px" height="250px"/>
      </div>
    );
  }
  renderStarList = () =>{
    var arr = Array.apply(null, Array(7))
    return arr.map((val, index) =>{
      return(
        <Row className="mx-2 my-3">
          <Col xs={8} >
            <StarRatings
              rating={3-index*0.5}
              starDimension="25px"
              starSpacing="5px"
              starRatedColor="#493cc2"
              numberOfStars={3}
            />
          </Col>
          <Col xs={4} className={`color-white ${this.getStarBackColor(index)}`} style={{borderRadius: "10px",  display: 'flex', alignItems: 'center', justifyContent: 'center', transform: `translateY(6%)`}}>
            {
              this.state.starVal[index].toString() === "NaN" ? "" : this.state.starVal[index] + "%"
            }
          </Col>
        </Row>
      );
    })
  }

  renderTables = () =>{
    var arr = Array.apply(null, Array(5))
    return arr.map((val, index) =>{
      return(
        <Col className={`m-1 color-white ${this.getBackColor(index)}`} style={{borderRadius: "13px"}}>
          <Row className="m-1 mt-3 text-left" style={{fontSize: 12, height: "45px"}}>
            {
              this.getComponentText(index)
            }
          </Row>
          <Row>
            <Col md={7} className="mb-3 text-right font-weight-bold" style={{fontSize: 25}}>
            {
              this.getResult(index)
            }
            </Col>
            <Col md={5} style={{marginLeft: -45, marginTop:-13}}>
            {
              this.getPercent(index)
            }
            </Col>
          </Row>
        </Col>
      );
    })
  }

  renderComponents = () => {
    let tempIndex = this.state.rankedRatings.findIndex(item => item.rating === -1)
    return this.state.rankedRatings.map((rating, cIndex) => {
      const component = _.find(this.state.components, { _id: rating.component });
      const { _id, name, index } = component;
      return (
        <>
          <tr key={_id} className="identity"  style={{borderBottom: cIndex === tempIndex-1 && "10px solid rgb(224 234 241)"}} >
            <td className="text-center" style={{padding: "5px 5px"}}>
              <div className={`ml-2 circle-badge ${this.getComponentColor(component)}`}>{index}</div>
            </td>
            <td className="text-left" style={{padding: "5px 5px"}}><span>{name}</span></td>
            <td className="text-center" style={{padding: "5px 5px"}}><span>{rating.rating !== -1 ? rating.rating : 'N/A'}</span></td>
            <td className="text-left" style={{padding: "5px 5px"}}>
              {rating !== -1 ?
                <StarRatings
                  rating={rating.rating}
                  starDimension="15px"
                  // starSpacing="5px"
                  starRatedColor="#493cc2"
                  numberOfStars={3}
                />
                :
                <span className="color-alert font-crm-medium"><i style={{ paddingTop: '8px' }} className="now-ui-icons ui-1_simple-remove"></i></span>
              }
            </td>
            <td className="text-center" style={{padding: "5px 5px"}}><span>{constant['COMPONENT_PAGES'][index - 1]}</span></td>
          </tr>
        </>
      );
    });
  }

  renderCompetencyRanks() {
    return (
      <Row style={{marginTop : "50px"}}>
          <Col xs={5} md={4}>
            <Row className="mt-3">
              <div className="mr-auto ml-auto col-md-12">
                <Card>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-center"><span>Rating Percentage</span></th>
                        </tr>
                        <tr>
                          {
                            this.renderStarRadial()
                          } 
                        </tr>
                      </thead>
                    </Table>
                    <div className="mb-5">
                      {
                        this.renderStarList()
                      }
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Col>
          <Col xs={13} md={8}>
            <Row className="mt-3">
              <div className="mr-auto ml-auto col-md-12">
                  <>
                  <Card style={{marginBottom: "0px"}}>
                    <CardBody>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            <th className="text-left"><span>Components Rated Per Each Domain</span></th>
                          </tr>
                        </thead>
                      </Table>
                      <Row>
                        {
                          this.renderTables()
                        }
                      </Row>              
                    </CardBody>
                  </Card>
                  <Card className='mt-3'>
                    <CardBody style={{padding: "5px 15px"}}>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            <th className="text-left"><span>Component</span></th>
                            <th className="text-center"> <span>Rating</span></th>
                            <th className="text-center"></th>
                            <th className="text-center">
                              <span>Further Study</span>
                              <a rel="noopener noreferrer" href="https://store.beyondconsequences.com/classroom180-a-framework-for-creating-sustaining-and-assessing-the-trauma-informed-classroom/" style={{ marginLeft: 10 }} target="_blank"><img id="buy-icon" style={{ height: 40 }} src={require("assets/img/book.png")} alt="print" /></a>
                              <UncontrolledTooltip target="buy-icon">
                                Click here to buy Classroom180
                              </UncontrolledTooltip>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.renderComponents()
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                  </>
              </div>
            </Row>
          </Col>
        </Row>
    );
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div id="portfolio" className="wrapper"  ref={el => (this.componentRef = el)}>
          <Container className="session-bar">
            <Row className="padding-lg default-back-color text-white title-container">
              <span style={{ marginRight: '30px' }}>Teacher: {this.state.obData.teacher}</span>
              <span style={{ marginRight: '30px' }}>Grade: {this.state.obData.grade}</span>
              <span style={{ marginRight: '30px' }}>Lesson: {this.state.obData.topic}</span>
              <span style={{ marginRight: '30px' }}>Created: {moment(this.state.obData.date).format('MM/DD/YYYY h:mm a')}</span>
              <span style={{ marginRight: '20px' }}>Last Modified: {moment(this.state.obData.dateModified).format('MM/DD/YYYY h:mm a')}</span>
              <ReactToPrint
                trigger={() => {
                  return <Button style={{ marginRight: '20px', width: '50px' }} className="default-back-color btn-print hide-printing" type="button">
                            <img src={require("assets/img/Icon-Print.png")} alt="print" />
                          </Button>;
                }}
                content={() => this.componentRef}
              />
            </Row>
          </Container>
          <div className="home">
            <div className="panel">
              <Container className="board">
                {
                  this.renderCompetencyRanks()
                }
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

Portfolio.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Portfolio);