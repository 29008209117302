import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import constant from "../../../shared/constant";
import { Button } from "reactstrap";
import VisualIndicators from "./VisualIndicators";

class ReflectiveQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: constant['OBSERVATION_TYPE']['RELATIONSHIP'],
      obData: {}
    };
  }

  onClickNext = (type, data) => {
    const { currentStep } = this.state;
    if (this.state.currentStep === constant['OBSERVATION_TYPE']['DISCIPLINE']) {
      this.setState({
        currentStep: 0,
      });
    } else {
      this.setState({
        currentStep: currentStep + (currentStep === constant['OBSERVATION_TYPE']['REGULATION'] ? 2 : 1)
      });
    }
  }

  onClickStep = (e, step) => {
    e.preventDefault();
    if (this.state.currentStep !== step) {
      this.setState({ currentStep: step});
    }
  }
  
  updateIndicators = (indicators) => {
  }

  removeNote = (indicators, indIndex, noteIndex) => {
  }

  render() {
    const { currentStep } = this.state;
    return (
      <>
        <div className="col-lg-12 p-0">
            <VisualIndicators 
              observer={this.props.obData.observer} 
              indicators={this.props.obData.questions} 
              printPreview={this.props.printPreview}
            />
        </div>
      </>
    );
  }
}

ReflectiveQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(ReflectiveQuestion)
