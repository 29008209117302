import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  Modal,
  ModalFooter
} from "reactstrap";

// core components
// import DropdownScrollNavbar from "../../shared/DropdownScrollNavbar";
import DropdownScrollNavbar from "../shared/DropdownScrollNavbar";
import constant from "../../shared/constant";

class AdminResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchFocus: false,
      keyword: '',
      users: [],
      totalUsers: [],
      newPassword: '',
      editId: '',
      pwdStatus: false
    }
  }

  componentDidMount() {
    document.body.classList.add("admin-users-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    this.getUserList();
  }

  componentWillUnmount() {
    document.body.classList.remove("admin-users-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getUserList = () => {
    axios.post('/api/users', { userId: this.props.auth.user.id })
      .then((res) => {
        const users = res.data.sort((a, b) => {
          return new Date(b.dateLastVisit) - new Date(a.dateLastVisit);
        })
        this.setState({ 
          users: users, 
          keyword: '',
          totalUsers: users
        });
      }).catch((err) => {
        console.log(err);
      });
  }

  onChangeKeyword = (e) => {
    let tempUsers = [];
    if (e.target.value.length > 0) {
      this.state.totalUsers.forEach(user => {
        let name = user.firstName + ' ' + user.lastName;
        if (name.toLowerCase().includes(e.target.value.toLowerCase())) {
          tempUsers.push(user);
          return
        }
        if(user.email && user.email.toLowerCase().includes(e.target.value.toLowerCase())) {
          tempUsers.push(user);
          return
        }
      })
    } else {
      tempUsers = [...this.state.totalUsers]
    }

    this.setState({
      keyword: e.target.value,
      users: tempUsers
    })
  }

  onChangeNewPassword = (e, user) => {
    this.setState({pwdStatus: false})
    this.setState({editId: user._id})
    this.setState({ newPassword: e.target.value })
  }

  handleReset = (user) => {
    console.log("first", this.state.newPassword)
    axios.post('/api/users/admin-reset-password', {email: user.email, newPassword: this.state.newPassword})
    .then((res) => {
      this.setState({
        pwdStatus: true,
        newPassword: res.data.newPassword
      })
    })
  }

  renderUserListTableData = () => {
    return this.state.users.map((user) => {
      return (
        <tr key={user._id}>
          <td className="text-left">{user.firstName ? user.firstName + ' ' + user.lastName : 'Unregistered'}</td>
          <td className="text-left">{user.email}</td>
          <td className="text-left">
            {
              this.state.pwdStatus && this.state.editId === user._id ? this.state.newPassword :
              <Input 
              onChange={(e) => this.onChangeNewPassword(e, user)} 
              value={this.state.editId === user._id ? this.state.newPassword : ''} />
            }
          </td>
          <td className="text-center">
            <Button 
              className="color-white default-back-color border-0 p-2" 
              style={{fontSize: '15px'}}
              onClick={() => this.handleReset(user)}>
              <i class="now-ui-icons gestures_tap-01"></i>  Reset
            </Button>
          </td>
        </tr>
      )
    })
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar />
        <div className="home">
          <div className="mr-auto ml-auto col-md-9" style={{textAlign: 'center'}}>
            <h3 className="title">Reset Password</h3>
          </div>
          <div className="mr-auto ml-auto col-lg-10" style={{ textAlign: 'right' }}>
            <Row style={{justifyContent: "space-between"}}>
              <Col lg='6'>
                <InputGroup className={this.state.searchFocus ? "input-group-focus" : ""}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_zoom-bold" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.onChangeKeyword}
                    value={this.state.keyword}
                    placeholder="Enter the keyword..."
                    id="keyword"
                    type="text"
                    autoComplete="on"
                    onFocus={() => this.setState({searchFocus: true})}
                    onBlur={() => this.setState({ searchFocus: false })}
                  />
                  
                </InputGroup>
              </Col>
              <Button 
                className="color-white default-back-color border-0" 
                style={{fontSize: '15px', margin: '0px', height: '40px'}}>
                  <i class="now-ui-icons ui-1_zoom-bold"></i>  Search
              </Button>
            </Row>
            <Card className="card-plain">
              <CardBody>
                {this.state.users.length > 0 ?
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">New Password</th>
                        <th className="text-left" width='120px'>Reset</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.renderUserListTableData()
                      }
                    </tbody>
                  </Table>
                  :
                  <h4 style={{ textAlign: 'center' }}>No User Found</h4>
                }
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

AdminResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(AdminResetPassword);