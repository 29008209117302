import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReflectiveQuestion from "./reflective_question/ReflectiveQuestion";
import moment from "moment";
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import ComponentToPrint from "./ComponentToPrint";
import ReactToPrint from "react-to-print";

// reactstrap components
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Label,
  Input,
  FormGroup
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import { GApageView } from "../../shared/gaUtils";
import constant from '../../shared/constant';
import '../debrief/Debrief.css';
import '../debrief/Slider.css';
import html2pdf from "html2pdf.js";
import IndicatorModal from "./IndicatorModal";
import EmailModal from "./EmailModal";
import RangeSlider from 'react-bootstrap-range-slider';
import ModalImage from "react-modal-image";

class Debrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obData: {},
      components: [],
      domains: [],
      type: -1,
      componentWillUpdated: {},
      selectionMode: true,
      rating: 0,
      indicatorModal: false,
      standardIndicators: [],
      emailModal: false,
      printPreview: false,
      invitedTeacher: [],
      totalUsers: []
    }
  }

  componentDidMount() {
    document.body.classList.add("debrief-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Debrief");
    if (this.props.auth.user.role === constant['ROLE']['TEACHER'] && this.props.match.params.observationId !== undefined) {
      localStorage.setItem(constant['OBSERVATION_ID'], this.props.match.params.observationId);
    }
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    if (observationId !== null) {
      this.getUserList();
      // this.loadCurrentObserveData(observationId);
      this.getDomains();
    } else {
      toast.info("Please select a classroom to observe or start new");
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("debrief-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getUserList() {
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    axios
      .post('/api/users', { userId: this.props.auth.user.id })
      .then((res) => {
        const users = res.data.sort((a, b) => {
          return new Date(b.dateLastVisit) - new Date(a.dateLastVisit);
        })

        axios
        .get(`/api/observations/${observationId}`)
        .then((res) => {
          let tempTeachers = [];
          res.data.teachers.forEach(teacher => {
            let tempTeacher = {email: teacher.email, date: teacher.invitedDate};
            tempTeachers.push(tempTeacher);
          })
          this.setState({invitedTeacher: tempTeachers});
          this.categorizeIndicators(res.data);
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while getting observation data.');
          }
          // this.props.history.goBack();
        });

        this.setState({ 
          totalUsers: users
        });
        
      }).catch((err) => {
        console.log(err);
      });
  }

  loadCurrentObserveData = (observationId) => {
    axios
      .get(`/api/observations/${observationId}`)
      .then((res) => {
        let tempTeachers = [];
        res.data.teachers.forEach(teacher => {
          if (this.state.totalUsers.filter(user => user.email === teacher)[0]) {
            let temp = this.state.totalUsers.filter(user => user.email === teacher)[0].date;
            let tempTeacher = {email: teacher, date: temp};
            tempTeachers.push(tempTeacher);
          }
        })
        this.setState({invitedTeacher: tempTeachers});
        this.categorizeIndicators(res.data);
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while getting observation data.');
        }
        // this.props.history.goBack();
      });
  }

  categorizeIndicators(obData) {
    if (obData.indicators.length !== 0) {
      let filteredIndicatorsAndActions = [];
      obData.indicators.forEach((indicator) => {
        if ((indicator.checked === true && indicator.type !== constant['OBSERVATION_TYPE']['CUSTOM'])
          || (indicator.type === constant['OBSERVATION_TYPE']['CUSTOM'] && indicator.components.length > 0)) {
          indicator.components.forEach((component) => {
            filteredIndicatorsAndActions.push({
              id: indicator._id,
              title: indicator.title,
              notes: indicator.notes,
              type: indicator.type,
              componentId: typeof(component) === "string" ? component : component._id,
              domainId: component.domain,

            });
          });
        }
      });
      obData.actions.forEach((action) => {
        if (action.components.length > 0) {
          action.components.forEach((component) => {
            filteredIndicatorsAndActions.push({
              id: action._id,
              title: action.teacher,
              note: action.student,
              type: action.type,
              componentId: typeof(component) === "string" ? component:  component._id,
              domainId: component.domain,
            });
          });
        }
      });
      this.setState({ obData: obData, filteredIndicators: filteredIndicatorsAndActions }, () => {
        this.getComponents();
      });
    } else {
      // toast.info("Please select at least an indicator to debrief");
      this.props.history.push('/observe');
    }
  }

  getComponents = () => {
    axios
      .get('/api/components')
      .then((res) => {
        if (res.data && res.data.length === 27) {
          let obData = this.state.obData;
          if (obData.ratings && obData.ratings.length !== 27) {
            obData.ratings = res.data.map((component) => {
              return {
                rating: -1,
                component: component._id
              }
            });
            this.setState({ components: res.data, obData: obData });
          } else {
            this.setState({ components: res.data });
          }
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving components.');
        }
      });
  }

  getDomains = () => {
    axios
      .get('/api/domains')
      .then((res) => {
        if (res.data) {
          const domains = res.data.sort((a, b) => { return a.index - b.index });
          this.setState({ domains: domains });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving domains.');
        }
      });
  }

  printPdf = () => {
    const element = document.getElementById('debrief-report');
    let clonedElement = element.cloneNode(true);
    let hideList = clonedElement.getElementsByClassName('hide-printing');
    for (let i = hideList.length - 1; i >= 0; i--) {
      let childNode = hideList[i];
      childNode.parentNode.removeChild(childNode);
    }
    const opt = {
      margin: [0.5, 0, 0.5, 0.5],
      filename: 'debrief.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true
      },
      jsPDF: { orientation: 'landscape', unit: 'cm', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  getPdf = () => {
    this.setState({ printPreview: true });
  }

  renderIndicator = (indicator) => {
    return (
      <>
        <div className="indicator-container">
          <div className="indicator-label">
            <label style={{ marginLeft: '5px', display: 'block' }} className="font-crm">{indicator.type === 7 ? 'Teacher: ' : ''}<span className={indicator.type === 7 ? "font-italic" : ""}>{indicator.title}</span></label>
            {
              this.renderNotes(indicator)
            }
          </div>
        </div>
      </>
    );
  }

  saveRating = () => {
    const index = _.findIndex(this.state.obData.ratings, { 'component': this.state.componentWillUpdated._id });
    let obData = this.state.obData;
    const rating = {
      rating: Number(this.state.rating),
      component: this.state.componentWillUpdated._id
    }
    obData.ratings.splice(index, 1, rating);
    this.updateObData(obData);
  }

  onCompletedObservation = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirm-dialog'>
            <h1>Are you sure?</h1>
            <p>Are you and the teacher in agreement with the assessment?  This can not be undone!</p>
            <button className="btn default-back-color font-crm" onClick={onClose}>Go Back</button>
            <button
              className="btn red-alert-color ml-2 font-crm"
              onClick={() => {
                onClose();
                let obData = this.state.obData;
                obData.status = constant['OBSERVATION_STATUS']['COMPLETED'];
                this.updateObData(obData);
              }}
            >
              Complete
            </button>
          </div>
        );
      }
    });
  }

  updateObData(obData) {
    axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        this.setState({ obData: res.data, rating: -1 }, () => {
          const indicators = this.state.obData.indicators.filter((indicator) => {
            if(typeof indicator.components[0] != "undefined")
            {
              return indicator.type === constant['OBSERVATION_TYPE']['STANDARD'] && indicator.components[0]._id === this.state.componentWillUpdated._id;
            }
          });
          this.setState({ standardIndicators: indicators });
        });
        this.categorizeIndicators(res.data);
      }).catch((err) => {
        this.setState({ rating: -1 });
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while updating observation data.');
        }
      });
  }

  onSlideRatings = (rating, component) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    if (this.state.rating !== rating) {
      this.setState({ componentWillUpdated: component, rating: rating }, () => {
        this.saveRating();
      });
    }
  }

  getRatingColor = (domain) => {
    switch (domain.index) {
      case 1:
        return 'primary';
      case 2:
        return 'secondary';
      case 3:
        return 'success';
      case 4:
        return 'danger';
      case 5:
        return 'warning';
      default:
        return ''
    }
  }

  showMessageToAddIndicator = () => {
    toast.info('Please add at least an indicator to give ratings.');
  }

  setRatings = (value) => {
    switch (value) {
      case 6:
        return 'N/A';
      case 5:
        return 0;
      case 4:
        return 1;
      case 3:
        return 1.5;
      case 2:
        return 2;
      case 1:
        return 2.5;
      case 0:
        return 3;
      default:
        return '';
    }
  }

  renderRatings = (component, domain) => {
    let sRating = _.find(this.state.obData.ratings, { component: component._id });
    const rating = (sRating === undefined || sRating === null || sRating.rating === -1) ? 6 : sRating.rating;
    return (
      <>
        <RangeSlider
          value={rating}
          onChange={(e) => { this.onSlideRatings(e.target.value, component) }}
          min={0} max={6} step={1}
          variant={`${this.getRatingColor(domain)}`}
          tooltipLabel={(value) => `${this.setRatings(value)}`}
          tooltip="on"
          className="hide-printing"
          disabled={this.disableCheckForCompletedObservation()}
        />
      </>
    );
  }

  renderIndicators = (domain, component) => {
    const indicators = _.filter(this.state.filteredIndicators, { 'componentId': component._id});
    if (indicators.length > 0) {
      return indicators.map((indicator, index) => {
        return (
          <>
            <tr key={indicator._id}>
              <td></td>
              <td className="text-left">
                {
                  this.renderIndicator(indicator)
                }
              </td>
            </tr>
            {(indicators.length === 1) &&
              <>
                <tr>
                  <td colSpan="3"></td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                </tr>
              </>
            }
            {(indicators.length === 2 && (index === indicators.length - 1)) &&
              <>
                <tr>
                  <td colSpan="3"></td>
                </tr>
                <tr>
                  <td colSpan="3"></td>
                </tr>
              </>
            }
          </>
        );
      });
    } else {
      return (
        <>
          <tr>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td colSpan="3"></td>
          </tr>
        </>
      );
    }
  }

  disableUserAccess = () => {
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ||
      this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      return true;
    } else if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      if (this.state.obData && this.state.obData.observer && (this.props.auth.user.id !== this.state.obData.observer._id)) {
        return true;
      }
    }
    return false;
  }

  disableCheckForCompletedObservation = () => {
    if (this.disableUserAccess())
      return true;
    if (this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED']) return true;
  }

  loadStandIndicatorsByComponent(component) {
    if(this.disableCheckForCompletedObservation()) return;
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    const indicators = this.state.obData.indicators.filter((indicator) => {
    if(typeof indicator.components[0] != "undefined")
    {
      return indicator.type === constant['OBSERVATION_TYPE']['STANDARD'] && (indicator.components[0]._id ? indicator.components[0]._id === component._id : indicator.components[0] === component._id);
    }
    });
    this.setState({ componentWillUpdated: component, indicatorModal: true, standardIndicators: indicators });
  }

  closeIndicatorModal() {
    this.setState({ componentWillUpdated: {}, indicatorModal: false });
  }

  renderNotes = (inputIndicator) => {
    const vIndicators = this.state.obData.indicators.filter((indicator) => {
      return indicator.checked === true
    });
    // return vIndicators.map((indicator, index) => {
    //   if (indicator._id == inputIndicator.id && indicator.notes && indicator.notes.length > 0) {
    //     return indicator.notes.map((note, nIndex) => {
          return (
            <>
              {inputIndicator.notes && inputIndicator.notes.length > 0 ? 
                inputIndicator.notes.map(note =>(
                  <div style={{marginLeft: '5px'}}>
                  <Label style={{ display: 'block', marginTop: '5px', marginBottom: '5px' }} className="font-crm" id="edit-note">
                    <>
                      {inputIndicator.type === 7 ? 'Student: ' : ''}<span className="font-italic">{note.text}</span>
                    </>
                  </Label>
                  {note.image &&
                    <div style={{maxWidth : '50px'}}>
                      <ModalImage
                        small={note.image}
                        large={note.image}
                        alt="image"
                      />
                    </div>
                  }
                  </div>
                ))
              : 
              <Label style={{ display: 'block', marginTop: '5px', marginLeft: '5px' }} className="font-crm" id="edit-note">
                <>
                  {inputIndicator.type === 7 ? 'Student: ' : ''}<span className="font-italic">{inputIndicator.note}</span>
                </>
              </Label>}
            </>
          );
      //   });
      // }
    // });
  }

  renderIndicatorsByComponents(domain) {
    const componentsByDomain = this.state.components.filter((component) => {
      return component.domain._id === domain._id;
    });
    return componentsByDomain.map((component) => {
      const ratingDesc = constant['RATINGS'].find((item) => { return item.index === component.index });
      return (
        <Table responsive>
          <thead>
            <tr>
              <th className="text-left" style={{ width: '30px' }}>
                <div className={`order-mark 
                    ${domain.index === 1 ? " color-domain-1" : ""} 
                    ${domain.index === 2 ? " color-domain-2" : ""} 
                    ${domain.index === 3 ? " color-domain-3" : ""} 
                    ${domain.index === 4 ? " color-domain-4" : ""} 
                    ${domain.index === 5 ? " color-domain-5" : ""}`}>
                  <span className="text-white">{component.index}</span>
                </div>
              </th>
              <th className="text-left" colSpan="2">
                <h6 className="grey-back-color" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                  <span className="component-title" onClick={() => this.loadStandIndicatorsByComponent(component)}>{component.name}<i className="now-ui-icons ui-1_simple-add" style={{ verticalAlign: 'top', marginLeft: 10 }}></i></span>
                  <Col className="rating-container" style={{ marginRight: '50px' }}>
                    {(this.state.obData.ratings !== undefined || this.state.obData.ratings !== null) &&

                      this.renderRatings(component, domain)
                    }
                    <div className="rating-desc">
                      <span className="rating3">{ratingDesc.texts[0]}</span>
                      <span className="rating2">{ratingDesc.texts[1]}</span>
                      <span className="rating1">{ratingDesc.texts[2]}</span>
                      <span className="rating0">{ratingDesc.texts[3]}</span>
                      <span className="ratingN">{ratingDesc.texts[4]}</span>
                    </div>
                    <div className="rating-ticks">
                      <span className="step">3</span>
                      <span className="step">2.5</span>
                      <span className="step">2</span>
                      <span className="step">1.5</span>
                      <span className="step">1</span>
                      <span className="step">0</span>
                      <span className="step rectangle">N/A</span>
                    </div>
                  </Col>
                </h6>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              this.renderIndicators(domain, component)
            }
          </tbody>
        </Table>
      )
    });
  }

  renderIndicatorsByDomains() {
    return this.state.domains.map((domain) => {
      return (
        !this.state.printPreview ?
          <Card className='mt-3'>
            <CardBody>
              <Row style={{ alignItems: 'center', marginBottom: '15px', marginLeft: '0', width: '100%' }}>
                <img className="logo-image mr-2" src={require("assets/img/logo-without-circle.png")} alt="" />
                <div className={`domain-title text-white font-crm-big ${domain.index === 1 ? " color-domain-1" : ""} 
                      ${domain.index === 2 ? " color-domain-2" : ""} 
                      ${domain.index === 3 ? " color-domain-3" : ""} 
                      ${domain.index === 4 ? " color-domain-4" : ""} 
                      ${domain.index === 5 ? " color-domain-5" : ""}`}>
                  <span style={{ float: 'left' }}>Domain {domain.index}: {domain.name}</span>
                </div>
              </Row>
              {
                this.renderIndicatorsByComponents(domain)
              }
            </CardBody>
          </Card>
          :
          <>
            <div>
              <Row style={{ alignItems: 'center', marginBottom: '15px', marginLeft: '0', width: '100%' }}>
                <img className="logo-image mr-2" src={require("assets/img/logo-without-circle.png")} alt="" />
                <div className={`domain-title text-white font-crm-big ${domain.index === 1 ? " color-domain-1" : ""} 
                      ${domain.index === 2 ? " color-domain-2" : ""} 
                      ${domain.index === 3 ? " color-domain-3" : ""} 
                      ${domain.index === 4 ? " color-domain-4" : ""} 
                      ${domain.index === 5 ? " color-domain-5" : ""}`}>
                  <span style={{ float: 'left' }}>Domain {domain.index}: {domain.name}</span>
                </div>
              </Row>
              {
                this.renderIndicatorsByComponents(domain)
              }
            </div>
          </>
      )
    });
  }

  sendEmail(email) {
    
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    // this.setState({ emailModal: false });
    axios
      .post('/api/users/inviteTeacher', {
        data: {
          email: email,
          observationId: observationId
        }
      })
      .then((res) => {
        toast.info(res.data && res.data.message);
        // Load updated observation data
        const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
        this.getUserList();
        this.loadCurrentObserveData(observationId);
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving observation data.');
        }
      });
  }


  render() {
    const pageStyle = `
      @page {
        margin: 2cm 2cm 1cm 2cm;
      }
      @media print {
        body {
          margin: 0;
          padding: 0;
        }
      }
      table {
        page-break-inside: avoid;
      }
    `;
    return (
      <>
        {this.state.printPreview === false ?
          <>
            <DropdownScrollNavbar location={this.props.location} observer={this.state.obData && this.state.obData.observer && this.state.obData.observer._id} />
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div className="wrapper">
              <div className="home">
                <div className="panel">
                  <Container className="session-bar">
                    <Row className="padding-lg default-back-color text-white title-container">
                      <span style={{ marginRight: '10px' }}>Teacher: {this.state.obData.teacher}</span>
                      {/* <span style={{ marginRight: '30px' }}>{this.state.obData.teachers && this.state.obData.teachers[0].email}</span> */}
                      <span style={{ marginRight: '30px' }}>Grade: {this.state.obData.grade}</span>
                      <span style={{ marginRight: '30px' }}>Lesson: {this.state.obData.topic}</span>
                      <span style={{ marginRight: '30px' }}>Created: {moment(this.state.obData.date).format('MM/DD/YYYY h:mm a')}</span>
                      <span style={{ marginRight: '20px' }}>Last Modified: {moment(this.state.obData.dateModified).format('MM/DD/YYYY h:mm a')}</span>
                      {/* <Button style={{ marginRight: '20px' }} className="default-back-color btn-print hide-printing" type="button" onClick={this.getPdf}>
                        <img src={require("assets/img/Icon-Print.png")} alt="print" />
                      </Button> */}
                      <ReactToPrint 
                        trigger={() => {
                          return <Button style={{ marginRight: '20px' }} className="default-back-color btn-print hide-printing" type="button">
                                    <img src={require("assets/img/Icon-Print.png")} alt="print" />
                                  </Button> 
                        }} 
                        content={() => this.componentRef}
                        pageStyle={pageStyle}
                        />
                      <FormGroup check style={{ marginTop: 0, paddingLeft: 0 }} className="hide-printing">
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={this.onCompletedObservation}
                            checked={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                            disabled={this.disableCheckForCompletedObservation()}
                          ></Input>
                          <span className="form-check-sign complete-check-sign">{'Completed?'}</span>
                        </Label>
                      </FormGroup>
                    </Row>
                  </Container>
                  <div style={{display: "none"}}><ComponentToPrint ref={el => (this.componentRef = el)} /></div>
                  
                  <Container className="board">
                    {
                      this.renderIndicatorsByDomains()
                    }
                    <ReflectiveQuestion
                      obData={this.state.obData}
                    />
                  </Container>
                </div>
                {(this.props.auth.user.role === constant['ROLE']['OBSERVER'] ||
                  this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] || (this.state.obData && this.state.obData.observer && (this.props.auth.user.id === this.state.obData.observer._id))) &&
                  <div className="invite-teacher hide-printing">
                    <a onClick={(e) => { e.preventDefault(); this.setState({ emailModal: true }); }}
                      className="btn default-back-color btn-round font-crm" href="#pblo">Invite Teacher</a>
                  </div>
                }
              </div>
            </div>
            <Footer />
            <IndicatorModal
              component={this.state.componentWillUpdated}
              indicatorModal={this.state.indicatorModal}
              closeIndicatorModal={() => { this.closeIndicatorModal() }}
              standardIndicators={this.state.standardIndicators}
              obData={this.state.obData}
              updateObData={(obData) => { this.updateObData(obData) }}
            />
            <EmailModal
              emailModal={this.state.emailModal}
              closeEmailModal={() => { this.setState({ emailModal: false }) }}
              sendEmailToTeacher={(email) => { this.sendEmail(email) }}
              teachers={this.state.invitedTeacher}
            />
          </>
          :
          <>
            <div className="print-page" style={{ backgroundColor: '#888', minHeight: '100vh' }}>
              <div style={{ marginLeft: 15 }}>
                <Button type="button" onClick={() => { this.setState({ printPreview: false }) }}>
                  <i className="now-ui-icons arrows-1_minimal-left" style={{ fontSize: '2em' }}></i>
                </Button>
                <Button className="btn-print" type="button" onClick={this.printPdf} style={{ backgroundColor: '#888' }}>
                  <img src={require("assets/img/Icon-Print.png")} alt="print" />
                </Button>
              </div>
              <div id="debrief-report" style={{ backgroundColor: 'white', width: '28.7cm' }} className="ml-auto mr-auto">
                <Row className="padding-lg default-back-color text-white title-container">
                  <span style={{ marginRight: '60px' }}>Teacher: {this.state.obData.teacher}</span>
                  <span style={{ marginRight: '60px' }}>Grade: {this.state.obData.grade}</span>
                  <span style={{ marginRight: '60px' }}>Topic of Lesson: {this.state.obData.topic}</span>
                  <span style={{ marginRight: '60px' }}>Created: {moment(this.state.obData.date).format('MM/DD/YYYY h:mm a')}</span>
                  <span style={{ marginRight: '30px' }}>Last Modified: {moment(this.state.obData.dateModified).format('MM/DD/YYYY h:mm a')}</span>
                </Row>
                <div className="print-board" style={{ marginLeft: 10, marginRight: 10 }}>
                  {
                    this.renderIndicatorsByDomains()
                  }
                </div>
                <ReflectiveQuestion
                  obData={this.state.obData}
                  printPreview={this.state.printPreview}
                />
              </div>
            </div>
          </>
        }
      </>
    );
  }
}

Debrief.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Debrief);