import React, { Component } from "react";
import {
  Modal,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import constant from "../../shared/constant";
import { ToastContainer, toast } from 'react-toastify';

class IndicatorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indicator: ''
    }
  }

  componentDidMount() {

  }

  onChangeCheckStatus = (e, _id) => {
    let indicators = this.props.obData.indicators;
    let indicator = indicators.find((indicator) => { return indicator._id === _id });
    if (indicator) {
      indicator.checked = e.target.checked;
    }
    let obData = this.props.obData;
    obData.indicators = indicators;
    // this.props.closeIndicatorModal();
    this.props.updateObData(obData);
  }

  onChangeIndicator = (e) => {
    this.setState({ indicator: e.target.value });
  }

  onClickIndicatorSave = () => {
    if (this.state.indicator === '') {
      toast.error('Please add the name of indicator');
      return;
    }
    let indicator = {};
    indicator.components = [this.props.component._id];
    indicator.type = constant['OBSERVATION_TYPE']['STANDARD'];
    indicator.note = '';
    indicator.title = this.state.indicator;
    indicator.checked = true;
    let obData = this.props.obData;
    obData.indicators.push(indicator);
    this.props.updateObData(obData);
    this.setState({ indicator: '' });
  }

  renderIndicators = () => {
    return this.props.standardIndicators.map((indicator) => {
      return (
        <>
          <div className="indicator">
            <FormGroup check className="checkbox">
              <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={indicator.checked}
                  onChange={(e) => this.onChangeCheckStatus(e, indicator._id)}
                />
                <span className="form-check-sign"></span>
              </Label>
            </FormGroup>
            <Label>{indicator.title}</Label>
          </div>
        </>
      );
    });
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Modal
          modalClassName="modal-indicators"
          isOpen={this.props.indicatorModal}
          toggle={() => { this.setState({ indicator: '' }); this.props.closeIndicatorModal() }}
        >
          <div className="modal-title">
            {this.props.component.domain &&
              <h5 className={`${this.props.component.domain.index === 1 ? " color-domain-1" : ""} 
                ${this.props.component.domain.index === 2 ? " color-domain-2" : ""} 
                ${this.props.component.domain.index === 3 ? " color-domain-3" : ""} 
                ${this.props.component.domain.index === 4 ? " color-domain-4" : ""} 
                ${this.props.component.domain.index === 5 ? " color-domain-5" : ""}`}>
                {`${this.props.component.index}. ${this.props.component.name}`}
              </h5>
            }
          </div>
          <div className="modal-body just">
            {
              this.renderIndicators()
            }
            <div className="new-indicator">
              <InputGroup
                className={this.state.searchFocus ? "input-group-focus" : ""}
              >
                <InputGroupAddon addonType="prepend" onClick={() => this.onClickIndicatorSave()}>
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_simple-add"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  onChange={this.onChangeIndicator}
                  value={this.state.indicator}
                  placeholder=""
                  id="indicator"
                  type="text"
                  autoComplete="on"
                  onFocus={() => this.setState({ searchFocus: true })}
                  onBlur={() => this.setState({ searchFocus: false })}
                />
              </InputGroup>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <Button
              className="default-back-color font-crm"
              onClick={() => {
                if (this.state.indicator !== '') {
                  this.onClickIndicatorSave();
                }
                this.props.closeIndicatorModal();
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default IndicatorModal;