import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Home from "./components/home/Home";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import { initGA } from "./shared/gaUtils";
import ContactUs from "./components/contact-us/ContactUs";
import Observe from "./components/observe/Observe";

import Consolidate from "./components/consolidate/Consolidate";
import Indicators from "./components/admin/Indicators";
import Questions from "./components/admin/Questions";
import Debrief from "./components/debrief/Debrief";
import Classroom from "./components/classroom/Classroom";
import Current from "./components/current/Current";
import Portfolio from "./components/portfolio/Portfolio";
import Districts from "./components/admin/user-control/Districts";
import District from "./components/admin/user-control/District";
import School from "./components/admin/user-control/School";
import Users from "./components/admin/users/Users";
import SchoolAdminHome from "./components/schoolAdmin/home/Home";
import AdminPortfolio from "./components/adminPortfolio/AdminPortfolio";
import AdminResetPassword from "./components/resetPassword/ResetPassword";

import 'font-awesome/css/font-awesome.min.css';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

initGA();

class App extends Component {

  componentDidMount() {
    setInterval(() => {
      // Check for token to keep user logged in
      if (localStorage.jwtToken) {
        // Set auth token header auth
        const token = localStorage.jwtToken;
        setAuthToken(token);
        // Decode token and get user info and exp
        const decoded = jwt_decode(token);
        // Set user and isAuthenticated
        store.dispatch(setCurrentUser(decoded));

        // Check for expired token
        const currentTime = Date.now() / 1000; // to get in milliseconds
        
        if (decoded.exp < currentTime) {
          // Logout user
          store.dispatch(logoutUser());
          // Redirect to login
          window.location.href = "./login";
        }
      }
    }, 1000 * 60 * 5);
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Route path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password" component={ForgotPassword} />
            <Route path="/reset" component={ResetPassword} />
            <Route path="/contact-us" component={ContactUs} />
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute exact path="/debrief/:observationId" component={Debrief} />
              <PrivateRoute exact path="/classroom" component={Classroom} />
              <PrivateRoute exact path="/current" component={Current} />
              <PrivateRoute path="/observe" component={Observe} />
              <PrivateRoute exact path="/consolidate" component={Consolidate} />
              <PrivateRoute exact path="/debrief" component={Debrief} />
              <PrivateRoute exact path="/admin/indicators" component={Indicators} />
              <PrivateRoute exact path="/admin/questions" component={Questions} />
              <PrivateRoute exact path="/portfolio" component={Portfolio} />
              <PrivateRoute exact path="/districts" component={Districts} />
              <PrivateRoute path="/districts/create" component={District} />
              <PrivateRoute path="/districts/:id" component={District} />
              <PrivateRoute path="/school/:id/:school" component={School} />
              <PrivateRoute exact path="/admin/users" component={Users} />
              <PrivateRoute exact path="/schooladmin" component={SchoolAdminHome} />
              <PrivateRoute exat path="/adminPortfolio" component={AdminPortfolio} />
              <PrivateRoute exat path="/admin-reset-password" component={AdminResetPassword} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;