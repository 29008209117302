import React, { Component } from "react";
import "../observe/Observe.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// reactstrap components
import {
  Container,
  Row,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";
import _ from "lodash";
import constant from "../../shared/constant";

class SchoolObservations extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  editObservation(date) {
    this.props.editObservation(date);
  }

  renderDates(dates) {
    return dates.map((date) => {
      return (
        <>
          <a href="#pablo" style={{ marginRight: 10, color: (this.props.auth.user.id !== date.observerId) ? 'blue' : 'darkgreen'}} onClick={(e) => { e.preventDefault(); this.editObservation(date) }}>{moment(date.date).format('MM/DD/YYYY')}</a>
        </>
      );
    });
  }

  renderObservations = () => {
    return this.props.observationsInSchool.map((obData, index) => {
      const schoolData = _.find(this.props.auth.user.district.schools, { _id: obData.school });
      return (
        <>
          <tr key={index}>
            <td className="text-left font-crm"><span>{schoolData && schoolData.name}</span></td>
            <td className="text-center font-crm"><span>{obData.teacher}</span></td>
            <td className="text-center font-crm"><span>{obData.observer}</span></td>
            <td className="text-center font-crm"><span>{obData.total}</span></td>
            <td className="text-left font-crm"><span>{this.renderDates(obData.dates)}</span></td>
          </tr>
        </>
      );
    });
  }

  render() {
    return (
      <>
        <Container className="mt-5">
          <Row>
            <div className="mr-auto ml-auto col-md-12">
              {this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN'] &&
                <a onClick={(e) => { e.preventDefault(); this.props.createObservationRoom() }} 
                className="btn default-back-color btn-round font-crm" href="#pblo">Start New Observation</a>
              }
              {this.props.observationsInSchool && this.props.observationsInSchool.length > 0 ?

                <Card className='mt-3'>
                  <CardBody style={{ padding: 0 }}>
                      <Table responsive>
                        <thead style={{ backgroundColor: '#648292', color: 'white' }}>
                          <tr>
                            <th className="text-left"> <span>School</span></th>
                            <th className="text-center"><span>Teacher</span></th>
                            <th className="text-center"> <span>Observer</span></th>
                            <th className="text-center"> <span>Total</span></th>
                            <th className="text-left"> <span>Dates</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.renderObservations()
                          }
                        </tbody>
                      </Table>
                  </CardBody>
                </Card>
                :
                <h4>No Completed Observations Found.</h4>
              }
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

SchoolObservations.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(SchoolObservations);
