
module.exports = {
  OOPS_ERROR: "Oops!! Something went wrong",
  REQUEST_OK: "The request is OK",
  AUTH_FAIL: "Authentication failed",
  MAX_UPLOAD_IMAGE_SIZE: 10 * 1024 * 1024,
  'VALIDATION_MSG': {
    
  },

  'HOST_PATH': "http://142.93.192.103",
  
  'POPUP_MSG': {
    'VERIFY_EMAIL': '',
  },

  'ROLE': {
    'SUPER_ADMIN': 'SuperAdmin',
    'DISTRICT_ADMIN': 'DistrictAdmin',
    'SCHOOL_ADMIN': 'SchoolAdmin',
    'OBSERVER': 'Observer',
    'TEACHER': 'Teacher', 
    'MANAGER': 'Manager'
  },

  'OBSERVATION_TYPE': {
    'STANDARD': 0,
    'RELATIONSHIP': 1,
    'REGULATION': 2,
    'LANGUAGE': 3,
    'SAFETY': 4,
    'DISCIPLINE': 5,
    'CUSTOM': 6,
    'ACTION': 7
  },

  'DATA_SAVE_TYPE': {
    'INDICATORS': 0,
    'ACTIONS': 1,
    "QUESTIONS": 2
  },

  'API_URLS': {
    'SIGN_UP': {
      'name': '/signup',
      'type': 'post',
      'tokenEnabled': false
    },
    'SIGN_IN': {
      'name': '/login',
      'type': 'post',
      'tokenEnabled': false
    },
    'SIGN_OUT': {
      'name': '/logout',
      'type': 'post'
    }
  },

  'HTTP_STATUS_CODES': {
    'SUCCESS': 200,
    'IM_USED': 226,
    'FAIL': 400,
    'UNAUTHORIZED': 401,
    'NOT_FOUND': 404,
    'NOT_ACCEPTABLE': 406,
    'CONFLICT': 409,
    'INVALID_BAR_ID': 417,
    'UNPROCESSABLE': 422,
    'LOCKED': 423,
    'FAILED_DEPENDENCY': 424,
    'PRECONDITION_REQUIRED': 428
  },
  
  'OBSERVATION_ID': 'ObservationId',

  'OBSERVATION_STATUS': {
    'COMPLETED' : 2,
    'OPEN' : 1,
    'CLOSED' : 0,
  },

  'STATUS': {
    'ACTIVE': 1,
    'EMAILED': 0,
    'DELETED': -1
  },

  'MEMBERSHIP': {
    'FREE_TRIAL' : 0,
    'PREMIUM' : 1, 
  },
  'DATE_FORMAT': 'MM/DD/YYYY',
  'RATINGS': [
    {
      name: 'Family Culture',
      index: 1,
      texts: [
        "Several indicators of family culture are evident. The teacher is genuine and clearly in charge of the students' well-being.",
        "Some indicators of a family culture.",
        "No indicators of family culture are observed or felt.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Teacher-Student Relationships',
      index: 2,
      texts: [
        "Many srategies are used to make personal connections with all students. Teacher-student relationships are genuine and a priority.",
        "Some strategies are used to make personal connections.",
        "The teacher does not make personal connections.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Student-Student Relationships',
      index: 3,
      texts: [
        "Students connect with one another in a positive manner. Many effective strategies are used to build and promote student-student relationships.",
        "Some strategies are used to build student-student relationships.",
        "No strategies are used to build student relationships.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Teacher-Parent Relationships',
      index: 4,
      texts: [
        "Many srategies are used to create a positive school-to-home connection and engage with parents.",
        "Some strategies are used to communicate with parents.",
        "No strategies are used to connect or engage with parents.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Physical Environment',
      index: 5,
      texts: [
        "Overall sense of calm and balance. Clearly defined work areas.",
        "Some adjustments made to create a more regulating environment.",
        "Limited regulating elements are present. Classroom layout does not promote connection.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Universal Proactive Supports',
      index: 6,
      texts: [
        "Whole classroom regulation strategies are used effectively. The teacher is organized, proactie and attuned to students' needs.",
        "Whole classroom regulation strategies are used with some success and are starting to ecome and integral part of the classroom.",
        "Some regulation strategies are used  but are not a part of the culture of the classroom.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Transitional Support',
      index: 7,
      texts: [
        "Transition support is provided before, during, and after a transition. Teacher is attuned to students who need extra assistance with transitions. Support is effective.",
        "Some transitional support is provided. It is mostly effective",
        "Transition support is limited or inconsistent.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Individual Interventions',
      index: 8,
      texts: [
        "Attuned to individual regulation strategies are available and/or offered.",
        "Some individual regulation strategies are available and/or offered.",
        "Not attuned to students. Opportunities to support individual students are missed.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Awareness',
      index: 9,
      texts: [
        "Genuine concern and awareness of students' emotional well-being. Uses empathetic and validating responses.",
        "Some awareness of students' emotional well-being. Inconsistently gives empathy and validation.",
        "Limited empathy and/or conversations about how students are feeling. Invalidating responses.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Window of Stress Tolerance',
      index: 10,
      texts: [
        "Exhibits awareness of each student's window of stress tolerance. Differentiates expectations effectively.",
        "Exhibits some awareness of each student's window of stress tolerance.",
        "No awareness of varying windows of stress tolerance.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Teacher Self-Regulation',
      index: 11,
      texts: [
        "Consistently regulated and self-confident.",
        "Mostly regulated. Self-confidence emerging.",
        "Dysregulated, stressed and/or easily triggered. Self-confidence is lacking.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Communication Shift',
      index: 12,
      texts: [
        "Before responding to dysregulated student, teacher pauses, interprets negative behavior/language, and seeks to understand.",
        "When students are starting to escalate, some connection is offered.",
        "When students are escalating, teacher is reactive and focuses on stopping or changing the negative behavior.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Right-Brain to Right-Brain De-Escalation',
      index: 13,
      texts: [
        "De-escalates students effectively. Is able to 'join' the student.",
        "Attempts to de-escalate students are not always effective. Somewhat focused on solutions and logic.",
        "Attempts to de-escalate are ineffective. Focused solely on solutions and unable to 'join' the student.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Affect Tolerance',
      index: 14,
      texts: [
        "Absorbs negative language/behavior. Is able to tolerate intense emotions.",
        "Somewhat able to handle students' negative attitude, language, behavior, and intense emotions.",
        "Shuts down dysregulated students. No connection offered. Is unable to handle intense emotions.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Nonverbal Communication',
      index: 15,
      texts: [
        "Verbal and nonverbal communication match. Appropriately reads students' nonverbal communication.",
        "Verbal and nonverbal cues do not always match. Somewhat able to read students' nonverbal cues.",
        "Verbal and nonverbal communication are misaligned and confusing. Unable to read students' nonverbal cues.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Positive Language',
      index: 16,
      texts: [
        "Describes negative behaviors with respect, dignity, and understanding.",
        "Describes students in traditional language with some trauma-informed variations.",
        "Describes students based solely off of their behaviors with traditional or negative language.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Universal Safety Practices',
      index: 17,
      texts: [
        "Safety is the number one rule. Several appropriate proactive safety measures in place.",
        "Some proactive safety measures in place.",
        "No whole-class proactive safety measures in place.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Identification of Individual Safety Issues',
      index: 18,
      texts: [
        "Attuned to students with known safety issues. Saftey plans in place for specific students.",
        "Some safety measures in place for individual students.",
        "No proactive individualized safety plans.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Responding in a Moment of Crisis',
      index: 19,
      texts: [
        "Intervenes effectively in a moment of crisis. Able to remain calm and businesslike.",
        "Moment-of-crisis plans are in place. Somewhat effective when intervening in a moment of crisis. Some fear-based dialogue evident.",
        "Does not intervene appropriately in a moment of crisis. Ineffective in remaining calm and business like.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Regulatory-Based Discipline',
      index: 20,
      texts: [
        "Free of fear-based behavior tracking systems. Uses effective strategies to develop students' self-regulation skills and internal controls.",
        "Some modifications made to behavioral system to incorporate regulation.",
        "Uses a traditional behavioral system focused on points, rewards, and consequences.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Consequences with Relational Support',
      index: 21,
      texts: [
        "Consequences regulate students and teach a better way forwaqrd. Connection is provided.",
        "Some use of consequences to regulate and teach.",
        "Consequences are mainly used to punish rather than to regulate or teach. Little connection is offered.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Repair and Healing',
      index: 22,
      texts: [
        "Effective use of restorative practices to repair relationships alter an offensive action.",
        "Some use of restorative practices.",
        "Little to no focus on repair, healing, or damage to relationships.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Developmental Deficits',
      index: 23,
      texts: [
        "Accurately identifies and addresses underlying skill deficits that can lead to behavioral issues.",
        "Some identification of underlying develop-mental deficits.",
        "Limited to no connection made between behavioral issues and develop-mental deficits.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Grades',
      index: 24,
      texts: [
        "Grading system is positively structured and communicated with encouragement and supports.",
        "Some modifications are made to communicate grades positively and focus on learning.",
        "Traditional grading system with little to no positive communication.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Social and Emotional Learning',
      index: 25,
      texts: [
        "SEL skills are embedded and taught explicitly from a trauma lens. Safe, open, and caring environment invites social and emotional expression.",
        "Limited discussion of trauma-related responses and disclosures. Some modifications made to SEL from a trauma lens.",
        "Limited social and emotional expression in the classroom. SEL lessons are either non-existent, generic or not modified from a trauma lens.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Self-Image and Identity Development',
      index: 26,
      texts: [
        "Many effective strategies used to address students' underlying negative belief systems.",
        "Some strategies used to address student' underlying negative belief systems.",
        "Limited to no strategies used to address student' negative belief systems.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    },
    {
      name: 'Reflection and Ongoing Growth',
      index: 27,
      texts: [
        "Continual efforts made to stay positive, reflect, and grow.",
        "Some efforts made to stay positive, reflect, and grow.",
        "Little to no efforts made to stay positive, rflect and grow.",
        "Unacceptable, damaging and/or inappropriate.",
        "Not observed or not applicable."
      ]
    }
  ],
  'COMPONENT_PAGES': [
    'pp. 19-33',
    'pp. 33-45',
    'pp. 46-53',
    'pp. 53-57',
    'pp. 64-72',
    'pp. 72-87',
    'pp. 87-91',
    'pp. 91-102',
    'pp. 102-108',
    'pp. 108-109',
    'pp. 109-120',
    'pp. 128-134',
    'pp. 134-142',
    'pp. 142-148',
    'pp. 148-153',
    'pp. 153-166',
    'pp. 174-185',
    'pp. 185-189',
    'pp. 189-194',
    'pp. 201-216',
    'pp. 216-219',
    'pp. 220-224',
    'pp. 224-234',
    'pp. 234-240',
    'pp. 240-253',
    'pp. 254-270',
    'pp. 270-271'
  ]
};
