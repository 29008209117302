import { SET_CURRENT_SCHOOL } from "../actions/types";

const initialState = {
  admins: [], 
  count: 0, 
  email: "", 
  name: "", 
  users: [], 
  _id: "", 
  archived: false
}

export default function(state = initialState, action) {
  switch(action.type) {
    case SET_CURRENT_SCHOOL: 
      return {
        ...state, 
        admins: [...action.payload.admins], 
        count: action.payload.count, 
        email: action.payload.email, 
        name: action.payload.name, 
        users: [...action.payload.users], 
        _id: action.payload._id, 
        archived: action.payload.archived
      }
    default: 
      return state
  }
}