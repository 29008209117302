import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Card,
  CardBody,
  Button,
  Col,
  Label,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ButtonGroup,
  Container,
  Modal,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import CardFooter from "reactstrap/lib/CardFooter";
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import CardHeader from "reactstrap/lib/CardHeader";
import Footer from "components/shared/Footer.js";
import DistrictModal from "./DistrictModal";
import constant from "../../../shared/constant";

class District extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'create',
      district: {
        name: '',
        admins: [],
        schools: [],
        totalObservations: 0,
      },
      school: null,
      schoolName: '',
      schoolEmail: '',
      districtName: '',
      districtModal: false,
      editType: 'School',
      editEmail: '',
      editName: '',
      editIndex: -1,
      schoolAdminFirstName: '',
      schoolAdminLastName: '',
      schoolAdminEmail: '',
      schoolUserFirstName: '',
      schoolUserLastName: '',
      schoolUserEmail: '',
      editData: null,
      modalFirstName: '',
      modalLastName: '',
      modalEmail: '',
      deleteModal: false
    }
  }

  componentDidMount() {
    document.body.classList.add("user-control-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN'] && this.props.auth.user.role !== constant['ROLE']['DISTRICT_ADMIN']) {
      this.props.history.push('/');
    } else {
      if (this.props.match.params.id) {
        this.setState({ type: 'edit' });
        this.getDistrictDetail(this.props.match.params.id);
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("user-control-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getDistrictDetail = (districtId) => {
    axios
      .get(`/api/districts/${districtId}`)
      .then((res) => {
        if(res.data.schools && res.data.schools.length > 0) {
          let school = res.data.schools.filter(s => s._id === this.props.match.params.school)[0];
          this.setState({school: school});
        }
        this.setState({ district: res.data });
      }).catch((err) => {
        console.log(err);
      });
  }

  onChange = (e) => {
    if (e.target.id === 'schoolAdminFirstName' || e.target.id === 'schoolAdminLastName' || e.target.id === 'schoolAdminEmail' || e.target.id === 'schoolUserFirstName' || e.target.id === 'schoolUserLastName' || e.target.id === 'schoolUserEmail' || e.target.id === 'modalFirstName' || e.target.id === 'modalLastName' || e.target.id === 'modalEmail') {
      this.setState({ [e.target.id]: e.target.value });
    } else {
      let districtObj = this.state.district;
      districtObj[e.target.id] = e.target.value;
      this.setState({ district: districtObj });
    }
  }

  saveDistrict(district, extra) {
    if (this.state.type === 'edit') {
      axios
        .put(`/api/districts/${district._id}`, { district: district, extra: extra })
        .then((res) => {
          if (res.status === 200) {
            toast.info("Successfully Updated the District/School Admins.");
          } else {
            toast.info(res.data.message);
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while updating District data.');
          }
        });
    } else {
      axios
        .post('/api/districts', { district })
        .then((res) => {
          if (res.status === 200) {
            toast.info("Successfully Created the District/School Admins.");
          } else {
            toast.info(res.data.message);
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while creating District data.');
          }
        });
    }
  }

  updateDistrictSchoolData = (data) => {
    console.log(data);
    this.setState({ districtModal: false, editIndex: -1, editName: '', editEmail: '', editType: 'School' });
    if (data.type === 'School') {
      let schools = this.state.district.schools;
      let school = schools[data.index];
      let emailUpdated = false;
      if (school.name !== data.name && school.email !== data.email) {
        emailUpdated = true;
        school.name = data.name;
        school.email = data.email;
      } else if (school.name !== data.name || school.email === data.email) {
        emailUpdated = false;
        school.name = data.name;
      } else if (school.name === data.name || school.email !== data.email) {
        emailUpdated = true;
        school.email = data.email;
      } else {
        return;
      }
      schools.splice(data.index, 1, school);
      let district = {
        ...this.state.district,
        schools: schools,
      }
      this.saveDistrict(district, { method: 'UPDATE_SCHOOL', email: data.email, emailUpdated: emailUpdated });
    } else {
      let emails = this.state.district.emails;
      const email = emails.find((email) => email === data.email);
      if (!email || email === undefined) {
        emails.splice(data.index, 1, data.email);
        let district = {
          ...this.state.district,
          emails: emails,
        }
        this.saveDistrict(district, { method: 'UPDATE_DISTRICT', email: data.email });
      } else {
        console.log("Same Email");
      }

    }
  }

  updateTotalUserCount = (isPlus) => {
    let districtObj = this.state.district;
    let count = districtObj.totalObservations;
    if (!isPlus) {
      if (count <= 0) return;
      count = count - 1;
    } else {
      count = count + 1;
    }
    districtObj['totalObservations'] = count;
    this.setState({ district: districtObj });
  }

  addSchoolData = () => {
    if (this.state.schoolName === '') {
      toast.info('School Name can not be empty');
      return;
    }
    let schools = this.state.district.schools;
    schools.push({
      name: this.state.schoolName,
      email: this.state.schoolEmail
    });
    if (this.state.type === 'edit') {
      let district = {
        ...this.state.district,
        schools: this.state.district.schools,
      }
      this.saveDistrict(district, { method: 'UPDATE_SCHOOL', email: this.state.schoolEmail, emailUpdated: true });
    }
    this.setState({ schoolName: '', schoolEmail: '' });
  }

  addSchoolAdminData = () => {
    if (this.state.schoolAdminEmail === '') {
      toast.info('School Admin Email can not be empty');
      return;
    }
    if (this.state.schoolAdminFirstName === '' && this.state.schoolAdminLastName === '') {
      toast.info('School Admin Name can not be empty');
      return;
    }
    let admin = {email: this.state.schoolAdminEmail, name: this.state.schoolAdminFirstName + ' ' + this.state.schoolAdminLastName};
    let tempSchool = {...this.state.school};
    tempSchool.admins.push(admin);
    tempSchool.admins.reverse();
    this.setState({
      school: tempSchool,
      schoolAdminEmail: '', 
      schoolAdminFirstName: '', 
      schoolAdminLastName: ''
    })
    // if (this.state.type === 'edit') {
    //   let district = {
    //     ...this.state.district,
    //     emails: this.state.district.emails,
    //   }
    //   this.saveDistrict(district, { method: 'UPDATE_DISTRICT', email: this.state.email });
    // }
  }

  addSchoolUserData = () => {
    if (this.state.schoolUserEmail === '') {
      toast.info('School User Email can not be empty');
      return;
    }
    if (this.state.schoolUserFirstName === '' && this.state.schoolUserLastName === '') {
      toast.info('School User Name can not be empty');
      return;
    }
    let user = {email: this.state.schoolUserEmail, name: this.state.schoolUserFirstName + ' ' + this.state.schoolUserLastName};
    let tempSchool = {...this.state.school};
    tempSchool.users.push(user);
    tempSchool.users.reverse();
    this.setState({
      school: tempSchool,
      schoolUserEmail: '', 
      schoolUserFirstName: '', 
      schoolUserLastName: ''
    })
    // if (this.state.type === 'edit') {
    //   let district = {
    //     ...this.state.district,
    //     emails: this.state.district.emails,
    //   }
    //   this.saveDistrict(district, { method: 'UPDATE_DISTRICT', email: this.state.email });
    // }
  }

  removeSchoolData = (school, index) => {
    let schools = this.state.district.schools;
    schools.splice(index, 1);
    this.setState({ schools: schools }, () => {
      if (this.state.type === 'edit') {
        let district = {
          ...this.state.district,
          schools: this.state.district.schools,
        }
        this.saveDistrict(district, { method: 'DELETE_SCHOOL', school: school });
      }
    });
  }

  removeDistrictAdminEmail = (admin, index) => {
    let tempDistrict = this.state.district;
    tempDistrict.admins.splice(index, 1);
    this.setState({ district: tempDistrict }, () => {
      if (this.state.type === 'edit') {
        let district = {
          ...this.state.district,
          admins: this.state.district.emails,
        }
        this.saveDistrict(district, { method: 'DELETE_DISTRICT', email: admin.email });
      }
    });
  }

  editSchoolUser = (user, index) => {
    this.setState({ districtModal: true, modalFirstName: user.name.split(' ')[0], modalLastName: user.name.split(' ')[1], modalEmail: user.email, editIndex: index, editType: 'user' });
  }

  editSchoolAdmin = (admin, index) => {
    let lastName = admin.name.split(' ').length > 2 ? `${admin.name.split(' ')[1]} ${admin.name.split(' ')[2]}` : admin.name.split(' ')[1]
    this.setState({ 
      districtModal: true, 
      modalFirstName: admin.name.split(' ')[0], 
      modalLastName: lastName, 
      modalEmail: admin.email, 
      editIndex: index, 
      editType: 'admin' 
    });
  }

  renderSchoolUsers = () => {
    if(this.state.school && this.state.school.users && this.state.school.users.length > 0) {
      let tempUsers = this.state.school.users.sort((a, b) => a.name > b.name ? 1 : -1)
      return tempUsers.map((user, index) => {
        return (
          <>
            <Row style={{ alignItems: 'center', marginLeft: '10px' }}>
              <>
                {this.state.type === 'create' &&
                  <Button
                    className="btn-icon btn-neutral btm btn-google mr-1"
                    onClick={() => { this.removeSchoolData(user, index) }}
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </Button>
                }
                {this.state.type === 'edit' &&
                  <Button
                    className="btn-icon btn-neutral btm btn-twitter mr-1"
                    onClick={() => { this.editSchoolUser(user, index) }}
                  >
                    <i className="now-ui-icons ui-2_settings-90"></i>
                  </Button>
                }
              </>
              <Row style={{ marginLeft: '10px', width: '90%' }}>
                <Col lg={4}>
                  <span className="font-crm">{user.name}</span>
                </Col>
                <Col lg={6}>
                  <span className="font-crm">{user.email}</span>
                </Col>
                <Col lg={2} className='text-center'>
                  <span className="font-crm">{user.count ? user.count : 0}</span>
                </Col>
              </Row>
            </Row>
          </>
        )
      });
    }
  }

  renderSchoolAdmins = () => {
    if(this.state.school && this.state.school.admins && this.state.school.admins.length > 0) {
      let tempAdmins = this.state.school.admins.sort((a, b) => a.name > b.name ? 1 : -1)
      return tempAdmins.map((admin, index) => {
        return (
          <>
            <Row style={{ alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}>
              <>
                {this.state.type === 'create' &&
                  <Button
                    className="btn-icon btn-neutral btm btn-google"
                    onClick={() => { this.removeDistrictAdminEmail(admin, index) }}
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </Button>
                }
                {this.state.type === 'edit' &&
                  <Button
                    className="btn-icon btn-neutral btm btn-twitter"
                    onClick={() => { this.editSchoolAdmin(admin, index) }}
                  >
                    <i className="now-ui-icons ui-2_settings-90"></i>
                  </Button>
                }
              </>
              <Row style={{ marginLeft: '10px', width: '90%' }}>
                <Col lg={6}>
                  <span className="font-crm">{admin.name}</span>
                </Col>
                <Col lg={6}>
                  <span className="font-crm">{admin.email}</span>
                </Col>
              </Row>
            </Row>
          </>
        )
      });
    }
  }


  saveDistrictData = () => {
    if (this.state.name === '') {
      toast.info('District Name can not be empty');
      return;
    }
    let tempDistrict = {...this.state.district};
    tempDistrict.schools.filter(school => school._id === this.state.school._id)[0] = this.state.school;
    this.setState({
      district: tempDistrict
    })
    // let district = {
    //   name: this.state.district.name,
    //   admins: this.state.district.admins,
    //   schools: this.state.district.schools,
    //   totalObservations: this.state.district.totalObservations
    // }
    // if (this.state.type === 'edit') {
    //   district = {
    //     ...district,
    //     _id: this.state.district._id,
    //   }
    // }
    this.props.history.push('/districts');
    this.saveDistrict(tempDistrict);
  }

  csvUpload = () => {
    document.getElementById('csvPicker').click();
  }

  getCSV = () => {
    if (!document.getElementById('csvPicker').files[0]) {
      console.log("No file selected.");
    } else {
      let file = document.getElementById('csvPicker').files[0];
      let fr = new FileReader();
      let tempSchool = {...this.state.school};
      let component = this;
      fr.onload = function() {
        let content = fr.result.split('\r\n');
        let firstName = 0;
        let lastName = 0;
        let email = 0;
        content[0].split(',').forEach(item => {
          if (item === 'First Name') {
            firstName = content[0].split(',').indexOf(item);
          }
          if (item === 'Last Name') {
            lastName = content[0].split(',').indexOf(item);
          }
          if (item === 'Email') {
            email = content[0].split(',').indexOf(item);
          }
        })
        content.forEach((item, index) => {
          if(index > 0) {
            let data = item.split(',');
            let user = {email: data[email], name: data[firstName] + ' ' + data[lastName]};
            tempSchool.users.push(user);
          }
        })
        component.setState({school: tempSchool});
      };
      fr.readAsText(file);
    }
  }

  onClickUpdate = () => {
    let temp = {name: this.state.modalFirstName + ' ' + this.state.modalLastName, email: this.state.modalEmail};
    let tempData = {...this.state.school}
    if(this.state.editType === 'admin') {
      tempData.admins[this.state.editIndex] = temp;
    } else {
      tempData.users[this.state.editIndex] = temp;
    }
    this.setState({
      school: tempData,
      districtModal: false
    })
  }

  onClickDelete = () => {
    this.setState({ deleteModal: true });
  }

  deleteUser = () => {
    let tempData = {...this.state.school}
    if(this.state.editType === 'admin') {
      tempData.admins.splice(this.state.editIndex, 1);
    } else {
      tempData.users.splice(this.state.editIndex, 1);
    }
    this.setState({
      school: tempData,
      districtModal: false,
      deleteModal: false
    })
  }

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <DropdownScrollNavbar />
        <div className="wrapper">
          <div className="home">
            <Container>
              <Row>
                <div className="mr-auto ml-auto col-lg-10 col-md-12">
                  <Card className="card-raised mt-2 form-card">
                    <CardHeader>
                      {this.state.type === 'edit' ?
                        <h5 className="default-back-color page-title">
                          School Information
                        </h5>
                        :
                        <h5 className="default-back-color page-title">
                          Add District/School
                        </h5>
                      }
                    </CardHeader>
                    <CardBody>
                      <Col>
                        <Label className="font-crm font-crm-bold">School Name</Label>
                        <p className="font-crm" style={{paddingLeft: '20px', fontWeight: '500'}}>{this.state.school && this.state.school.name}</p>
                      </Col>
                      <Col>
                        <Label className="font-crm font-crm-bold">School Admin</Label>
                        <Row style={{ alignItems: 'flex-end', marginRight: 0 }} className="mt-2">
                          <Col xs='3'>
                            <InputGroup
                              className={this.state.adminFirstNameFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons business_bank"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.schoolAdminFirstName}
                                placeholder="Admin First Name"
                                id="schoolAdminFirstName"
                                type="text"
                                autoComplete="off"
                                required
                                onFocus={() => this.setState({ adminFirstNameFocus: true })}
                                onBlur={() => this.setState({ adminFirstNameFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs='3'>
                            <InputGroup
                              className={this.state.adminLastNameFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons business_bank"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.schoolAdminLastName}
                                placeholder="Admin Last Name"
                                id="schoolAdminLastName"
                                type="text"
                                autoComplete="off"
                                required
                                onFocus={() => this.setState({ adminLastNameFocus: true })}
                                onBlur={() => this.setState({ adminLastNameFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs='5'>
                            <InputGroup
                              className={this.state.districtAdminFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons ui-1_email-85"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.schoolAdminEmail}
                                placeholder="School Admin Email"
                                id="schoolAdminEmail"
                                type="text"
                                autoComplete="off"
                                onFocus={() => this.setState({ districtAdminFocus: true })}
                                onBlur={() => this.setState({ districtAdminFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                          <Button className="btn btn-default default-back-color" onClick={() => { this.addSchoolAdminData() }}>
                            <i className="now-ui-icons ui-1_simple-add"></i>
                          </Button>
                        </Row>
                        {
                          this.renderSchoolAdmins()
                        }
                      </Col>
                      <Col>
                        <Label className="font-crm font-crm-bold">Users</Label>
                        <Button
                          className="default-back-color font-crm mr-2 csv-btn"
                          onClick={this.csvUpload}
                        >
                          CSV EMAIL UPLOADER
                        </Button>
                        <Row style={{ alignItems: 'flex-end', marginRight: 0 }} className="mt-2">
                          <Col xs='3'>
                            <InputGroup
                              className={this.state.userFirstNameFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons business_bank"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.schoolUserFirstName}
                                placeholder="User First Name"
                                id="schoolUserFirstName"
                                type="text"
                                autoComplete="off"
                                required
                                onFocus={() => this.setState({ userFirstNameFocus: true })}
                                onBlur={() => this.setState({ userFirstNameFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs='3'>
                            <InputGroup
                              className={this.state.userLastNameFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons business_bank"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.schoolUserLastName}
                                placeholder="User Last Name"
                                id="schoolUserLastName"
                                type="text"
                                autoComplete="off"
                                required
                                onFocus={() => this.setState({ userLastNameFocus: true })}
                                onBlur={() => this.setState({ userLastNameFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs='5'>
                            <InputGroup
                              className={this.state.userMailFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons ui-1_email-85"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={this.onChange}
                                value={this.state.schoolUserEmail}
                                placeholder="User Email"
                                id="schoolUserEmail"
                                type="text"
                                autoComplete="off"
                                onFocus={() => this.setState({ userMailFocus: true })}
                                onBlur={() => this.setState({ userMailFocus: false })}
                              />
                            </InputGroup>
                          </Col>
                          <Button className="btn btn-default default-back-color" onClick={() => { this.addSchoolUserData() }}>
                            <i className="now-ui-icons ui-1_simple-add"></i>
                          </Button>
                        </Row>
                        {
                          this.state.school && this.state.school.users && this.state.school.users.length > 0 &&
                          <Row style={{ alignItems: 'center', marginLeft: '10px', marginTop: '10px'}}>
                            <Button
                              className="btn-icon btn-neutral btm btn-twitter mr-1"
                              style={{opacity: 0}}
                            >
                              <i className="now-ui-icons ui-2_settings-90"></i>
                            </Button>
                            <Row style={{ marginLeft: '10px', width: '90%' }}>
                              <Col lg={4}>
                              </Col>
                              <Col lg={6}>
                              </Col>
                              <Col lg={2} className='text-center'>
                                <span className="font-crm" style={{fontWeight: '600'}}>Used Count</span>
                              </Col>
                            </Row>
                          </Row>
                        }
                        {
                          this.renderSchoolUsers()
                        }
                      </Col>
                    </CardBody>
                    <CardFooter style={{ justifyContent: 'flex-end' }}>
                      <Button
                        className="default-back-color font-crm mr-2"
                        onClick={() => { this.props.history.push("/districts"); }}
                      >
                        Back
                      </Button>
                      <Button
                        className="red-alert-color font-crm"
                        onClick={() => this.saveDistrictData()}
                      >
                        Save
                      </Button>
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
        <Modal
          modalClassName="modal-district"
          isOpen={this.state.districtModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color">
              {this.state.editType === 'admin' ?
                'Edit Admin Information' :
                'Edit User Information'
              }
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col>
                <InputGroup
                  className={this.state.modalFirstNameFocus ? "input-group-focus" : ""}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons business_bank"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.onChange}
                    value={this.state.modalFirstName}
                    placeholder="First Name"
                    id="modalFirstName"
                    type="text"
                    autoComplete="off"
                    required
                    onFocus={() => this.setState({ modalFirstNameFocus: true })}
                    onBlur={() => this.setState({ modalFirstNameFocus: false })}
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup
                  className={this.state.modalLastNameFocus ? "input-group-focus" : ""}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons business_bank"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.onChange}
                    value={this.state.modalLastName}
                    placeholder="Last Name"
                    id="modalLastName"
                    type="text"
                    autoComplete="off"
                    required
                    onFocus={() => this.setState({ modalLastNameFocus: true })}
                    onBlur={() => this.setState({ modalLastNameFocus: false })}
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup
                  className={this.state.modalEmailFocus ? "input-group-focus" : ""}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.onChange}
                    value={this.state.modalEmail}
                    placeholder="Email"
                    id="modalEmail"
                    type="text"
                    autoComplete="off"
                    required
                    onFocus={() => this.setState({ modalEmailFocus: true })}
                    onBlur={() => this.setState({ modalEmailFocus: false })}
                  />
                </InputGroup>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <Button
              className="red-alert-color font-crm mr-2"
              onClick={() => { this.setState({ districtModal: false, editIndex: -1, editData: null, editType: 'admin' }) }}
            >
              Close
            </Button>
            <Button
              className="default-back-color font-crm mr-2"
              onClick={() => this.onClickUpdate()}
            >
              Update
            </Button>
            <Button
              className="default-back-color font-crm"
              onClick={() => this.onClickDelete()}
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-district"
          isOpen={this.state.deleteModal}
          // toggle={() => { this.setState({ email: '', name: '', type: 'School' }); this.props.closeDistrictModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color">
              &nbsp;
            </h5>
          </div>
          <div className="modal-body just">
            <h5 style={{color: 'black'}}>Are you sure you want to delete {this.state.editType}?</h5>
          </div>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              className="default-back-color font-crm mr-5"
              onClick={() => this.deleteUser()}
            >
              OK
            </Button>
            <Button
              className="default-back-color font-crm"
              onClick={() => { this.setState({ deleteModal: false }) }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <input type='file' id='csvPicker' style={{display: 'none'}} accept=".csv" onChange={this.getCSV}></input>
      </>
    )
  }
}

District.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(District);