import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  CardTitle
} from "reactstrap";


import { GApageView } from "../../shared/gaUtils";
import FooterWhite from "../shared/FooterWhite";
import './auth.css';
import _ from 'lodash';
import constant from "../../shared/constant";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {},
      firstFocus: false,
      lastFocus: false,
      observation: null,
      passwordShown: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    if (nextProps.auth.isAuthenticated) {
      if (this.state.observation) {
        this.props.history.push(`/debrief/${this.state.observation}`);
      } else if (nextProps.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
        this.props.history.push("/admin/users");
      } else {
        if (localStorage.getItem('isSchoolAdmin') === 'true') {
          this.props.history.push("/schooladmin");
        } else {
          this.props.history.push("/"); // push user to home page when they login
        }
      }
    }
  }

  componentDidMount() {
    const queryString = require('query-string');
    const parsed = queryString.parse(this.props.location.search);
    if (!_.isEmpty(parsed)) {
      if (!parsed.district && parsed.role === constant['ROLE']['TEACHER']) {
        // If logged in and user navigates to Login page, should redirect them to home
        if (this.props.auth.isAuthenticated) {
          this.props.history.push(`/debrief/${parsed.observation}`);
          return;
        } else {
          this.setState({ observation: parsed.observation, email: parsed.email});
        }
      }
    } else {
      // If logged in and user navigates to Login page, should redirect them to home
      if (this.props.auth.isAuthenticated) {
        if (localStorage.getItem('isSchoolAdmin') === 'true') {
          this.props.history.push("/schooladmin");
        } else {
          this.props.history.push("/"); // push user to home page when they login
        }
        return;
      }
    }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    GApageView("Login");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const userData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, 
    // we don't need to pass in this.props.history as a parameter
  };

  togglePassword = () => {
    this.setState({
        passwordShown: !this.state.passwordShown
    })
  };

  render() {
    const { errors, passwordShown } = this.state;
    return (
      <>
        <div className="page-header header-filter">
          <svg className="divider-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern1" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back1.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern1)" points="0,0 100,0 0,100" />
          </svg>
          <svg className="divider-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
              <pattern id="pattern2" height="100%" width="100%" patternContentUnits="objectBoundingBox">
                <image height="2.5" width="1" preserveAspectRatio="none" xlinkHref={require("assets/img/login-back2.png")} />
              </pattern>
            </defs>
            <polygon fill="url(#pattern2)" points="50,100 100,0 100,100" />
          </svg>
          <div className="content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8" lg="5">
                  <Row className="brand justify-content-center">
                    <img src={require("assets/img/logo.png")} alt="" />
                  </Row>
                  <Card className="card-login mt-4">
                    <Form noValidate action="" className="form" method="" onSubmit={this.onSubmit}>
                      <CardBody>
                        <CardTitle className="text-center" tag="h3">
                          Sign In
                        </CardTitle>
                        <InputGroup
                          className={this.state.firstFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            placeholder="Email"
                            id="email"
                            type="email"
                            autoComplete="off"
                            onFocus={() => this.setState({ firstFocus: true })}
                            onBlur={() => this.setState({ firstFocus: false })}
                          />
                        </InputGroup>
                        <span className="text-left" style={{color: "red"}}>
                          {errors.email}
                          {errors.emailnotfound}
                        </span>
                        <InputGroup
                          className={this.state.lastFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend" onClick={this.togglePassword}>
                            <InputGroupText>
                              <i className="far fa-eye"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            placeholder="Password"
                            id="password"
                            type={passwordShown ? 'password' : 'text'}
                            autoComplete="off"
                            onFocus={() => this.setState({ lastFocus: true })}
                            onBlur={() => this.setState({ lastFocus: false })}
                          />
                        </InputGroup>
                        <span className="color-default text-left">
                          {errors.password}
                          {errors.passwordincorrect}
                        </span>
                        <Button
                          block
                          className="btn-round default-back-color mt-4 font-crm"
                          type="submit"
                          size="md"
                        >
                          Enter Now
                        </Button>
                        <div className="btn-create-reset">
                          <Link className="link-signup color-default" to="/register">Create Account</Link>
                          <Link className="link-signup color-default" to="/reset-password">Forgot Password</Link>
                        </div>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
            <FooterWhite />
          </div>
        </div>
      </>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { loginUser }
)(Login);