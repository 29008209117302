import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// reactstrap components
import {
  Container,
  Row,
  NavItem,
  Nav,
  NavLink,
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import { GApageView } from "../../shared/gaUtils";
import RoomDetail from "./RoomDetail";
import '../classroom/Classroom.css';
import constant from "../../shared/constant";

class Current extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      obData: null
    }
    if(this.props.location && this.props.location.state && this.props.location.state.obData)
    {  
      this.state.edit = true;
      this.state.obData = this.props.location.state.obData;
    }
    if(!this.props.location.state.obData || this.props.location.state.obData === null || this.props.location.state.obData === undefined) {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    document.body.classList.add("classroom-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Classroom");
    if (this.props.auth.user.district) {
      this.getTotalObservationsCount();
    } else {
      if (this.props.auth.user.role === constant['ROLE']['OBSERVER']) {
        this.getObservationList();
      }
    }
  }

  getTotalObservationsCount() {
    axios
      .post('/api/observations/districtObservationCount',
        {
          districtId: this.props.auth.user.district._id
        })
      .then((res) => {
        if (res.status === 200) {
          if (res.data >= this.props.auth.user.district.totalObservations) {
            this.props.history.push('/');
          }
        }
      });
  }

  getObservationList = () => {
    axios
      .post('/api/observations', { userId: this.props.auth.user.id })
      .then((res) => {
        if (res.data && res.data.length >= 1 && this.state.edit === false) {
          this.props.history.push('/');
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving observation data.');
        }
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("classroom-page");
    document.body.classList.remove("sidebar-collapse");
  }

  saveRoomDetail = (roomDetailData) => {
    const observationData = {
      ...roomDetailData,
      observer: this.props.auth.user.id,
    };
    this.saveObservationDataIntoDB(observationData);
  }

  saveObservationDataIntoDB = (obData) => {
    if(this.state.edit)
    {
      axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        localStorage.setItem(constant['OBSERVATION_ID'], res.data._id);
        this.props.history.push('/observe');
      }).catch((err) => {
        console.log(err);
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while saving observation data.');
        }
      });
    }
    else
    {
      axios
        .post('/api/observations/create', { obData })
        .then((res) => {
          localStorage.setItem(constant['OBSERVATION_ID'], res.data._id);
          this.props.history.push('/observe');
        }).catch((err) => {
          console.log(err);
          if (err.response) {
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while saving observation data.');
          }
        });
    }
  }

  render() {
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container>
                <Row>
                  <div className="mr-auto ml-auto col-lg-12">
                    <h3 className="title">Current Classroom</h3>
                    <div className="classroom">
                      <RoomDetail obData={this.state.obData} saveRoomDetail={this.saveRoomDetail}></RoomDetail>
                    </div>
                    <Nav className="row mt-5 features">
                      <div className="col-lg-1"></div>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon01-book.png") + ")",
                          }} />
                          <h4 className="info-title">LEARN</h4>
                          <p>Read Classroom180 and memorize the domains, and components. Under-stand the process of the Classroom180 Rubric. To purchase Classroom180, click here.</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools" tag={Link} to="/observe">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon02-Observe.png") + ")",
                          }} />
                          <h4 className="info-title">OBSERVE</h4>
                          <p>Go into the classroom and carefully observe the classroom and gather the evidence from an objective and unbiased perspective.</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2" >
                        <NavLink className="icon-tools" tag={Link} to="/consolidate">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon03-Consolidate.png") + ")",
                          }} />
                          <h4 className="info-title">CONSOLIDATE</h4>
                          <p>After the observation, consolidate the evidence by assigning your components and domains. Prepare for the debriefing step to come next.</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools" tag={Link} to="/debrief">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon04-Debrief.png") + ")",
                          }} />
                          <h4 className="info-title">DEBRIEF</h4>
                          <p>Meet with the teacher for a collaborative post-observation debriefing. Gather more evidence with the teacher and determine levels of performance</p>
                        </NavLink>
                      </NavItem>
                      <NavItem className="col-lg-2">
                        <NavLink className="icon-tools" tag={Link} to="/portfolio">
                          <div className="icon-image" style={{
                            backgroundImage: "url(" + require("assets/img/Icon05-Portfolio.png") + ")",
                          }} />
                          <h4 className="info-title">PORTFOLIO</h4>
                          <p>Find your finalized Classroom180 Rubrics along with the Teacher Reflection and Growth Plans in the Portfolio.</p>
                        </NavLink>
                      </NavItem>
                      <div className="col-lg-1"></div>
                    </Nav>
                  </div>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

Current.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Current);