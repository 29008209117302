import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import ReactDOM from 'react-dom';
// reactstrap components
import {
  Table,
  FormGroup,
  Label,
  Input,
  Row,
  CardBody,
  Card,
  Button,
  Modal,
  ModalFooter
} from "reactstrap";
import constant from "../../../shared/constant";
import _ from "lodash";
import Dropzone from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../debrief/Debrief.css';
class VisualIndicators extends Component {
  constructor(props) {
    super(props);

    this.onDrop = (files) => {
      if (files.length === 0) {
        return;
      }
      const file = Object.assign(files[0], { preview: URL.createObjectURL(files[0]) });
      this.setState(prevState => ({
        note: {
          ...prevState.note,
          image: file
        }
      }));
      this.setState({ files })
    };

    this.state = {
      errors: {},
      indicator: {},
      customizedIndicator: {},
      noteModal: false,
      note: {},
      nIndex: -1,
      indicators: [],
      file: null,
      inputState: true,
      components: []
    };
  }

  componentDidMount() {
    axios
      .get(`/api/components`)
      .then((res) => {
        this.setState({
          components: res.data
        })
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log("Please check your netwrok connection.");
        } else {
          console.log("Error occured while getting observation data.");
        }
      });
  }

  componentWillReceiveProps(props) {
    this.setState({ indicators: props.indicators });
  }

  onChangeNote = (e) => {
    const text = e.target.value;
    this.setState({
      note: text
    });
  }

  onChangeCustomizedIndicator = (e) => {
    let indicator = this.state.customizedIndicator;
    indicator.title = e.target.value;
    indicator.notes = null;
    indicator.type = constant['OBSERVATION_TYPE']['CUSTOM'];
    indicator.checked = true;
    indicator.component = null;
    this.setState({ customizedIndicator: indicator });
  }

  renderNotes = (indicator, modal = false) => {
    if (indicator.notes !== '') {
      return (
        <>
          <div className="note" style={{width: !modal ? 'inherit': '100%', overflow: modal ? 'hidden' : 'inherit'}}>
            <div className="d-flex align-items-center justify-content-between">
              <Label style={{ display: 'block', marginTop: '5px' }} id="edit-note">
                  <>
                    <span className="pl-3 text-wrap d-block">{indicator.notes}</span>
                  </>
              </Label>
            </div>
          </div>
        </>
      );
    }
  }

  renderVisualIndicators = (type) => {
    if (this.state.indicators === undefined) return (<></>);
    let indicators = this.state.indicators.filter((indicator) => { return indicator.type === type && (indicator.answer === 'Yes' || indicator.notes !== '')});
    let components = this.state.components.filter((component) => component.domain.index === type);
    return components.map(component => {
      return (
        <>
          <tr className="d-block" key={component._id} style={{borderBottom: 'solid 0.5px rgba(0,0,0,0.125)'}}>
            <td className="text-left" style={{ verticalAlign: 'initial' }}>
              <div style={{ textAlign: "center", backgroundColor: this.getBackgroundColor(type), width: '30px', height: '25px' }}>
                <span style={{ color: 'white', fontSize: '1.2em' }}>{component.index}</span>
              </div>
            </td>
            <td className="text-left" style={{fontSize: '1.2em'}}>
              {component.name}
            </td>
            <td className="text-right" style={{ verticalAlign: 'initial' }}>
            </td>
          </tr>
          {
            indicators.filter(indicator => indicator.components[0] === component._id).map((indicator) => {
              const { title, _id } = indicator;
              return (
                <>
                  <tr className="py-2 d-block" key={_id} style={{borderBottom: 'solid 0.5px rgba(0,0,0,0.125)'}}>
                    <td className="text-left py-1" style={{ verticalAlign: 'initial' }}>
                      <FormGroup check style={{ marginLeft: '5px' }}>
                        <Label check>
                          <Input checked={indicator.answer === "Yes" ? true : false} type="checkbox" disabled={true}></Input>
                          <span className="form-check-sign"></span>
                        </Label>
                      </FormGroup>
                    </td>
                    <td className="text-left py-1">
                      <h5 className="font-crm mb-1">
                        {title}
                      </h5>
                      {
                        this.renderNotes(indicator)
                      }
                    </td>
                    <td className="text-right py-1" style={{ verticalAlign: 'initial' }}>
                    </td>
                  </tr>
                </>
              );
            })
          }
        </>
      )
      
    })
    
  }

  getBackgroundColor = (type) => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === type) {
      return '#5bada3';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === type) {
      return '#158574';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === type) {
      return '#cd1f3d';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === type) {
      return '#136ba5';
    } else {
      return '#d6af20';
    }
  }

  getTableHeaderTitle = (type) => {
    if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === type) {
      return 'Relationships and Family Culture';
    } else if (constant['OBSERVATION_TYPE']['REGULATION'] === type) {
      return 'Regulation';
    } else if (constant['OBSERVATION_TYPE']['LANGUAGE'] === type) {
      return 'Language of Trauma';
    } else if (constant['OBSERVATION_TYPE']['SAFETY'] === type) {
      return 'Safety';
    } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === type) {
      return 'Discipline and Empowerment';
    } else {
      return 'Visual Indicators';
    }
  }

  render() {

    return (
      <>
        {!this.props.printPreview ? 
          <Row className="col-lg-12 p-0 m-0">
            <div className="col-lg-10 question-card p-0">
              <Card className='mt-3'>
                <CardBody>
                  <div className="px-2 text-left flex-fill mb-3" style={{fontWeight: 600, backgroundColor: "#dedede", fontSize: "22px"}}>Getting into the Shoes of Billy:<br/><span className="font-italic">Reflective Questions</span></div>
                  {[...Array(5)].map((_v, type) => 
                    <>
                      <div className="d-flex" style={{width: '90%'}}>
                        <div className="px-2 text-center mr-1" style={{backgroundColor: this.getBackgroundColor(type + 1), fontSize: "18px"}}>
                          <span style={{ color: 'white' }}>Domain {type + 1}</span>
                        </div>
                        <div className="px-2 text-left flex-fill" style={{fontWeight: 400, backgroundColor: "#dedede", fontSize: "18px"}}>{this.getTableHeaderTitle(type + 1)}</div>
                      </div>
                      <Table responsive style={{width: '90%'}}>
                        <tbody>
                          {
                            this.renderVisualIndicators(type + 1)
                          }
                        </tbody>
                      </Table>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </Row>
          :
          <div>
            <div className="px-2 text-left flex-fill font-italic mb-3" style={{fontWeight: 600, backgroundColor: "#dedede", fontSize: "22px"}}>Getting into the Shoes of Billy: Reflective Questions</div>
            {[...Array(5)].map((_v, type) => 
              <>
                <div className="d-flex" style={{width: '90%'}}>
                  <div className="px-2 text-center mr-1" style={{backgroundColor: this.getBackgroundColor(type + 1), fontSize: "18px"}}>
                    <span style={{ color: 'white' }}>Domain {type + 1}</span>
                  </div>
                  <div className="px-2 text-left flex-fill" style={{fontWeight: 600, backgroundColor: "#dedede", fontSize: "18px"}}>{this.getTableHeaderTitle(type + 1)}</div>
                </div>
                <Table responsive style={{width: '90%'}}>
                  <tbody>
                    {
                      this.renderVisualIndicators(type + 1)
                    }
                  </tbody>
                </Table>
              </>
            )}
          </div>
        }
      </>
    )
  }
}

VisualIndicators.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(VisualIndicators)
