import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import constant from "../../../shared/constant";
import { Button } from "reactstrap";
import VisualIndicators from "./VisualIndicators";

class ReflectiveQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: constant['OBSERVATION_TYPE']['RELATIONSHIP'],
      obData: {}
    };
  }

  onClickNext = (type, data) => {
    const { currentStep } = this.state;
    if (this.state.currentStep === constant['OBSERVATION_TYPE']['DISCIPLINE']) {
      this.setState({
        currentStep: 0,
      });
    } else {
      this.setState({
        currentStep: currentStep + (currentStep === constant['OBSERVATION_TYPE']['REGULATION'] ? 2 : 1)
      });
    }
    this.props.saveObservationData(type, data)
  }

  onClickStep = (e, step) => {
    e.preventDefault();
    if (this.state.currentStep !== step) {
      this.setState({ currentStep: step});
    }
  }
  
  updateIndicators = (indicators) => {
    this.props.saveObservationData(constant['DATA_SAVE_TYPE']['QUESTIONS'], indicators);
  }

  updateOtherObservation = (indicators) => {
    this.props.saveObservationData(constant['DATA_SAVE_TYPE']['INDICATORS'], indicators);
  }

  removeNote = (indicators, indIndex, noteIndex) => {
    this.props.removeNote(indicators, indIndex, noteIndex);
  }

  render() {
    const { currentStep } = this.state;
    return (
      <>
        <div className="mr-auto ml-auto col-md-12">
          <h4 className="mt-5">Enter Details for Reflective Questions</h4> 
          <div className="social-line social-line-white text-center">
            <div className="container"><div className="justify-content-center row">
                <Button  
                   onClick={(e) => this.onClickStep(e, constant['OBSERVATION_TYPE']['RELATIONSHIP'])} 
                   className={`btn btn-step font-crm ${currentStep === constant['OBSERVATION_TYPE']['RELATIONSHIP'] ? 'color-domain-1' : 'color-non-active'}`}>
                   {constant['OBSERVATION_TYPE']['RELATIONSHIP']}
                </Button> 
                <div className="arrow-container">
                  <span className="arrow">&#8594;</span> 
                </div>
                <Button 
                   onClick={(e) => this.onClickStep(e, constant['OBSERVATION_TYPE']['REGULATION'])} 
                   className={`btn btn-step font-crm ${currentStep === constant['OBSERVATION_TYPE']['REGULATION'] ? 'color-domain-2' : 'color-non-active'}`}>
                   {constant['OBSERVATION_TYPE']['REGULATION']}
                </Button> 
                <div className="arrow-container">
                  <span className="arrow">&#8594;</span> 
                </div>
                <Button 
                   onClick={(e) => this.onClickStep(e, constant['OBSERVATION_TYPE']['LANGUAGE'])} 
                   className={`btn btn-step font-crm ${currentStep === constant['OBSERVATION_TYPE']['LANGUAGE'] ? 'color-domain-3' : 'color-non-active'}`}>
                   {constant['OBSERVATION_TYPE']['LANGUAGE']}
                </Button> 
                <div className="arrow-container">
                  <span className="arrow">&#8594;</span> 
                </div>
                <Button 
                   onClick={(e) => this.onClickStep(e, constant['OBSERVATION_TYPE']['SAFETY'])} 
                   className={`btn btn-step font-crm ${currentStep === constant['OBSERVATION_TYPE']['SAFETY'] ? 'color-domain-4' : 'color-non-active'}`}>
                   {constant['OBSERVATION_TYPE']['SAFETY']}
                </Button>
                <div className="arrow-container">
                  <span className="arrow">&#8594;</span> 
                </div>
                <Button
                   onClick={(e) => this.onClickStep(e, constant['OBSERVATION_TYPE']['DISCIPLINE'])} 
                   className={`btn btn-step font-crm ${currentStep === constant['OBSERVATION_TYPE']['DISCIPLINE'] ? 'color-domain-5' : 'color-non-active'}`}>
                   {constant['OBSERVATION_TYPE']['DISCIPLINE']}
                </Button>
              </div>
            </div>
          </div>
            <VisualIndicators 
              status={this.props.obData.status}
              type={this.state.currentStep} 
              observer={this.props.obData.observer} 
              indicators={this.props.obData.questions} 
              onClickNext={this.onClickNext} 
              removeNote={this.removeNote}
              updateIndicators={this.updateIndicators}
              updateOtherObservation={this.updateOtherObservation}
              otherObservations={this.props.obData.indicators}
              />
        </div>
      </>
    );
  }
}

ReflectiveQuestion.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(ReflectiveQuestion)
