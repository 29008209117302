import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import ModalImage from "react-modal-image";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint"

// reactstrap components
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  Col,
  Label,
  Row
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/shared/DropdownScrollNavbar.js";
import Footer from "components/shared/Footer.js";
import { GApageView } from "../../shared/gaUtils";
import constant from '../../shared/constant';
import '../consolidate/Consolidate.css';
import html2pdf from "html2pdf.js";                                          

class Consolidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      obData: {},
      components: [],
      domains: [],
      filteredComponents: [],
      componentsModal: false,
      dataWillSaved: {},
      obDataList: [],
      printPreview: false
    }
  }

  componentDidMount() {
    document.body.classList.add("consolidate-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Consolidate");
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    if (observationId !== null) {
      this.loadCurrentObserveData(observationId);
      this.getComponents();
      this.getDomains();
    } else {
      toast.info("Please select a classroom to observe or start new");
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("consolidate-page");
    document.body.classList.remove("sidebar-collapse");
  }

  loadCurrentObserveData = (observationId) => {
    axios
      .get(`/api/observations/${observationId}`)
      .then((res) => {
        if (res.data.indicators.length !== 0) {
          this.setState({ obData: res.data });
        } else {
          // toast.info("Please select at least an indicator to consolidate");
          this.props.history.push('/observe');
        }
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while getting observation data.');
        }
        // this.props.history.goBack();
      });
  }

  getComponents = () => {
    axios
      .get('/api/components')
      .then((res) => {
        if (res.data) {
          this.setState({ components: res.data });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving components.');
        }
      });
  }

  getDomains = () => {
    axios
      .get('/api/domains')
      .then((res) => {
        if (res.data) {
          const domains = res.data.sort((a, b) => { return a.index - b.index });
          this.setState({ domains: domains });
        }
      }).catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while retrieving domains.');
        }
      });
  }

  removeComponent = (data, component) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    if (data.type === constant['OBSERVATION_TYPE']['ACTION'] || data.type === constant['OBSERVATION_TYPE']['CUSTOM']) {
      const components = data.components.filter((com) => com._id !== component._id);
      data.components = components;
      const obData = this.state.obData;
      if (data.type === constant['OBSERVATION_TYPE']['ACTION']) {
        const dataArray = this.state.obData.actions.map((action) => {
          return action._id === data._id ? data : action
        });
        obData.actions = dataArray;
      } else if (data.type === constant['OBSERVATION_TYPE']['CUSTOM']) {
        const dataArray = this.state.obData.indicators.map((indicator) => {
          return indicator._id === data._id ? data : indicator
        });
        obData.indicators = dataArray;
      }
      axios
        .put(`/api/observations/${obData._id}`, { obData })
        .then((res) => {
          this.loadCurrentObserveData(localStorage.getItem(constant['OBSERVATION_ID']));
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while updating observation data.');
          }
        }); 
    }
  }

  disableUserAccess = () => {
    if (this.props.auth.user.role === constant['ROLE']['DISTRICT_ADMIN'] ||
      this.props.auth.user.role === constant['ROLE']['TEACHER']) {
      return true;
    } else if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      if (this.state.obData && this.state.obData.observer && (this.props.auth.user.id !== this.state.obData.observer._id)) {
        return true;
      }
    }
    return false;
  }

  onClickTBD = (data) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    data.components = [];
    const obData = this.state.obData;
    if (data.type === constant['OBSERVATION_TYPE']['ACTION']) {
      const dataArray = this.state.obData.actions.map((action) => {
        return action._id === data._id ? data : action
      });
      obData.actions = dataArray;
    } else if (data.type === constant['OBSERVATION_TYPE']['CUSTOM']) {
      const dataArray = this.state.obData.indicators.map((indicator) => {
        return indicator._id === data._id ? data : indicator
      });
      obData.indicators = dataArray;
    }
    axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        this.setState((prevState) => {
          return ({
            ...prevState,
            indicators: res.data.indicators
          })
        })
      }).catch((err) => {
        if (err.response) {
          console.log(err.response);
          this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while updating observation data.');
        }
      });
  }

  onClickDomain = (domain, data) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    if (data.type === constant['OBSERVATION_TYPE']['ACTION'] || data.type === constant['OBSERVATION_TYPE']['CUSTOM']) {
      const filteredComponents = this.state.components.filter((component) => { return component.domain._id === domain._id });
      this.setState({ filteredComponents: filteredComponents, componentsModal: true, dataWillSaved: data });
    }
  }

  onClickComponent = (component) => {
    if (this.state.printPreview) return;
    if (this.disableUserAccess()) return;
    const dataWillSaved = this.state.dataWillSaved;
    const componentFound = dataWillSaved.components.find((comp) => comp.index === component.index);
    if (componentFound) {
      toast.error('This component was already assigned to this indicator or action');
      this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
      return;
    }
    dataWillSaved.components.push(component._id);
    const obData = this.state.obData;
    if (dataWillSaved.type === constant['OBSERVATION_TYPE']['ACTION']) {
      const dataArray = this.state.obData.actions.map((action) => {
        return action._id === dataWillSaved._id ? dataWillSaved : action
      });
      obData.actions = dataArray;

    } else if (dataWillSaved === constant['OBSERVATION_TYPE']['CUSTOM']) {
      const dataArray = this.state.obData.indicators.map((indicator) => {
        return indicator._id === dataWillSaved._id ? dataWillSaved : indicator
      });
      obData.indicators = dataArray;
    }

    axios
      .put(`/api/observations/${obData._id}`, { obData })
      .then((res) => {
        this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
        this.loadCurrentObserveData(localStorage.getItem(constant['OBSERVATION_ID']));
      }).catch((err) => {
        this.setState({ dataWillSaved: {}, componentsModal: false, filteredComponents: [] });
        if (err.response) {
          console.log(err.response);
          toast.error(err.response.data.message);
        } else if (err.request) {
          toast.error('Please check your netwrok connection.');
        } else {
          toast.error(err.message || 'Error occured while updating observation data.');
        }
      });
  }

  renderDomains = (data, defaultIndicator) => { 
    return this.state.domains.map((domain) => {
      let selected = '';
      data.components.forEach((component) => {
        let tmpComponent = this.state.components.filter(component1 => component1._id === component);
        if (tmpComponent.length > 0 && tmpComponent[0].domain._id === domain._id)
          selected = 'selected';
      });
      const { index } = domain;
      return (
        <>  
          <Button
            disabled={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
            onClick={(e) => { e.preventDefault(); this.onClickDomain(domain, data) }}
            className={`domain-mark domain-mark-${index} domain-mark-${index}-${selected}`} 
            style={{ 
              pointerEvents: 
              this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? 'none' : (defaultIndicator || this.state.printPreview) ? 'none' : '' 
            }} 
          >
            {index}
          </Button>
        </>
      );
    });
  }

  getComponentColor = (component) => {
    let tmpComponent = this.state.components.filter(component1 => component1._id === component);
    // const domain = this.state.domains.find((domain) => domain._id === component.domain);
    if (tmpComponent.length > 0) {
      if (constant['OBSERVATION_TYPE']['RELATIONSHIP'] === tmpComponent[0].domain.index) {
        return 'color-domain-1';
      } else if (constant['OBSERVATION_TYPE']['REGULATION'] === tmpComponent[0].domain.index) {
        return 'color-domain-2';
      } else if (constant['OBSERVATION_TYPE']['SAFETY'] === tmpComponent[0].domain.index) {
        return 'color-domain-4';
      } else if (constant['OBSERVATION_TYPE']['DISCIPLINE'] === tmpComponent[0].domain.index) {
        return 'color-domain-5';
      } else {
        return 'color-domain-3';
      }
    } else {
      return 'color-grey-default';
    }
  }

  displayComponents = (data) => {
    if (data.components == null || data.components.length === 0) {
      return;
    }
    const components = data.components.sort((comA, comB) => {
      return (comA.index < comB.index) ? -1 : (comA.index > comB.index) ? 1 : 0;
    })
    return components.map((component) => {
      let tmpComponent = this.state.components.filter(component1 => component1._id === component);
      return (<div className={`ml-2 circle-badge ${this.getComponentColor(component)}`} onClick={(e) => { this.removeComponent(data, component); }}>{tmpComponent.length > 0 && tmpComponent[0].index}</div>);
    });
  };

  printPdf = () => {
    const element = document.getElementById('observe-report');
    let clonedElement = element.cloneNode(true);
    const opt = {
      margin: [0.5, 0, 0.5, 0.5],
      filename: 'consolidate.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true
      },
      jsPDF: { orientation: 'landscape', unit: 'cm', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  getPdf = () => {
    this.setState({ printPreview: true });
  }

  renderActions = () => {
    const actions = this.state.obData.actions.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    return actions.map((action, index) => {
      const { teacher, student, _id, teacherFirst } = action;
      return (
        <>
          <tr key={_id}>
            <td className="text-left" style={{ verticalAlign: 'initial' }}>
              <span style={{ marginLeft: '8px' }} className="font-crm">
                {index + 1}
              </span>
            </td>
            <td className="text-left">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  { 
                    teacher && !teacherFirst 
                    ? <div>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-crm">Student: <span className="font-italic">{student}</span></label>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-crm">Teacher: <span className="font-italic">{teacher}</span></label>
                    </div>
                    : <div>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-crm">Teacher: <span className="font-italic">{teacher}</span></label>
                        <label style={{ marginLeft: '5px', display: 'block'}} className="font-crm">Student: <span className="font-italic">{student}</span></label>
                    </div>
                  }
                  {

                  }
                </div>
                <div style={{minWidth: '20%'}}> 
                  {action.components.length > 0 &&
                    this.displayComponents(action)
                  }
                </div>
              </div>
            </td>
            <td className="text-right actions domain" style={{ verticalAlign: 'initial' }}>
              {
                this.renderDomains(action)
              }
              <Button
                disabled={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                onClick={(e) => { e.preventDefault(); this.onClickTBD(action) }}
                style={{ pointerEvents: 
                  this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? 'none' : this.state.printPreview ? 'none' : '', backgroundColor: action.components.length > 0 ? '#888' : '#648292' }}
                className="domain-mark">
                N/A
              </Button>
            </td>
          </tr>
        </>
      );
    });
  }

  renderNotes = (indicator) => {
    if (indicator.notes && indicator.notes.length > 0) {
      return indicator.notes.map((note, nIndex) => {
        return (
          <>
            <Label style={{ display: 'block', marginTop: '5px' }} id="edit-note">
              {note.text &&
                <>
              -  {note.text}
                </>
              }
            </Label>
            {note.image &&
              <div style={{maxWidth : '50px'}}>
                <ModalImage
                  small={note.image}
                  large={note.image}
                  alt="image"
                />
              </div>
            }
          </>
        );
      });
    }
  }

  renderVisualIndicators = () => {
    const vIndicators = this.state.obData.indicators.filter((indicator) => {
      return indicator.type !== constant['OBSERVATION_TYPE']['CUSTOM'] &&
        indicator.type !== constant['OBSERVATION_TYPE']['STANDARD'] &&
        indicator.checked === true
    });
    return vIndicators.map((indicator, index) => {
      const { title, _id } = indicator;
      return (
        <tr key={_id}>
          <td className="text-left" style={{ verticalAlign: 'initial' }}>
            <span style={{ marginLeft: '8px' }} className="font-crm">
              {index + 1}
            </span>
          </td>
          <td className="text-left">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label style={{ marginLeft: '5px', maxWidth: '76%' }} className="font-crm">{title}</label>
              {indicator.components.length > 0 &&
                this.displayComponents(indicator)
              }
            </div>
            {
              this.renderNotes(indicator)
            }
          </td>
          <td className="text-right actions domain" style={{ verticalAlign: 'initial' }}>
            {
              this.renderDomains(indicator, true)
            }
            <Button
              className="domain-mark color-darker-grey n-a-btn" style={{ pointerEvents: 'none' }}>
              N/A
            </Button>
          </td>
        </tr>
      );
    });
  }

  renderCustomizedIndicators = () => {

    const cIndicators = this.state.obData.indicators.filter((indicator) => { return indicator.type === constant['OBSERVATION_TYPE']['CUSTOM'] && indicator.checked });
    return cIndicators.map((indicator, index) => {
      const { title, _id } = indicator;
      return (
        <>
          <tr key={_id}>
            <td className="text-left" style={{ verticalAlign: 'initial' }}>
              <span style={{ marginLeft: '8px' }} className="font-crm">
                {index + 1}
              </span>
            </td>
            <td className="text-left">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ marginLeft: '5px', maxWidth: '76%' }} className="font-crm">{title}</label>
                {indicator.components.length > 0 &&
                  this.displayComponents(indicator)
                }
              </div>
              {
                this.renderNotes(indicator)
              }
            </td>
            <td className="text-right actions domain" style={{ verticalAlign: 'initial' }}>
              {
                this.renderDomains(indicator)
              }
              <Button
                disabled={this.state.obData.status === constant['OBSERVATION_STATUS']['COMPLETED'] ? true : false}
                onClick={(e) => { e.preventDefault(); this.onClickTBD(indicator) }}
                className="domain-mark" style={{ pointerEvents: this.state.printPreview ? 'none' : '', backgroundColor: indicator.components.length > 0 ? '#888' : '#648292' }}>
                N/A
              </Button>
            </td>
          </tr>
        </>
      );
    });
  }

  renderComponents = () => {
    return this.state.filteredComponents.map((component) => {
      return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
          <a className={`${component.domain.index === 1 ? " color-font-1" : ""} 
                ${component.domain.index === 2 ? " color-font-2" : ""} 
                ${component.domain.index === 3 ? " color-font-3" : ""} 
                ${component.domain.index === 4 ? " color-font-4" : ""} 
                ${component.domain.index === 5 ? " color-font-5" : ""}`}
            onClick={(e) => { e.preventDefault(); this.onClickComponent(component) }}
            href="#pablo"
            style={{ display: "block", color: '#158574' }}
            rel="noopener noreferrer"
            target="_blank">
            {component.index}.{component.name}
          </a>
        </div>
      )
    })
  }

  colorsPerDomains = () => {
    return '#black';
  }

  render() {
    const pageStyle = `
      @page {
        margin: 2cm 2cm 1cm 2cm;
      }
      @media print {
        body {
          margin: 0;
          padding: 0;
        }
      }
      table {
        page-break-inside: avoid;
      }
    `;
    return (
      <>
        <DropdownScrollNavbar location={this.props.location} observer={this.state.obData && this.state.obData.observer && this.state.obData.observer._id} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <Container className="session-bar">
                <Row className="padding-lg default-back-color text-white title-container">
                  <span style={{ marginRight: '60px' }}>Teacher: {this.state.obData.teacher}</span>
                  <span style={{ marginRight: '60px' }}>Grade: {this.state.obData.grade}</span>
                  <span style={{ marginRight: '60px' }}>Lesson: {this.state.obData.topic}</span>
                  <span style={{ marginRight: '60px' }}>Created: {moment(this.state.obData.date).format('MM/DD/YYYY h:mm a')}</span>
                  <span style={{ marginRight: '30px' }}>Last Modified: {moment(this.state.obData.dateModified).format('MM/DD/YYYY h:mm a')}</span>
                  {/* <Button className="default-back-color btn-print hide-printing" type="button" onClick={() => window.print()}>
                        <img src={require("assets/img/Icon-Print.png")} alt="print" />
                      </Button> */}
                  <ReactToPrint
                    trigger={() => {
                      return <Button className="default-back-color btn-print hide-printing" type="button">
                        <img src={require("assets/img/Icon-Print.png")} alt="print" />
                      </Button>
                    }}
                    content = {() => this.componentRef}
                    pageStyle={pageStyle}
                  >

                  </ReactToPrint>
                </Row>
              </Container>
              <div style={{display: "none"}}>
                <ComponentToPrint ref={el => (this.componentRef = el)} />
              </div>
              <Container className="board">
                <Card className='mt-3'>
                  <CardBody>
                    <Table responsive className="consolidate-table">
                      <thead>
                        <tr>
                          <th className="text-left" style={{ width: '30px' }}>
                            <div className="order-mark">
                            </div>
                          </th>
                          <th className="text-left" colSpan="2">
                            <h6 className="grey-back-color padding-sm align-items">
                              <span>Room Scan Evidence</span>
                              <span>Domains/Components</span>
                            </h6>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.obData.indicators && this.renderVisualIndicators()
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
                <Card className='mt-3'>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left" style={{ width: '30px' }}>
                            <div className="order-mark">
                            </div>
                          </th>
                          <th className="text-left" colSpan="2"><h6 className="grey-back-color padding-sm align-items"><span>Other Observations</span><span>Domains/Components</span></h6></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.obData.indicators && this.renderCustomizedIndicators()
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
                <Card className='mt-3'>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left" style={{ width: '30px' }}>
                            <div className="order-mark">
                            </div>
                          </th>
                          <th className="text-left" colSpan="2"><h6 className="grey-back-color padding-sm align-items"><span>Teacher/Student Actions</span><span>Domains/Components</span></h6></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.obData.actions && this.renderActions()
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Container>
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          modalClassName="modal-components"
          isOpen={this.state.componentsModal}
          toggle={() => this.setState({ componentsModal: false })}
        >
          <div className="modal-title">
            {this.state.filteredComponents[0] &&
              <h5 className={`${this.state.filteredComponents[0].domain.index === 1 ? " color-domain-1" : ""} 
            ${this.state.filteredComponents[0].domain.index === 2 ? " color-domain-2" : ""} 
            ${this.state.filteredComponents[0].domain.index === 3 ? " color-domain-3" : ""} 
            ${this.state.filteredComponents[0].domain.index === 4 ? " color-domain-4" : ""} 
            ${this.state.filteredComponents[0].domain.index === 5 ? " color-domain-5" : ""}`}>
                {this.state.filteredComponents[0].domain.name}
              </h5>
            }
          </div>
          <div className="modal-body just">
            <Col>
              {
                this.renderComponents()
              }
            </Col>
          </div>
        </Modal>
      </>
    );
  }
}

Consolidate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Consolidate);