import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import Select from "react-select";

// reactstrap components
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  FormGroup,
  Card,
  CardBody,
  Modal,
  ModalFooter,
  ButtonGroup
} from "reactstrap";

import moment from "moment";
import constant from "../../shared/constant";
import '../classroom/Classroom.css';
import CardFooter from "reactstrap/lib/CardFooter";
import CardHeader from "reactstrap/lib/CardHeader";
import ReactDOM from 'react-dom';
import { ToastContainer, toast } from 'react-toastify';
import { find } from "lodash";

class RoomDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {

      date: moment().format('MM/DD/YYYY'),
      time: moment().format('HH:mma'),
      teacher: '',
      grade: null,
      topic: '',
      school: null,
      district: null,
      errors: {},
      specialGradeModal: false,
      grades: [{ value: 'P', label: 'P' }, { value: 'K', label: 'K' }, { value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }, { value: '6', label: '6' }, { value: '7', label: '7' }, { value: '8', label: '8' }, { value: '9', label: '9' }, { value: '10', label: '10' }, { value: '11', label: '11' }, { value: '12', label: '12' }, { value: 'Other', label: 'Other' }],
      totalDistricts: [],
      districts: [],
      schools: [],
      editMode: 'district',
      districtModal: false,
      modalDistrict: {
        name: '',
        emails: [],
        schools: [],
        totalObservations: 1,
      },
      schoolName: '',
      schoolEmail: '',
      email: '',
      isSchoolUser: localStorage.getItem('isSchoolUser'),
      schoolList: [],
      userSchool: 'none',
      currentDistrict: null
    };
    if(this.props.obData)
    {
      this.state.teacher = this.props.obData.teacher;
      this.state.grade = this.props.obData.grade;
      this.state.topic = this.props.obData.topic;
    }
    else
    {
      this.state.grade = "Select";
      this.state.district = "Select";
      this.state.school = "Select";
    }
  }
  
  componentDidMount() {
    axios
      .get('/api/districts')
      .then((res) => {
        if (res.status === 200) {
          if (this.state.isSchoolUser === 'true') {
            let tempSchoolList = [];
            if (res.data.length > 0) {
              res.data.forEach(district => {
                let flag = 0;
                if (district.schools.length > 0) {
                  district.schools.forEach(school => {
                    if (school.users.length > 0) {
                      if (school.users.filter(user => user.email.toLowerCase() === this.props.auth.user.email).length > 0) {
                        flag = 1;
                      }
                    }
                  })
                }
                if (flag === 1) {
                  let temp = [...tempSchoolList];
                  tempSchoolList = temp.concat(district.schools);
                  this.setState({
                    currentDistrict: district
                  })
                }
              })
            }
            if(this.props.obData !== null) {
              this.setState({ userSchool: tempSchoolList.filter(school => school._id === this.props.obData.school)[0] })
            }
            let tempList = tempSchoolList.filter((item) => item.archived === false ||item.archived===undefined).sort((a, b) => a.name > b.name ? 1 : -1)
            this.setState({schoolList: tempList})
          } else {
            let tempDistricts = [{value: "Add New", label: "Add New"}];
            let tempSchools = [];
            res.data.forEach(item => {
              tempDistricts.push({
                value: item._id,
                label: item.name
              })
              item.schools.forEach(school => {
                tempSchools.push({
                  value: school._id.toString(),
                  label: school.name
                })
              })
            })
            if(this.props.obData) {
              if(this.props.obData.district ) {
                if(this.props.obData.district._id){
                  this.setState({
                    district: tempDistricts.filter(d => d.value === this.props.obData.district._id)[0]
                  })
                } else {
                  this.setState({
                    district: tempDistricts.filter(d => d.value === this.props.obData.district)[0]
                  })
                }
              } else {
                this.state.district = "Select";
              }
              if(this.props.obData.school) {
                this.setState({
                  school: tempSchools.filter(d => d.value === this.props.obData.school)[0]
                })
              } else {
                this.state.school = "Select";
              }
            }
            this.setState({
              totalDistricts: res.data,
              districts: tempDistricts,
              schools: tempSchools
            })
          }
        }
      });
    if (this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN']) {
      const school = find(this.props.auth.user.district.schools, { _id: this.props.auth.user.school });
      this.setState({ school: school });
    }
  }

  onChange = e => {
    if (e.target.id === 'teacher' || e.target.id === 'observer' || e.target.id === 'topic') {
      if (e.target.value.length === 1) {
        let str = e.target.value.toUpperCase(); // First Letter Uppercase
        this.setState({ [e.target.id]: str });
        return;
      }
      let str = e.target.value;
      let char = str[str.length - 2];
      if (char === ' ') { // After space, add captialize
        let upperChar = str[str.length - 1].toUpperCase();
        str = str.substring(0, str.length - 1);
        str += upperChar;
        this.setState({ [e.target.id]: str });
        return;
      }
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  onModalChange = (e) => {
    if (e.target.id === 'schoolName' || e.target.id === 'schoolEmail' || e.target.id === 'email') {
      this.setState({ [e.target.id]: e.target.value });
    } else {
      let districtObj = this.state.modalDistrict;
      districtObj[e.target.id] = e.target.value;
      this.setState({ modalDistrict: districtObj });
    }
  }

  saveDistrict(district, extra) {
    if(this.state.editMode === 'district') {
      axios
        .post('/api/districts', { district })
        .then((res) => {
          if (res.status === 200) {
            toast.info("Successfully Created the District/School Admins.");
            let tempDist = {value: res.data._id, label: res.data.name}
            let tempTotalDistricts = [...this.state.totalDistricts];
            tempTotalDistricts.push(res.data);
            let tempDistricts = [...this.state.districts];
            let tempSchools = [{value: "Add New", label: "Add New"}];
            tempDistricts.push(tempDist);
            res.data.schools.forEach(school => {
              tempSchools.push({
                value: school._id.toString(),
                label: school.name
              })
            })
            this.setState({
              district: tempDist,
              districtModal: false,
              modalDistrict: {
                name: '',
                emails: [],
                schools: [],
                totalObservations: 1,
              },
              totalDistricts: tempTotalDistricts,
              schools: tempSchools,
              districts: tempDistricts,
            })
          } else {
            toast.info(res.data.message);
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while creating District data.');
          }
        });
    } else {
      axios
        .put(`/api/districts/${district._id}`, { district })
        .then((res) => {
          if (res.status === 200) {
            toast.info("Successfully Created the District/School Admins.");
            let tempDist = {value: res.data._id, label: res.data.name}
            let tempTotalDistricts = [...this.state.totalDistricts];
            tempTotalDistricts.push(res.data);
            let tempDistricts = [...this.state.districts];
            let tempSchools = [{value: "Add New", label: "Add New"}];
            tempDistricts.push(tempDist);
            res.data.schools.forEach(school => {
              tempSchools.push({
                value: school._id.toString(),
                label: school.name
              })
            })
            this.setState({
              district: tempDist,
              districtModal: false,
              modalDistrict: {
                name: '',
                emails: [],
                schools: [],
                totalObservations: 1,
              },
              totalDistricts: tempTotalDistricts,
              schools: tempSchools,
              districts: tempDistricts,
            })
          } else {
            toast.info(res.data.message);
          }
        }).catch((err) => {
          if (err.response) {
            console.log(err.response);
            toast.error(err.response.data.message);
          } else if (err.request) {
            toast.error('Please check your netwrok connection.');
          } else {
            toast.error(err.message || 'Error occured while creating District data.');
          }
        });
    }
  }

  addDistrictData = () => {
    if (this.state.email === '') {
      toast.info('District Email can not be empty');
      return;
    }
    let emails = this.state.modalDistrict.emails;
    emails.push(this.state.email);
    this.setState({ email: '' });
  }

  renderDistrictAdminEmails = () => {
    return this.state.modalDistrict.emails.map((email, index) => {
      return (
        <>
          <Row style={{ alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}>
            <>
              {this.state.editMode === 'district' && <Button
                className="btn-icon btn-neutral btm btn-google"
                onClick={() => { this.removeDistrictAdminEmail(email, index) }}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </Button>}
            </>
            <Row style={{ marginLeft: '10px' }}>
              <Col>
                <span className="font-crm">{email}</span>
              </Col>
            </Row>
          </Row>
        </>
      )
    });
  }

  removeDistrictAdminEmail = (email, index) => {
    let emails = this.state.modalDistrict.emails;
    emails.splice(index, 1);
    this.setState({ emails: emails }, () => {
      if (this.state.type === 'edit') {
        let district = {
          ...this.state.district,
          emails: this.state.modalDistrict.emails,
        }
        this.saveDistrict(district, { method: 'DELETE_DISTRICT', email: email });
      }
    });
  }

  removeSchoolData = (school, index) => {
    let schools = this.state.modalDistrict.schools;
    schools.splice(index, 1);
    this.setState({ schools: schools }, () => {
      if (this.state.type === 'edit') {
        let district = {
          ...this.state.district,
          schools: this.state.modalDistrict.schools,
        }
        this.saveDistrict(district, { method: 'DELETE_SCHOOL', school: school });
      }
    });
  }

  renderSchoolList = () => {
    return this.state.modalDistrict.schools.map((school, index) => {
      return (
        <>
          <Row style={{ alignItems: 'center', marginLeft: '10px' }}>
            <>
              {this.state.editMode === 'district' && <Button
                className="btn-icon btn-neutral btm btn-google mr-1"
                onClick={() => { this.removeSchoolData(school, index) }}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </Button>}
            </>
            <Row style={{ marginLeft: '10px', width: '90%' }}>
              <Col lg={6}>
                <span className="font-crm">{school.name}</span>
              </Col>
              <Col lg={6}>
                <span className="font-crm">{school.email}</span>
              </Col>
            </Row>
          </Row>
        </>
      )
    });
  }

  addSchoolData = () => {
    if (this.state.schoolName === '') {
      toast.info('School Name can not be empty');
      return;
    }
    let schools = this.state.modalDistrict.schools;
    schools.push({
      name: this.state.schoolName,
      email: this.state.schoolEmail
    });
    this.setState({ schoolName: '', schoolEmail: '' });
  }

  handleSchoolChange(v) {
    this.setState({ userSchool: v });
  }

  handleGradeChange(grade) {
    if (grade.value === 'Other') {
      this.setState({ specialGradeModal: true, grade: { value: '', label: '' } }, () => {
        setTimeout(() => {
          ReactDOM.findDOMNode(this.refs.gradeInput).focus();
        }, 1)
      });
    } else {
      this.setState({ grade: grade });
    }
  }

  handleDistrictChange(district) {
    if(district.label !== "Add New") {
      let tempSchools = [{value: "Add New", label: "Add New"}];
      this.state.totalDistricts.filter(d => d._id === district.value)[0].schools.forEach(school => {
        tempSchools.push({
          value: school._id.toString(),
          label: school.name
        })
      })
      this.setState({ 
        district: district,
        schools: tempSchools
      });
    } else {
      this.setState({
        modalDistrict: {
          name: '',
          emails: [],
          schools: [],
          totalObservations: 1,
        },
        editMode: 'district',
        districtModal: true,
      })
    }
  }

  onSchoolChange(school) {
    if(school.label !== "Add New") {
      this.setState({school: school})
    } else {
      if(this.state.district !== 'Select') {
        let tempModalDistrict = this.state.totalDistricts.filter(dist => dist._id.toString() === this.state.district.value)[0];
        this.setState({
          modalDistrict: tempModalDistrict,
          editMode: 'school',
          districtModal: true
        })
      }
    }
    
  }

  onChangeSpeicalGrade = (e) => {
    this.setState({ grade: { label: e.target.value, value: e.target.value } });
  }

  onKeyPress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      if (this.state.grade.value === '') {
        toast.info('Please select grade before start observation.');
      } else {
        this.setState({ specialGradeModal: false });
      }
    }
  }

  onSubmit = e => {
    e.preventDefault();
    if((this.props.auth.user.role !== constant['ROLE']['OBSERVER'] || (this.props.auth.user.role === constant['ROLE']['OBSERVER'] && this.props.auth.user.freeUser === false))) {
      if (!this.state.school &&
        this.props.auth.user.district &&
        this.props.auth.user.role === constant['ROLE']['OBSERVER']) {
        toast.info('Please select school before start observation.');
        return;
      }
      if (this.state.district === "Select" || this.state.district === null) {
        toast.info('Please select district before start observation.');
        return;
      }
      if (this.state.school === "Select" || this.state.school === null) {
        toast.info('Please select school before start observation.');
        return;
      }
    }
    if (this.state.userSchool === "none" && this.state.isSchoolUser === 'true') {
      toast.info('Please select school before start observation.');
      return;
    }
    if (this.state.grade === "Select" || this.state.grade === null) {
      toast.info('Please select grade before start observation.');
      return;
    }
    if (this.state.teacher === '')
    {
      toast.info("Please write teacher's Last Name.");
      return;
    }
    if (this.state.topic === '')
    {
      toast.info("Please write Topic of Lesson.");
      return;
    }
    const roomDetailData = {
      time: this.state.time,
      teacher: this.state.teacher,
      grade: this.state.grade.value,
      topic: this.state.topic,
      school: this.state.isSchoolUser === 'true' ? this.state.userSchool._id : this.state.school.value,
      district: this.state.isSchoolUser === 'false' ? this.state.district.value : this.state.currentDistrict._id
    }
    if(this.props.obData) {
      roomDetailData._id = this.props.obData._id;
    }
    this.props.saveRoomDetail(roomDetailData, this.state.schoolList);
  }

  saveDistrictData = () => {
    if (this.state.modalDistrict.name === '') {
      toast.info('District Name can not be empty');
      return;
    }
    let district = {...this.state.modalDistrict}
    this.saveDistrict(district);
  }

  render() {
    const { errors } = this.state;
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="ml-auto mr-auto col-md-10 col-lg-8">
          <Card className='mt-3'>
            <CardBody>
              <Form noValidate action="" className="form mt-4" method="" onSubmit={this.onSubmit}>
                {this.state.isSchoolUser === 'true' &&
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label>School</label>
                        <Select
                          className="react-select react-select-info"
                          classNamePrefix="react-select"
                          placeholder="Select School"
                          getOptionValue={(school) => school._id}
                          getOptionLabel={(school) => school.name}
                          onChange={value => this.handleSchoolChange(value)}
                          options={this.state.schoolList}
                          value={this.state.userSchool}
                          isDisabled={this.props.auth.user.role === constant['ROLE']['SCHOOL_ADMIN'] ? true : false}
                        ></Select>
                        <span className="red-text">
                          {errors.employee}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label>Observer</label>
                      <Input
                        id="observer"
                        type="text"
                        readOnly
                        value={this.props.auth.user.name}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label>Grade</label>
                      <Select
                        className="react-select react-select-info"
                        classNamePrefix="react-select"
                        placeholder={this.state.grade}
                        getOptionValue={(grade) => grade.label}
                        getOptionLabel={(grade) => grade.value}
                        onChange={value => this.handleGradeChange(value)}
                        options={this.state.grades}
                        value={this.state.grade}
                      ></Select>
                      <span className="red-text">
                        {errors.grade}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label>Teacher</label>
                      <Input
                        id="teacher"
                        placeholder="Name"
                        onChange={this.onChange}
                        type="text"
                        error={errors.teacher}
                        value={this.state.teacher}
                      ></Input>
                    </FormGroup>
                    <span className="red-text">
                      {errors.teacher}
                    </span>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label>Topic of Lesson</label>
                      <Input
                        id="topic"
                        placeholder="Topic here"
                        onChange={this.onChange}
                        error={errors.topic}
                        type="text"
                        value={this.state.topic}
                      ></Input>
                    </FormGroup>
                    <span className="red-text">
                      {errors.topic}
                    </span>
                  </Col>
                </Row>
                {(this.props.auth.user.role !== constant['ROLE']['OBSERVER'] || (this.props.auth.user.role === constant['ROLE']['OBSERVER'] && this.props.auth.user.freeUser === false)) &&
                  <>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label>District</label>
                          <Select
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder={this.state.district && this.state.district.label}
                            getOptionValue={(district) => district.value}
                            getOptionLabel={(district) => district.label}
                            onChange={value => this.handleDistrictChange(value)}
                            options={this.state.districts}
                            value={this.state.district && this.state.district.value}
                          ></Select>
                          <span className="red-text">
                            {errors.district}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label>School</label>
                          <Select
                            className="react-select react-select-info"
                            classNamePrefix="react-select"
                            placeholder={this.state.school && this.state.school.label}
                            getOptionValue={(school) => school.value}
                            getOptionLabel={(school) => school.label}
                            onChange={value => this.onSchoolChange(value)}
                            options={this.state.schools}
                            value={this.state.school && this.state.school.value}
                          ></Select>
                          <span className="red-text">
                            {errors.school}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                }
                <Button
                  type="submit"
                  className="default-back-color font-crm mt-4"
                  size="md">
                  Save
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>

        <Modal
          modalClassName="modal-special-district"
          isOpen={this.state.districtModal}
          toggle={() => this.setState({ specialGradeModal: false, grade: { label: 'Other', value: 'Other' }, })}
        >
          <Card className="card-raised mb-0 form-card">
            <CardHeader>
              {this.state.type === 'edit' ?
                <h5 className="default-back-color page-title">
                  Edit/Delete District/School Admins
                </h5>
                :
                <h5 className="default-back-color page-title">
                  Add District/School Admins
                </h5>
              }
            </CardHeader>
            <CardBody className='text-left'>
              <Col>
                <Label className="font-crm font-crm-bold">District Name</Label>
                <InputGroup
                  className={this.state.nameFocus ? "input-group-focus" : ""}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons location_pin"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.onModalChange}
                    value={this.state.modalDistrict.name}
                    placeholder="District Name Here"
                    id="name"
                    type="text"
                    autoComplete="off"
                    required
                    disabled={this.state.editMode === 'district' ? false : true}
                    onFocus={() => this.setState({ nameFocus: true })}
                    onBlur={() => this.setState({ nameFocus: false })}
                  />
                </InputGroup>
              </Col>
            <Col>
              <Label className="font-crm font-crm-bold">District Admin Emails</Label>
              {
                this.renderDistrictAdminEmails()
              }
              {this.state.editMode === 'district' && <Row style={{ alignItems: 'flex-end', marginRight: 0 }} className="mt-2">
                <Col>
                  <InputGroup
                    className={this.state.districtAdminFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onModalChange}
                      value={this.state.email}
                      placeholder="District Admin Email"
                      id="email"
                      type="text"
                      autoComplete="off"
                      onFocus={() => this.setState({ districtAdminFocus: true })}
                      onBlur={() => this.setState({ districtAdminFocus: false })}
                    />
                  </InputGroup>
                </Col>
                <Button className="btn btn-default default-back-color" onClick={() => { this.addDistrictData() }}>
                  <i className="now-ui-icons ui-1_simple-add"></i>
                </Button>
              </Row>}
            </Col>
            <Col>
              <Label className="font-crm font-crm-bold">List of Schools</Label>
              {
                this.renderSchoolList()
              }
              <Row style={{ alignItems: 'flex-end', marginRight: 0 }} className="mt-2">
                <Col>
                  <InputGroup
                    className={this.state.schoolNameFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons business_bank"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onModalChange}
                      value={this.state.schoolName}
                      placeholder="School Name Here"
                      id="schoolName"
                      type="text"
                      autoComplete="off"
                      required
                      onFocus={() => this.setState({ schoolNameFocus: true })}
                      onBlur={() => this.setState({ schoolNameFocus: false })}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup
                    className={this.state.schoolAdminFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.onModalChange}
                      value={this.state.schoolEmail}
                      placeholder="School Admin Email"
                      id="schoolEmail"
                      type="text"
                      autoComplete="off"
                      onFocus={() => this.setState({ schoolAdminFocus: true })}
                      onBlur={() => this.setState({ schoolAdminFocus: false })}
                    />
                  </InputGroup>
                </Col>
                <Button className="btn btn-default default-back-color" onClick={() => { this.addSchoolData() }}>
                  <i className="now-ui-icons ui-1_simple-add"></i>
                </Button>
              </Row>
            </Col>
            </CardBody>
            <CardFooter style={{ justifyContent: 'flex-end' }}>
              <Button
                className="default-back-color font-crm mr-2"
                onClick={() => { 
                  this.setState({
                    districtModal: false, 
                    modalDistrict: {
                      name: '',
                      emails: [],
                      schools: [],
                      totalObservations: 1,
                    },
                  }) 
                }}
              >
                Back
              </Button>
              <Button
                className="red-alert-color font-crm"
                onClick={() => this.saveDistrictData()}
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        </Modal>

        <Modal
          modalClassName="modal-special-grade"
          isOpen={this.state.specialGradeModal}
          toggle={() => this.setState({ specialGradeModal: false, grade: { label: 'Other', value: 'Other' }, })}
        >
          <div className="modal-title default-back-color">
            <h5>Enter Grade</h5>
          </div>
          <div className="modal-body just">
            <FormGroup>
              <Input
                id="grade"
                placeholder="Grade"
                onChange={this.onChangeSpeicalGrade}
                type="textarea"
                value={this.state.grade && this.state.grade.value}
                rows="1"
                ref='gradeInput'
                onKeyPress={this.onKeyPress}
              ></Input>
            </FormGroup>
          </div>
          <ModalFooter className="justify-content-center">
            <Button
              className="default-back-color font-crm grade-save"
              onClick={() => {
                if (this.state.grade.value === '') {
                  this.setState({ specialGradeModal: false, grade: { value: 'Other', label: 'Other' } });
                } else {
                  this.setState({ specialGradeModal: false });
                }
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

RoomDetail.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(RoomDetail)
