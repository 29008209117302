import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { FiLink2 } from "react-icons/fi";
import constant from '../../shared/constant';
import axios from "axios";

const moment = require('moment');

class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolEmail: '',
      copyEmail: '',
      copyLink: ''
    }
  }

  componentDidMount() {
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ copyEmail: e.target.value })
  }

  onClickTeacherInvite = () => {
    if (this.state.schoolEmail === '') {
      toast.info('Email can not be empty');
      return;
    }
    this.props.sendEmailToTeacher(this.state.schoolEmail);
    this.setState({schoolEmail: ''})
  }

  onClickSendInvite = () => {
    // copy link
    const observationId = localStorage.getItem(constant['OBSERVATION_ID']);
    axios.post('/api/users/getInviteUser', {email: this.state.copyEmail})
    .then((res) => {
      let link = `${constant['HOST_PATH']}/register?role=${res.data.data.role}&observation=${observationId}&email=${res.data.data.email}`
      // navigator.clipboard.writeText(link);
      const tempInput = document.createElement("input");
      tempInput.value = link;
      document.body.appendChild(tempInput);
      tempInput.select();
      try {
        document.execCommand('copy');
        toast.success("Successfully Copied")
      } catch (error) {
        toast.warning(error)
      }
    })
    .catch((err) => {
      console.log(err)
    })
    
  }
  

  render() {
    return (
      <>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Modal
          modalClassName="modal-email"
          isOpen={this.props.emailModal}
          toggle={() => { this.setState({ schoolEmail: '' }); this.props.closeEmailModal() }}
        >
          <div className="modal-title">
            <h5 className="default-back-color">
              Send Invitation Email to Teacher
            </h5>
          </div>
          <div className="modal-body just">
            <div>
              {
                this.props.teachers.length > 0 && this.props.teachers.map((teacher, index) => {
                  return (
                    <>
                      <Row style={{ alignItems: 'center', marginRight: '10px', marginLeft: '10px' }}>
                        <Row style={{ marginLeft: '10px', marginBottom: '10px', width: '100%' }}>
                          <Col style={{width: '100%', justifyContent: 'space-between', display: 'flex'}}>
                              {teacher.email && <span className="font-crm">{teacher.email}</span>}{teacher.date && <span className="font-crm">{moment(teacher.date).format('MM/DD/YYYY hh:mm A')}</span>}
                          </Col>
                        </Row>
                      </Row>
                    </>
                  )
                })
              }
              <Col>
                <InputGroup
                  className={this.state.schoolEmailFocus ? "input-group-focus" : ""}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.onChange}
                    value={this.state.schoolEmail}
                    placeholder="Teacher Email"
                    id="schoolEmail"
                    type="text"
                    autoComplete="off"
                    required
                    onFocus={() => this.setState({ schoolEmailFocus: true })}
                    onBlur={() => this.setState({ schoolEmailFocus: false })}
                  />
                </InputGroup>
              </Col>
            </div>
          </div>
          <ModalFooter style={{ justifyContent: 'flex-end', gap: '20px' }}>
            <Button
              className="default-back-color font-crm"
              onClick={() => this.onClickTeacherInvite()}
            >
              Invite
            </Button>
            <Button
              className="default-back-color font-crm"
              onClick={() => this.onClickSendInvite()}
              style={{borderRadius: "60px", display: "flex", alignItems: "center", gap: "15px"}}
            >
              <FiLink2 
                style={{fontSize: "25px"}}
              />
              Copy link
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default EmailModal;