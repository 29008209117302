import React, { Component } from "react";
import "../observe/Observe.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Col,
  Modal,
  ModalFooter, 
  Label
} from "reactstrap";
import moment from "moment";
import constant from "../../shared/constant";
import _ from 'lodash';
import axios from 'axios';

class Observations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFocus: false,
      keyword: '',
      obDataList: [],
      archivedList: [],
      noteModal: false,
      modalContent: '',
      currentData: {},
      action: 'delete',
      viewOpt: 'Archived'
    }
  }

  componentWillReceiveProps(props) {
    if (this.state.viewOpt === "My Data") {
      this.setState({ 
        obDataList: props.obDataList.filter(obData => obData.archived === true)
      });
    } else {
      this.setState({ 
        obDataList: props.obDataList.filter(obData => obData.archived === false)
      });
    }
  }

  editObservation(obData) {
    this.props.editObservation(obData);
  }

  onChangeKeyword = e => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.props.searchObservation(this.state.keyword);
    });
  };

  btnClick = (obData, action) => {
    this.setState({
      currentData: obData,
      action: action
    })
    if(action === 'delete') {
      this.setState({
        modalContent: 'Are you sure you want to permanently delete this User?  This can not be undone.',
        noteModal: true
      })
    } else if(action === "archive"){
      this.setState({
        modalContent: 'Are you sure you want to archive observation?',
        noteModal: true
      })
    } else {
      this.setState({
        modalContent: 'Are you sure you want to unarchive observation?',
        noteModal: true
      })
    }
  }

  dataAction = () => {
    if(this.state.action === 'delete') {
      axios
      .delete(`/api/observations/${this.state.currentData._id}`)
      .then((res) => {
        if(localStorage.getItem('isSchoolUser') === 'true') {
          axios
            .get('/api/districts')
            .then((res) => {
              let tempDistricts = [...res.data];
              if(tempDistricts.length > 0) {
                tempDistricts.forEach(district => {
                  if(district.schools.length > 0) {
                    let flag = 0;
                    district.schools.forEach(school => {
                      if(school.users.length > 0) {
                        school.users.forEach(user => {
                          if(user.email === this.props.auth.user.email) {
                            user.count === 0 ? user.count = 0 : user.count--;
                            flag = 1;
                          }
                        })
                      }
                    })
                    if(flag === 1) {
                      axios
                        .put(`/api/districts/${district._id}`, { district: district })
                        .then((res) => {
                          if (res.status === 200) {
                            console.log('success');
                          }
                        })
                    }
                  }
                })
              }
            })
        }
        this.props.resetObDataList()
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error occured while getting observation data.");
        }
      });
    } else if (this.state.action === "archive") {
      let obData = {...this.state.currentData, archived: true}
      axios
      .put(`/api/observations/${this.state.currentData._id}`, 
        {
          obData: obData,
        }
      )
      .then((res) => {
        this.props.resetObDataList()
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error occured while getting observation data.");
        }
      });
    } else {
      let obData = {...this.state.currentData, archived: false}
      axios
      .put(`/api/observations/${this.state.currentData._id}`, 
        {
          obData: obData,
        }
      )
      .then((res) => {
        this.props.resetObDataList()
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log("Error occured while getting observation data.");
        }
      });
    }
    this.setState({
      noteModal: false
    })
  }

  renderObservations = () => {
    return this.state.obDataList.sort((a, b) => {return new Date(b.dateModified) - new Date(a.dateModified)}).map((obData, index) => {
      const { _id, teacher, grade, topic, district , school} = obData;
      let schoolData = null;
      if (district)
        schoolData = _.find(district.schools, {_id: school});
      return (
        <>
          <tr key={_id}>
            <td className="text-left">
              <span style={{ marginLeft: '8px' }} className="font-crm">
                {index + 1}
              </span>
            </td>
            <td className="text-left font-crm"><span>{teacher}</span></td>
            <td className="text-left font-crm"><span>{grade}</span></td>
            <td className="text-left font-crm"><span>{topic}</span></td>
            <td className="text-left font-crm"><span>{schoolData ? schoolData.name : 'Unknown'}</span></td>
            <td className="text-left font-crm"><span><a href="#pablo"  style={{ color: 'blue'}} onClick={(e) => { e.preventDefault(); this.editObservation(obData) }}>{moment(obData.dateModified).format('MM/DD/YYYY h:mm a')}</a></span></td>
            <td className="text-left font-crm"><span>{obData.status === 1 ? 'Not Completed' : 'Completed'}</span></td>
            {this.state.viewOpt === "Archived" ?
              <>
                <td className="text-left font-crm"><Button className="color-white default-back-color border-0 p-2" tag={Link} to={{ pathname: "/classroom",  state: {obData: obData} }}><i class="fa fa-pencil"></i>  Edit</Button></td>
                <td className="text-left font-crm"><Button className="color-white border-0 p-2" style={{backgroundColor: "#cca923"}} onClick={() => this.btnClick(obData, 'archive')}><i class="fa fa-archive"></i>  Archive</Button></td>
                <td className="text-left font-crm"><Button className="color-white border-0 p-2" style={{backgroundColor: "#b80838"}} onClick={() => this.btnClick(obData, 'delete')}><i class="fa fa-trash"></i>  Delete</Button></td>
              </>
              :
              <>
                <td className="text-left font-crm"><Button className="color-white default-back-color border-0 p-2" onClick={() => this.btnClick(obData, 'unarchive')}><i class="fa fa-archive"></i>  unArchive</Button></td>
              </>
            }
          </tr>
        </>
      );
    });
  }

  changeView = () => {
    if(this.state.viewOpt === "Archived") {
      this.setState({ 
        viewOpt: 'My Data',
        obDataList: this.props.obDataList.filter(obData => obData.archived === true)
      })
    } else {
      this.setState({ 
        viewOpt: 'Archived',
        obDataList: this.props.obDataList.filter(obData => obData.archived === false)
      })
    }
  }

  render() {
    return (
      <>
        <Container className="mt-5">
          <Row>
            <div className="mr-auto ml-auto col-md-12">
              {(this.props.auth.user.role === constant['ROLE']['OBSERVER'] ||
                  this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) && this.state.viewOpt === "Archived" &&
                <a onClick={(e) => { e.preventDefault(); this.props.createObservationRoom() }} 
                className="btn default-back-color btn-round font-crm" href="#pblo">Start New Observation</a>
              }
              
              {(this.props.auth.user.role === constant['ROLE']['OBSERVER'] ||
                  this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) && this.state.viewOpt === "My Data" &&
                <p className="btn default-back-color btn-round font-crm">Archived Observations</p>
              }
              {
                <Card className='mt-3'>
                  <CardBody>
                    <Row>
                      <Col lg="6">
                        <InputGroup
                          className={this.state.searchFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_zoom-bold"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            onChange={this.onChangeKeyword}
                            value={this.state.email}
                            placeholder=""
                            id="keyword"
                            type="text"
                            autoComplete="on"
                            onFocus={() => this.setState({ searchFocus: true })}
                            onBlur={() => this.setState({ searchFocus: false })}
                          />
                        </InputGroup>
                      </Col>
                      <Col lg="3"></Col>
                      <Col lg="3">
                        <Button className="color-white border-0 p-2" style={{backgroundColor: "#cca923"}} onClick={this.changeView}><i class="fa fa-exchange"></i> {this.state.viewOpt}</Button>
                      </Col>
                    </Row>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left" style={{ width: '30px' }}>
                            <div className="order-mark">
                            </div>
                          </th>
                          <th className="text-left"><span>Teacher</span></th>
                          <th className="text-left"> <span>Grade</span></th>
                          <th className="text-left"><span>Topic Of Lesson</span></th>
                          <th className="text-left"><span>School</span></th>
                          <th className="text-left"><span>Last Completed</span></th>
                          <th className="text-left"><span>Status</span></th>
                          {this.state.viewOpt === "Archived" ?
                            <>
                              <th className="text-left"><span>Edit</span></th>
                              <th className="text-left"><span>Archive</span></th>
                              <th className="text-left"><span>Delete</span></th>
                            </>
                            :
                            <>
                              <th className="text-left"><span>Unarchive</span></th>
                            </>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.renderObservations()
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              }
            </div>
          </Row>
        </Container>
        <Modal
          modalClassName="modal-question"
          isOpen={this.state.noteModal}
          toggle={() => this.setState({ noteModal: false, note: '', nIndex: -1 , inputState : true})}
        >
          <div className="modal-title">
            <h5 className="default-back-color" style={{ color: 'white', padding: '10px', textAlign: 'center' }}>Warning</h5>
          </div>
          <div className="modal-body just">
            <div>
              <Col className='text-left'>
                <Label className="font-crm font-crm-bold text-dark">
                  {this.state.modalContent}
                </Label>
              </Col>
            </div>
          </div>
          <ModalFooter className="justify-content-center" style={{gap: '10px'}}>
            <Button
              className="red-alert-color font-crm action-save ml-3"
              onClick={() => this.setState({noteModal: false})}
            >
              Cancel
            </Button>
            <Button
              className="default-back-color font-crm action-save mr-3"
              onClick={this.dataAction}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

Observations.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Observations);
