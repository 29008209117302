import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING
} from "./types";
import { GAexception } from "../shared/gaUtils";
import { decode } from "jsonwebtoken";

// Register User
export const registerUser = (userData) => dispatch => {
    axios
    .post("/api/users/register", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("isSchoolUser", res.data.isSchoolUser);
      localStorage.setItem("isSchoolAdmin", res.data.isSchoolAdmin);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      {
        GAexception(`Register User Failed: ${err}`);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: {message: "Oops!!! Something went wrong"}
          })
        }
      }
    );
};

// Register User
export const registerEmployee = (userData, history) => dispatch => {
  axios
    .post("/api/users/registerEmployee", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
        GAexception(`Register Employee Failed: ${err}`);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: {message: "Oops!!! Something went wrong"}
          })
        }
      }
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("isSchoolUser", res.data.isSchoolUser);
      localStorage.setItem("isSchoolAdmin", res.data.isSchoolAdmin);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
        GAexception(`Login Failed: ${err}`);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: {message: "Oops!!! Something went wrong"}
          })
        }
      }
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};